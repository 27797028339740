import { Button, CircularProgress, Grid, Snackbar, Typography } from '@material-ui/core'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { Link, useHistory } from 'react-router-dom'
import {
  composeValidators,
  validatorEmail,
  validatorFieldsMatch,
  validatorMinLength,
  validatorRequired,
} from '_core/form-validators/validators'
import { TextFieldAdapter } from '../forms/inputs/text-field-adapter'
import { DEFAULT_ADMIN_ROUTE } from '../router/router-utils'
import { CheckboxFieldAdapter } from '../forms/inputs/checkbox-field-adapter'
import { analytics } from '../index'
import { getTimeStamp } from '../_core/utils/date-utils'
export const SignUpForm: React.FC = () => {
  const firebase = useFirebase()
  const firestore = useFirestore()
  const history = useHistory()

  const [errorMessage, setErrorMessage] = React.useState(undefined)
  const [loading, setLoading] = React.useState(false)

  const handleClose = (event, reason) => {
    setErrorMessage(undefined)
  }

  const onSubmit = formValue => {
    setLoading(true)
    firebase
      .auth()
      .createUserWithEmailAndPassword(formValue.email, formValue.password)
      .then(userCredential => {
        userCredential.user.sendEmailVerification().then(value => console.log('email sent'))
        const unsubscribe = firestore
          .collection('users')
          .doc(userCredential.user.uid)
          .onSnapshot(user => {
            const userData = user.data()
            if (userData) {
              if (userData.companyId) {
                unsubscribe()
                firestore.doc(`users/${user.id}`).update({
                  name: formValue.name,
                })
              } else {
                analytics.logEvent('company-sign-up', { name: formValue.companyName })
                firestore.collection('companies').add({
                  name: formValue.companyName,
                  created: getTimeStamp(),
                  createdBy: userCredential.user.uid,
                })
              }
            }
          })
      })
      .catch(r => {
        setErrorMessage((r && r.message) ?? undefined)
        setLoading(false)
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, invalid, values }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off" style={{ width: '100%' }}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography variant="h2">Registracija verslui</Typography>
              </Grid>
              <Grid item xs>
                <Field
                  name="companyName"
                  label="Verslo pavadinimas"
                  component={TextFieldAdapter}
                  validate={validatorRequired}
                  required
                />
              </Grid>
              <Grid item xs>
                <Field
                  name="name"
                  label="Jūsų vardas"
                  component={TextFieldAdapter}
                  validate={validatorRequired}
                  required
                />
              </Grid>

              <Grid item xs>
                <Field
                  name="email"
                  label="El. paštas"
                  component={TextFieldAdapter}
                  validate={composeValidators(validatorRequired, validatorEmail)}
                  required
                />
              </Grid>
              <Grid item xs>
                <Field
                  name="password"
                  label="Slaptažodis"
                  fieldType="password"
                  component={TextFieldAdapter}
                  validate={composeValidators(validatorMinLength(6), validatorRequired)}
                  required
                />
              </Grid>
              <Grid item xs>
                <Field
                  name="repeated-password"
                  label="Pakartoti slaptažodį"
                  fieldType="password"
                  component={TextFieldAdapter}
                  validate={composeValidators(
                    validatorFieldsMatch(['password'], 'Slaptažodžiai nesutampa'),
                    validatorMinLength(6),
                    validatorRequired,
                  )}
                  required
                />
              </Grid>

              <Grid item xs>
                <Field
                  name="terms"
                  component={CheckboxFieldAdapter}
                  label={
                    <Typography variant="body1">
                      Atlikdamas registraciją sutinku su <Link to={'/terms-for-business'}>sąlygomis</Link>
                    </Typography>
                  }
                  type="checkbox"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={invalid || loading || !values.terms}
                >
                  Registruotis
                  {loading && <CircularProgress size="16px" style={{ marginLeft: '8px' }} />}
                </Button>
              </Grid>
            </Grid>

            <Snackbar open={errorMessage} autoHideDuration={6000} onClose={handleClose} message={errorMessage} />
          </form>
        )
      }}
    />
  )
}
