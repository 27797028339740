import Label from '../../_core/components/label'
import React from 'react'
import { Coupon } from '../../types/coupon-type'
import { isPastDueDate } from '../../_core/utils/coupon-utils'

type CouponStatusProps = {
  coupon: Coupon
}
export const CouponStatus: React.FC<CouponStatusProps> = ({ coupon }) => {
  let color = coupon.claimed ? 'error' : 'success'
  if (!coupon.claimed && isPastDueDate(coupon)) {
    color = 'warning'
  }
  return (
    <Label color={color}>
      {coupon.claimed
        ? 'Talonas išnaudotas'
        : isPastDueDate(coupon)
        ? 'Talono galiojimas pasibaigęs'
        : 'Talonas aktyvus'}
    </Label>
  )
}
