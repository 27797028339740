import { Button, Grid, IconButton, Link, TextField, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { useEffect, useState } from 'react'
import { useSelector } from '../store'
import { isEmpty, isLoaded, useFirebase } from 'react-redux-firebase'
import { Link as RouterLink, Redirect, useHistory, useParams } from 'react-router-dom'
import validate from 'validate.js'
import { white } from '../theme/palette'
import { DEFAULT_ADMIN_ROUTE } from '../router/router-utils'
import { useSnackbar } from 'notistack'
import { getErrorText } from '../_core/utils/firebase-error-text'
import { registerPageView } from '../_core/analytics/analytics'
const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
}

const useStyles = makeStyles(theme => ({
  realRoot: {
    height: '100%',
  },
  realContent: {
    height: '100%',
  },
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: '#FFFFFF',
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: white,
  },
  bio: {
    color: white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}))
export const SignIn = () => {
  const history = useHistory()
  const firebase = useFirebase()
  const classes = useStyles()

  const profile = useSelector(state => state.firebase.profile)
  useEffect(() => {
    registerPageView('business_signin')
  }, [])
  const [formState, setFormState] = useState<any>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const handleBack = () => {
    history.goBack()
  }

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }
  const { enqueueSnackbar } = useSnackbar()
  const showError = error => {
    enqueueSnackbar(getErrorText(error), {
      variant: 'error',
    })
  }

  const handleSignIn = event => {
    event.preventDefault()
    firebase.login({ email: formState.values.email, password: formState.values.password }).catch(error => {
      showError(error)
    })
  }

  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false)

  const params = useParams<any>()
  if (
    isLoaded(profile) &&
    !isEmpty(profile) &&
    (profile.role === 'admin' || profile.role === 'user') &&
    profile.companyId != null
  ) {
    if (params.redirectUrl) {
      return <Redirect to={{ pathname: `/${params.redirectUrl}/${params.param1}` }} />
    } else {
      return <Redirect to={{ pathname: DEFAULT_ADMIN_ROUTE }} />
    }
  }

  const heandleResetPassword = () => {
    var email = prompt('Įveskite el. pašto adresą', formState.values.email)
    if (email !== null) {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          alert('Slaptažodžių pakeitimo nuoroda buvo išsiųsta į jūsų nurodytą el. paštą')
        })
    }
  }

  return (
    <div className={classes.realRoot}>
      <main className={classes.realContent}>
        <div className={classes.root}>
          <Grid className={classes.grid} container>
            <Grid className={classes.quoteContainer} item lg={5}>
              <div className={classes.quote}>
                <div className={classes.quoteInner}>
                  <Typography className={classes.quoteText} variant="h1">
                    I just bought a year of lunches. From a restaurant near where I work. It cost me $2000 - money I
                    plan to spend on lunches anyhow.
                  </Typography>
                  <div>
                    <Typography className={classes.name} variant="body1">
                      Mike McDerment
                    </Typography>
                    <Typography className={classes.bio} variant="body2">
                      Co-Founder & CEO of Freshbooks
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid className={classes.content} item lg={7} xs={12}>
              <div className={classes.content}>
                <div className={classes.contentHeader}>
                  <IconButton onClick={handleBack}>
                    <ArrowBackIcon />
                  </IconButton>
                </div>
                <div className={classes.contentBody}>
                  <form className={classes.form} onSubmit={handleSignIn}>
                    <Typography className={classes.title} variant="h2">
                      Prisijungimas verslui
                    </Typography>
                    <TextField
                      className={classes.textField}
                      error={hasError('email')}
                      fullWidth
                      helperText={hasError('email') ? formState.errors.email[0] : null}
                      label="El. paštas"
                      name="email"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.email || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={hasError('password')}
                      fullWidth
                      helperText={hasError('password') ? formState.errors.password[0] : null}
                      label="Slaptažodis"
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.password || ''}
                      variant="outlined"
                    />
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!formState.isValid}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Prisijungti
                    </Button>
                    <Typography color="textSecondary" variant="body1">
                      Norite užsiregistruoti kaip įmonė?{' '}
                      <Link component={RouterLink} to="/sign-up" variant="h6">
                        Susikurti paskyrą
                      </Link>
                    </Typography>
                    <br />
                    <Typography color="textSecondary" variant="body1">
                      Pamiršote slaptažodį?{' '}
                      <Link onClick={heandleResetPassword} component={RouterLink} to="#" variant="h6">
                        Spauskite čia
                      </Link>
                    </Typography>
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </main>
    </div>
  )
}
