import { Grid, IconButton, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { useEffect } from 'react'
import { useSelector } from '../store'
import { Redirect, useHistory } from 'react-router-dom'
import { white } from '../theme/palette'
import { SignUpForm } from './sign-up-form'
import { DEFAULT_ADMIN_ROUTE } from '../router/router-utils'
import { registerPageView } from '../_core/analytics/analytics'
const useStyles = makeStyles(theme => ({
  realRoot: {
    height: '100%',
  },
  realContent: {
    height: '100%',
  },
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: '#FFFFFF',
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: white,
  },
  bio: {
    color: white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}))

export const SignUp = () => {
  const history = useHistory()
  const classes = useStyles()

  const profile = useSelector(state => state.firebase.profile)

  const handleBack = () => {
    history.goBack()
  }
  useEffect(() => {
    registerPageView('business_signup')
  }, [])

  if (profile && profile.companyId != null) {
    return <Redirect to={{ pathname: DEFAULT_ADMIN_ROUTE }} />
  }

  return (
    <div className={classes.realRoot}>
      <main className={classes.realContent}>
        <div className={classes.root}>
          <Grid className={classes.grid} container>
            <Grid className={classes.quoteContainer} item lg={5}>
              <div className={classes.quote}>
                <div className={classes.quoteInner}>
                  <Typography className={classes.quoteText} variant="h1">
                    I just bought a year of lunches. From a restaurant near where I work. It cost me $2000 - money I
                    plan to spend on lunches anyhow.
                  </Typography>
                  <div>
                    <Typography className={classes.name} variant="body1">
                      Mike McDerment
                    </Typography>
                    <Typography className={classes.bio} variant="body2">
                      Co-Founder & CEO of Freshbooks
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid className={classes.content} item lg={7} xs={12} style={{ overflowY: 'auto' }}>
              <div className={classes.content}>
                <div className={classes.contentHeader}>
                  <IconButton onClick={handleBack}>
                    <ArrowBackIcon />
                  </IconButton>
                </div>
                <div className={classes.contentBody}>
                  <div className={classes.form}>
                    <SignUpForm />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </main>
    </div>
  )
}
