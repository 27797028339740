import { CardContent, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import palette from '../../theme/palette'
import { getImageUrl, ImageType } from '../../images/image-utils'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  root: {
    width: '200px',
    minWidth: '200px',
    margin: '0 8px',
    textAlign: 'center',
  },
  largeBlueText: {
    color: palette.primary.main,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '35px',
    margin: '16px',
  },
  button: {
    fontWeight: 700,
    fontSize: '24px',
  },
  midBlueText: {
    color: palette.primary.main,
  },
  blueText: {
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: '14px',
  },
  smallInput: {
    textAlign: 'center',
    maxWidth: '32px',
    '& input': {
      textAlign: 'center',
    },
  },
  value: {
    display: 'inline-block',
    margin: '0 5px',
  },
  discountPrice: {
    fontSize: 18,
    display: 'inline-block',
    margin: '0 5px',
    fontWeight: 500,
    color: palette.text.secondary,
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
    backgroundColor: '#ffffff',
  },
  badges: {
    fontSize: 12,
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  wrapper: {
    width: 200,
    height: 200,
    marginTop: '-50px',
    overflow: 'hidden',
    border: '5px solid #eeeeee',
    borderRadius: '50%',
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
      marginLeft: '8px',
      border: '2px solid #ffffff',
    },
  },
  mini: {
    width: '140px',
    height: '140px',
    margin: '-30px auto 0 auto',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    border: '2px solid #ffffff',
  },
  img: {
    objectFit: 'fill',
  },
  letter: {
    fontSize: 48,
    color: theme.palette.primary.dark,
  },
  miniTitle: {
    maxHeight: 50,
    overflow: 'hidden',
  },
}))
const CompanyLogo: React.FC<any> = props => {
  const { company, mini } = props
  const classes = useStyles()
  return (
    <div className={clsx(classes.wrapper, mini ? classes.mini : '')}>
      {company.logoUrl ? (
        <img className={classes.img} alt={company.name} src={getImageUrl(company.logoUrl, ImageType.SMALL)} />
      ) : (
        <Typography className={classes.letter}>{company.name.charAt(0)}</Typography>
      )}
    </div>
  )
}
export const MiniCompanyCard = props => {
  const { className, company, ...rest } = props
  // const product = props.product as Product
  const classes = useStyles()

  return (
    <Paper {...rest} className={clsx(classes.root, className)}>
      <Link to={`/company/${company.alias}`}>
        <CompanyLogo company={company} mini={true} />
        <CardContent>
          <Typography className={classes.miniTitle} align="center" variant="h4">
            {company.name}
          </Typography>
        </CardContent>
      </Link>
    </Paper>
  )
}
