import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import { EditorField } from 'forms/inputs/editor-field-adapter'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { CheckboxFieldAdapter } from '../../forms/inputs/checkbox-field-adapter'
import { SelectFieldAdapter } from '../../forms/inputs/select-field-adapter'
import { TextFieldAdapter } from '../../forms/inputs/text-field-adapter'
import { toNumber } from '../../_core/utils/parse-values'
import { composeValidators, validatorRequired, valuePriceValidator } from '../../_core/form-validators/validators'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  root: {},
  field: {
    marginBottom: '12px',
  },
  fieldWithHelper: {
    marginBottom: '20px',
  },
}))
const expirationOptions = [
  { value: 'days', label: 'Dienos' },
  { value: 'months', label: 'Mėnesiai' },
  { value: 'years', label: 'Metai' },
]
export const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

export const ProductDetails: React.FC<any> = props => {
  const classes = useStyles()
  const { className, values } = props

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title="Nominalo detalės" />
      <CardContent>
        <div className={classes.field}>
          <Field
            name="isPublished"
            component={CheckboxFieldAdapter}
            defaultValue={true}
            label="Publikuotas"
            type="checkbox"
            variant="outlined"
          />
        </div>
        <div className={classes.field}>
          <Field name="title" validate={validatorRequired} component={TextFieldAdapter} label="Pavadinimas" />
        </div>
        <div className={classes.fieldWithHelper}>
          <Field name="sku" validate={validatorRequired} component={TextFieldAdapter} label="Prekės kodas" />
          <Typography variant={'caption'}>
            Prekės kodas - jūsų vidinei apskaitai ir sąskaitose matomas tekstas
          </Typography>
        </div>
        <div className={classes.fieldWithHelper}>
          <Field name="shortDescription" component={TextFieldAdapter} label="Trumpas aprašymas" />
          <Typography variant={'caption'}>
            Trumpas aprašymas - papildoma informacija matoma jūsų kompanijos puslapyje
          </Typography>
        </div>
        <div className={classes.fieldWithHelper}>
          <Field
            name="discount"
            component={CheckboxFieldAdapter}
            helperText={'pvz 10 EUR vertės talonas, kuris kainuoja 8EUR'}
            label="Šio nominalo vertė skirsis nuo kainos"
            type="checkbox"
            variant="outlined"
          />
          <Typography variant={'caption'}>
            Pažymėkite jei norite turėti Taloną su nuolaida. Pvz 10 Eur vertės Talonas už 8 Eur
          </Typography>
        </div>
        <div className={classes.field}>
          <Grid container spacing={4}>
            <Grid item md={2} sm={2} xs={12}>
              <Field
                validate={validatorRequired}
                name="prefix"
                defaultValue={'€'}
                component={TextFieldAdapter}
                fieldType={'text'}
                label="Ženklas"
              />
            </Grid>
            <Grid item md={5} sm={5} xs={12}>
              {' '}
              <Field
                validate={validatorRequired}
                name="price"
                component={TextFieldAdapter}
                parse={toNumber}
                fieldType={'number'}
                step={'0.01'}
                label="Kaina"
              />
            </Grid>
            <Grid item md={5} sm={5} xs={12}>
              <Condition when="discount" is={true}>
                <Field
                  validate={composeValidators(validatorRequired, valuePriceValidator(values.price))}
                  name="value"
                  component={TextFieldAdapter}
                  parse={toNumber}
                  fieldType={'number'}
                  step={'0.01'}
                  label="Vertė"
                />
              </Condition>
            </Grid>
          </Grid>
        </div>
        <div className={classes.field}>
          <Field
            name="hasExpirationDate"
            component={CheckboxFieldAdapter}
            label="Šio nominalo talonai turės galiojimo laiką"
            type="checkbox"
            defaultValue={true}
            variant="outlined"
          />
        </div>
        <Condition when="hasExpirationDate" is={true}>
          <div>
            <Grid container spacing={4}>
              <Grid item md={4} xs={6}>
                <Field
                  validate={validatorRequired}
                  name="expirationAmount"
                  parse={toNumber}
                  defaultValue={12}
                  component={TextFieldAdapter}
                  fieldType={'number'}
                  label="Kiekis"
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <Field
                  validate={validatorRequired}
                  name="expirationType"
                  fullWidth
                  defaultValue={'months'}
                  items={expirationOptions}
                  component={SelectFieldAdapter}
                  label="dienos / mėnesiai / metai"
                />
              </Grid>
            </Grid>
          </div>
        </Condition>
        {/*<div>*/}
        {/*  <Field*/}
        {/*    name="isLimited"*/}
        {/*    component={CheckboxFieldAdapter}*/}
        {/*    label="This product has limited amount in stock"*/}
        {/*    type="checkbox"*/}
        {/*    variant="outlined"*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<Condition when="isLimited" is={true}>*/}
        {/*  <Grid container spacing={4}>*/}
        {/*    <Grid item md={4} xs={12}>*/}
        {/*      <Field*/}
        {/*        name="inStock"*/}
        {/*        parse={toNumber}*/}
        {/*        component={TextFieldAdapter}*/}
        {/*        fieldType={'number'}*/}
        {/*        label="Amount in stock"*/}
        {/*      />*/}
        {/*    </Grid>*/}
        {/*    <Grid item md={4} xs={12}>*/}
        {/*      <Field*/}
        {/*        name="maxPerOrder"*/}
        {/*        parse={toNumber}*/}
        {/*        component={TextFieldAdapter}*/}
        {/*        fieldType={'number'}*/}
        {/*        label="Allowed amount per 1 order"*/}
        {/*      />*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</Condition>*/}
      </CardContent>
    </Card>
  )
}

ProductDetails.propTypes = {
  className: PropTypes.string,
}
