import { makeStyles } from '@material-ui/core'
import React from 'react'

import PerfectScrollbar from 'react-perfect-scrollbar'
import Typography from '@material-ui/core/Typography'
import { Company } from '../../../types/company-type'
import { FeaturedProduct } from './featured-product'
import { useSelector } from '../../../store'
import { findCompany } from '../../../_core/utils/company-utils'

const useStyles = makeStyles(theme => ({
  box: {
    maxWidth: '1440px',
    margin: '0 auto',
  },
  root: {
    padding: theme.spacing(2),
  },
  content: {
    margin: '0 auto',
    marginTop: theme.spacing(2),
  },
  gridList: {
    display: 'flex',
    padding: '30px 0 8px 0',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  sectionHeader: {
    marginBottom: theme.spacing(2),
  },
}))
type Props = {
  companies: Company[]
}
export const FeaturedProductsList: React.FC<Props> = ({ companies }) => {
  const classes = useStyles()
  const products = useSelector(
    ({
      firestore: {
        ordered: { publicProducts },
      },
    }) => publicProducts && publicProducts,
  )
  if (!products || products.length === 0) return null
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.box}>
          <Typography variant={'h2'} style={{ marginBottom: '16px' }}>
            Pasiūlymai
          </Typography>
          <PerfectScrollbar>
            {products && (
              <div className={classes.gridList}>
                {products.map(product => {
                  const company = findCompany(companies, product.companyId)
                  if (company) {
                    return <FeaturedProduct product={product} company={company} />
                  } else return null
                })}
              </div>
            )}
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  )
}
