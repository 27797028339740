import { Dialog, useMediaQuery } from '@material-ui/core'
import { shiftKeyOnly } from 'ol/events/condition'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { defaults, MouseWheelZoom } from 'ol/interaction'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import Map from 'ol/Map'
import MapBrowserEvent from 'ol/MapBrowserEvent'
import 'ol/ol.css'
import Cluster from 'ol/source/Cluster'
import OSM from 'ol/source/OSM'
import VectorSource from 'ol/source/Vector'
import XYZ from 'ol/source/XYZ'
import Fill from 'ol/style/Fill'
import Icon from 'ol/style/Icon'
import Style from 'ol/style/Style'
import Text from 'ol/style/Text'
import View from 'ol/View'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '../store'
import { Company } from '../types/company-type'
import { CompanyCategory } from '../types/company/category.enum'
import { SelectedCompany } from './companies/selected-company'
import { updateMapStateAction } from './map-reducer'
import { createEmpty } from 'ol/extent'
import { toOl } from './map-utils'
import useTheme from '@material-ui/core/styles/useTheme'
import { actionSetSelectedCompany, actionUpdateVisibleCompanies } from './map-actions'

const cluster =
  '<svg width="32" height="41"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:#39b54a;}</style></defs><title>ikonos</title><path class="cls-1" d="M348,676.9a328.95,328.95,0,0,1-232.6-561.55,328.95,328.95,0,1,1,465.2,465.2A326.79,326.79,0,0,1,348,676.9Z"/><path class="cls-2" d="M668.54,212.5a348,348,0,1,0-456,456c5.86,2.48,11.76,4.77,17.71,6.92L343,895,458.51,678q12.58-4.23,24.89-9.43a348.29,348.29,0,0,0,185.14-456ZM348,657.9C176.77,657.9,38,519.13,38,348S176.77,38,348,38s310,138.76,310,309.94S519.13,657.9,348,657.9Z"/></svg>'
const companyToFeatures = (company: Company) => {
  if (company.locations) {
    return company.locations.map(x => {
      const f = new Feature(new Point(toOl(x.lng, x.lat)))
      f.set('companyId', company.id)
      f.set('category', company.category)
      return f
    })
  } else {
    return []
  }
}
export const getIconByCategory = (category: string) => {
  switch (category) {
    case CompanyCategory.FOOD_AND_DRINKS: {
      return '/icons/food_and_drinks.svg'
    }
    case CompanyCategory.THINGS_TO_DO: {
      return '/icons/things_to_do.svg'
    }
    case CompanyCategory.SERVICES: {
      return '/icons/services.svg'
    }
    default:
      return '/icons/food_and_drinks.svg'
  }
}
export const getTextByCategory = (category: string) => {
  switch (category) {
    case CompanyCategory.FOOD_AND_DRINKS: {
      return 'Maistas ir gėrimai'
    }
    case CompanyCategory.THINGS_TO_DO: {
      return 'Laisvalaikis'
    }
    case CompanyCategory.SERVICES: {
      return 'Paslaugos'
    }
    default:
      return 'Kita'
  }
}
const setOnHoverListener = map => {
  map.on('pointermove', evt => {
    if (evt.dragging) {
      // the event is a drag gesture, this is handled by openlayers (map move)
      return
    }
    const pixel = map.getEventPixel(evt.originalEvent)
    const feature = map.forEachFeatureAtPixel(pixel, someFeature => someFeature, {})
    map.getTargetElement().style.cursor = feature ? 'pointer' : ''
  })
}
type Props = {
  companies: Company[]
}
// const setOnClickListener = (map: Map) => {
//   map.on('click', (event: MapBrowserEvent) => {
//     if (map.hasFeatureAtPixel(event.pixel) === true) {
//       const features = map.getFeaturesAtPixel(event.pixel)
//       if (features.length > 0) {
//         console.log('click', features)
//       }
//     }
//   })
// }
const getCenterCoordinates = city => {
  switch (city) {
    case 'Klaipėda':
      return toOl(21.1377133, 55.710803)
    case 'Kaunas':
      return toOl(23.9033113, 54.8999251)
    case 'Panevėžys':
      return toOl(24.3609063, 55.731891)
    default:
      return toOl(25.2800104, 54.6920793)
  }
}
export const MapComponent: React.FC<Props> = ({ companies }) => {
  const initialMapState = useSelector(state => state.map)
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })
  const city = useSelector(state => state.filters.city)
  const selectedCompany = useSelector(state => state.map.selectedCompany)
  //const hoveredCompanyId = useSelector(state => state.map.hoveredCompanyId)
  const ref = useCallback(ref => {
    if (!ref) {
      return
    }
    console.log('init map')
    const features = companies.map(companyToFeatures).flat()

    const source = new VectorSource({
      features: features,
    })

    const clusterSource = new Cluster({
      distance: 20,
      source: source,
    })

    const styleCache: Record<number, Style> = {}
    const locations = new VectorLayer({
      source: clusterSource,
      style: feature => {
        var size = feature.get('features').length
        var style = styleCache[size]
        if (!style) {
          if (size === 1) {
            let f = feature.get('features')[0]
            const category = f.get('category')
            style = new Style({
              image: new Icon({
                anchor: [0.5, 1],
                opacity: 1,
                src: getIconByCategory(category),
              }),
            })
          } else {
            style = new Style({
              image: new Icon({
                opacity: 1,
                src: '/icons/cluster.svg',
              }),
              text: new Text({
                text: size.toString(),
                font: 'bold 11px Roboto, Verdana, Helvetica, sans-serif',

                offsetY: -3,

                scale: 1.2,
                fill: new Fill({
                  color: '#000',
                }),
              }),
            })
            styleCache[size] = style
          }
        }
        return style
      },
    })

    var osm = new TileLayer({
      source: new OSM(),
    })

    const gmap = new TileLayer({
      source: new XYZ({
        url: 'http://mt{0-3}.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
      }),
    })

    const view = new View({
      center: getCenterCoordinates(city),
      zoom: initialMapState.zoom,
    })

    const map = new Map({
      interactions: defaults({ mouseWheelZoom: true }),
      target: 'map',
      layers: [gmap, locations],
      view: view,
    })
    setOnHoverListener(map)
    map.on('moveend', function (e) {
      const zoom = map.getView().getZoom()
      const center = map.getView().getCenter()
      const extent = map.getView().calculateExtent(map.getSize())
      let visibleCompanies = []
      clusterSource.forEachFeatureInExtent(extent, function (feature) {
        const features = feature.get('features')
        features.map(feature => {
          const companyId = feature.get('companyId')
          visibleCompanies.push(companyId)
        })
      })
      dispatch(actionUpdateVisibleCompanies(visibleCompanies))
      dispatch(updateMapStateAction(zoom, center))
    })

    map.on('singleclick', evt => {
      const clickFeature = map.forEachFeatureAtPixel(evt.pixel, feature => feature)
      if (clickFeature !== undefined) {
        console.log(clickFeature, evt.coordinate)
        const features = clickFeature.get('features') as Feature[]
        if (features.length > 1) {
          const currentZoom = map.getView().getZoom()
          map.getView().setCenter(evt.coordinate)
          map.getView().setZoom(currentZoom + 3)
        } else {
          const companyId = features[0].get('companyId')
          const company = companies.find(x => x.id === companyId)
          dispatch(actionSetSelectedCompany(company))
        }
      }
    })

    var mouseWheelInt = new MouseWheelZoom()
    map.addInteraction(mouseWheelInt)

    map.on('wheel', evt => {
      mouseWheelInt.setActive(shiftKeyOnly(evt as MapBrowserEvent))
    })
  }, [])
  return (
    <div style={{ height: '100%' }}>
      <div ref={ref} id="map" className="map" style={{ height: '100%' }} />
      <Dialog
        open={selectedCompany !== undefined}
        maxWidth={'md'}
        fullScreen={isMobile}
        fullWidth={true}
        onClose={() => dispatch(actionSetSelectedCompany(undefined))}
      >
        {selectedCompany && (
          <SelectedCompany onClose={() => dispatch(actionSetSelectedCompany(undefined))} company={selectedCompany} />
        )}
      </Dialog>
    </div>
  )
}
