import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import firebase from 'firebase'
import arrayMutators from 'final-form-arrays'
import { Field, Form } from 'react-final-form'
import { EditorField } from '../../forms/inputs/editor-field-adapter'
import { composeValidators, validatorMaxLength, validatorRequired } from '../../_core/form-validators/validators'
import { TextFieldAdapter } from '../../forms/inputs/text-field-adapter'
import draftToHtml from 'draftjs-to-html'
import { useSnackbar } from 'notistack'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}))
export const ControlCenterEmails = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const showMessage = (type, message) => {
    enqueueSnackbar(message, {
      variant: type,
    })
  }
  const handleSend = (subject: string, content: string, email: string) => {
    const sendToAdmins = firebase.functions().httpsCallable('sendToAllAdmins')
    sendToAdmins({ subject: subject, content: content, email: email })
      .then(function (result) {
        console.log('result.', result)
        showMessage(result.data.type, result.data.message)
      })
      .catch(error => console.error(error))
  }
  const onSubmit = values => {
    const content = draftToHtml(values.content)
    if (content.length > 50) {
      handleSend(values.subject, content, values.email)
    } else {
      showMessage('error', 'email message is too short probably a mistake')
    }
  }

  return (
    <div className={classes.root}>
      <Typography>
        Šita formą išsiunčia mailą visiem kompanijų administratoriam. naudoti saikingai ir atsakingai.
      </Typography>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              validate={validatorRequired}
              name="email"
              component={TextFieldAdapter}
              label="Send copy to this email"
            />
            <Field validate={validatorRequired} name="subject" component={TextFieldAdapter} label="Subject" />
            <Field name="content" component={EditorField} label="Email Content" />
            <Button type="submit">Send Email</Button>
          </form>
        )}
      />
    </div>
  )
}
