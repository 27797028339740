import React, { useEffect, useState } from 'react'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { FullScreenLoader } from '../common/full-screen-loader'
import { User } from '../users/user.type'

export const AuthContext: React.Context<{
  currentUser: User
  idToken: string
}> = React.createContext({
  currentUser: undefined,
  idToken: undefined,
})

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined)
  const [idToken, setIdToken] = useState(undefined)
  const [pending, setPending] = useState(true)

  const firebase = useFirebase()
  const firestore = useFirestore()

  useEffect(() => {
    let unsubscribe
    firebase.auth().onIdTokenChanged(user => {
      if (user == null) {
        // logout
        unsubscribe?.()
        setCurrentUser(user)
        setIdToken(undefined)
        setPending(false)
      } else {
        // for login wit for user record to be created by cloud function
        unsubscribe = firestore.doc(`users/${user.uid}`).onSnapshot(snapshot => {
          if (snapshot.exists) {
            user.getIdToken(true).then(idToken => {
              setCurrentUser(user)
              setIdToken(idToken)
              setPending(false)
            })
          }
        })
      }
    })
    return () => {
      unsubscribe?.()
    }
  }, [firebase])

  if (pending) {
    return <FullScreenLoader />
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        idToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
