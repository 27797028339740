import { Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core'
import CardMedia from '@material-ui/core/CardMedia'
import ClearIcon from '@material-ui/icons/Clear'
import React from 'react'
import palette from '../../theme/palette'
import { Product } from '../../types/product-type'
import { getValueText } from '../../products/product-helper'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 345,
  },
  largeBlueText: {
    color: palette.primary.main,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '35px',
    margin: '16px',
  },
  midBlueText: {
    color: palette.primary.main,
  },
  value: {
    display: 'inline-block',
    margin: '0 5px',
  },
  discountPrice: {
    fontSize: 18,
    display: 'inline-block',
    margin: '0 5px',
    fontWeight: 500,
    color: palette.text.secondary,
  },
  media: {
    height: 140,
  },
  productCount: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.success.main,
    fontSize: 36,
    textAlign: 'right',
    width: '100%',
  },
  avatar: {
    border: '1px solid #546e7a',
    '& img': {
      objectFit: 'scale-down',
    },
  },
}))

type ProductDetailsCardProps = { product: Product; borderClassName?: string }

export const ProductDetailsCard: React.FC<ProductDetailsCardProps> = props => {
  const { product } = props
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      {/* <CardHeader
        avatar={
          <Avatar alt={company ? company.name : ''} src={company ? company.logoUrl : ''} className={classes.avatar}>
            R
          </Avatar>
        }
        title={company ? company.name : ''}
      /> */}
      <CardMedia
        className={classes.media}
        image={
          product.images?.length > 0 && product.images[0].url ? product.images[0].url : '/images/brand-placeholder.png'
        }
        title={product.title}
      />
      <CardContent>
        <Typography align="center" gutterBottom variant="h6">
          {product.title}
        </Typography>
        <Typography align="center" variant="h2">
          {getValueText(product)}
        </Typography>
        <Typography align="center" variant="subtitle2">
          {product.shortDescription}
        </Typography>
      </CardContent>
      {product.count > 1 && (
        <CardActions>
          <div className={classes.productCount}>
            <ClearIcon />
            <span>{product.count}</span>
          </div>
        </CardActions>
      )}
    </Card>
  )
}
