import { Button, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { useSelector } from '../../store'
import { useFirestore } from 'react-redux-firebase'
import { OrderStatus } from '../../types/order/order-status.enum'
import { ConfirmDialog } from '../../_core/components/confirm-dialog'
import { useSnackbar } from 'notistack'
import Label from '../../_core/components/label'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  warning: {
    fontWeight: 500,
    color: theme.palette.error.dark,
  },
}))

export const SingleOrderToolbar = props => {
  const { order } = props
  const classes = useStyles()
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const isFinished = order && order.status === OrderStatus.FINISHED
  const { enqueueSnackbar } = useSnackbar()
  const showSuccess = () => {
    enqueueSnackbar('Talonai išsiųsti sėkmingai', {
      variant: 'success',
    })
  }
  const handleConfirmOrder = () => {
    const itemUpdates = {
      status: OrderStatus.FINISHED,
    }
    firestore.collection(`companies/${profile.companyId}/orders`).doc(order.id).update(itemUpdates).then(showSuccess)
  }
  if (!order) return null
  return (
    <div className={clsx(classes.root)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <ConfirmDialog
          onConfirm={() => handleConfirmOrder()}
          title={`Užsakymo #${order.number} patvirtinimas`}
          content={
            <div>
              <Typography color="inherit" variant="body1">
                Patvirtinus užsakymą bus suformuoti Talonai ir klientui pristatyti el. paštu
              </Typography>
              <Typography className={classes.warning}>
                Prieš patvirtindami užsakymus įsitikinkite, kad pavedimas yra atliktas sėkmingai
              </Typography>
            </div>
          }
        >
          <Button color="primary" disabled={isFinished} onClick={() => handleConfirmOrder()} variant="contained">
            {isFinished ? 'Užsakymas patvirtintas' : 'Patvirtinti užsakymą'}
          </Button>
        </ConfirmDialog>
      </div>
    </div>
  )
}
