import React from 'react'
import { Button, Dialog } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Help } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}))
export const InfoDialog: React.FC<any> = ({ children, title, onConfirm, okText, disabled = false }) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  return (
    <>
      <IconButton aria-label="delete" onClick={() => setOpen(true)} className={classes.margin} size="small">
        <Help fontSize="inherit" />
      </IconButton>
      <Dialog open={open} onClose={_ => setOpen(false)} maxWidth={'sm'}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={_ => setOpen(false)}>
            Atgal
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
