import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, IconButton, Link, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Company } from '../types/company-type'
import { useSelector } from '../store'
import { FullScreenLoader } from '../common/full-screen-loader'
import Button from '@material-ui/core/Button'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { useSnackbar } from 'notistack'
const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 3),
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  content: {
    marginTop: theme.spacing(4),
    maxWidth: 900,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  header: {
    fontSize: 18,
    marginBottom: theme.spacing(2),
  },
  subHeader: {
    fontSize: 16,
    marginBottom: theme.spacing(0.5),
  },
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  verification: {
    display: 'inline-block',
    border: '1px solid',
    borderColor: theme.palette.text.primary,
    borderRadius: 5,
    padding: 8,
    whiteSpace: 'normal',
  },
}))
const InfoCopy = ({ profile }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const copySuccess = () => {
    enqueueSnackbar('Žinutė nukopijuota', {
      variant: 'success',
    })
  }
  return (
    <>
      <div className={classes.verification}>Įmonės identifikavimo kodas: {profile.companyId}</div>
      <br />
      <br />
      <Button
        variant={'outlined'}
        onClick={() =>
          navigator.clipboard.writeText(`Įmonės identifikavimo kodas: ${profile.companyId}`).then(copySuccess)
        }
        startIcon={<FileCopyIcon />}
      >
        Kopijuoti tekstą
      </Button>
    </>
  )
}
export const FeaturedCompany: React.FC<any> = props => {
  const classes = useStyles()
  const history = useHistory()
  const profile = useSelector(state => state.firebase.profile)

  const handleBack = () => {
    history.goBack()
  }
  return (
    <div className={classes.root}>
      <div>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.content}>
            <Typography variant="h4" className={classes.header}>
              Kodėl įmonė nėra matoma pirmame puslapyje?
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
              Šiuo metu įmones tvrtinam "rankiniu" būdu. Sistemos administratorius kas kelias valandas patikrina įmones,
              kurios susivedė visą reikalingą informacijas, radęs tokių, peržiūri ar turinys yra tvarkingas ir
              patvirtina.
              <br />
              <br />
              <b>Būtina sąlyga</b> turėti susikūrus nominalų, nes tik tada jūsų klientai galį kažką realiai įsigyti.{' '}
              <br />
              <br />
              Mums patikęs pavyzdys, kur viskas gražiai ir tvarkingai suvesta: <br />
              <a href={'https://talonai.lt/company/ciop-ciop'} rel="noreferrer noopener" target={'_blank'}>
                https://talonai.lt/company/ciop-ciop
              </a>{' '}
              <br />
              <br />
              <b>Svarbu: </b> įsitikinkite, kad jūsų juridinio asmens duomenys ir banko sąskaita yra tvarkingai suvesti,
              buvo atveju, kai žmonės negalėjo pervesti pinigų, nes trūko vieno skaitmens sąskaitos numeryje.
              <br />
              <br />
              <br />
              Viską atlikus rekomenduojame su mumis susisiekti žemiau pateiktais būdais. <br />
              <br />
              <br />
            </Typography>
            <Typography variant="h4" className={classes.header}>
              Kaip patvirtinti įmonę?
            </Typography>
            <Typography component="h2" className={classes.subHeader} variant="overline">
              Būdas #1 Per Facebook
            </Typography>

            <Typography variant="body1" className={classes.paragraph}>
              1. Apsilankę mūsų facebook paskyroje parašykite žinutę <br />
              <a href="https://www.facebook.com/talonai" target="_top">
                https://www.facebook.com/talonai
              </a>
              <br />
              Nuoroda tiesiai į messenger: <br />
              <a href={'https://m.me/talonai'} target="_top">
                Eiti tiesiai į messenger
              </a>
              <br />
              2. Parašykite žinutę su šia informacija:
              <br />
              <br />
              <InfoCopy profile={profile} />
              <br />
              <br />
            </Typography>
            <br />
            <br />
            <Typography component="h2" className={classes.subHeader} variant="overline">
              Būdas #2 Elektroniniu paštu
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
              Parašykite mum laišką ir nurodykite šį kodą:
              <br />
              <br />
              <InfoCopy profile={profile} />
            </Typography>
            <br />
            <br />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
