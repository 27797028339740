import * as React from 'react'
import { Coupon } from '../types/coupon-type'
import { PdfCoupon } from './pdf-coupon'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from '../store'
import { useParams } from 'react-router-dom'
import { PdfCouponV2 } from './pdf-coupon-v2'
import { PdfCouponV3 } from './pdf-v3/pdf-coupon-v3'

type Props = {}
export const PdfTestComponent: React.FC<any> = () => {
  const params = useParams<any>()
  useFirestoreConnect({ collection: `companies`, doc: params.companyId })
  const company = useSelector(
    ({
      firestore: {
        ordered: { companies },
      },
    }) => companies && companies[0],
  )
  useFirestoreConnect([
    {
      collection: `companies/${params.companyId}/coupons/`,
      doc: `${params.couponId}`,
      storeAs: 'singleCoupon',
    },
  ])
  const coupon: Coupon = useSelector(state =>
    state.firestore.ordered.singleCoupon ? state.firestore.ordered.singleCoupon[0] : null,
  )
  if (!coupon || !company) return null
  return <PdfCouponV3 coupon={coupon} company={company} />
}
