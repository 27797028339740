import { Coordinate } from 'ol/coordinate'
import { AnyAction } from 'redux'
import { toOl } from './map-utils'
import {
  ACTION_LIST_ITEM_HOVER_CHANGE,
  ACTION_SET_SELECTED_COMPANY,
  ACTION_UPDATE_VISIBLE_COMPANIES,
} from './map-actions'
import { Company } from '../types/company-type'

const MAP_UPDATE_STATE = 'MAP_UPDATE_STATE'

export const updateMapStateAction = (zoom: number, center: Coordinate) => ({
  type: MAP_UPDATE_STATE,
  zoom,
  center,
})

export type MapState = {
  center: Coordinate
  zoom: number
  selectedCompany?: Company
  visibleCompanies: string[]
  hoveredCompanyId: string
}

const initialState: MapState = {
  center: toOl(25.2800104, 54.6920793),
  zoom: 12,
  selectedCompany: undefined,
  hoveredCompanyId: undefined,
  visibleCompanies: [],
}

export const mapReducer = (state = initialState, action: AnyAction): MapState => {
  switch (action.type) {
    case MAP_UPDATE_STATE:
      return {
        ...state,
        zoom: action.zoom,
        center: action.center,
      }
    case ACTION_SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.company,
      }
    case ACTION_UPDATE_VISIBLE_COMPANIES:
      return {
        ...state,
        visibleCompanies: action.companyIds,
      }
    case ACTION_LIST_ITEM_HOVER_CHANGE:
      return {
        ...state,
        hoveredCompanyId: action.companyId,
      }
    default:
      return state
  }
}
