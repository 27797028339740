import { firebaseReducer } from 'react-redux-firebase'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { mapReducer, MapState } from '../map/map-reducer'
import { productsReducer } from '../products/products-reducer'
import { countOrderReducer } from '../views/count-order/count-order-reducer'
import { filtersReducer } from '../views/home/filters/filters-reducer'
import { shareReducer, ShareState } from '../customer/share/share-reducer'
import { couponsReducer, CouponsState } from '../coupons/coupons-reducer'

export type OrderData = {
  id: string
  count: number
  title: string
  price: number
}

export type CountOrder = {
  companyId: string | undefined
  orderData: { [key: string]: OrderData }
}

export type AppState = {
  firebase: any
  firestore: any
  countOrder: CountOrder
  products: any
  coupons: CouponsState
  filters: any
  map: MapState
  share: ShareState
}

export const appReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  countOrder: countOrderReducer,
  products: productsReducer,
  coupons: couponsReducer,
  filters: filtersReducer,
  map: mapReducer,
  share: shareReducer,
})
