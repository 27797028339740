import { Button, Card, CardContent, CardHeader } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import FilesDropzone from '../../_core/components/files-dropzone'

const useStyles = makeStyles(() => ({
  root: {},
}))

export const ProductImages: React.FC<any> = props => {
  const classes = useStyles()
  const fb = useFirebase()
  const firestore = useFirestore()

  const { className, input, title, companyId } = props

  const onFilesChanged = (files: File[]) => {
    if (files.length > 0) {
      const file: File = files[0]
      const fileName = `${firestore.collection('someplace').doc().id}.${file.name.split('.').pop()}`
      fb.uploadFile(`files/${companyId}`, file, undefined, { name: fileName })
        .then(result => result.uploadTaskSnapshot.ref.getDownloadURL())
        .then(url => input.onChange({ url }))
    }
  }

  const handleDeleteImage = () => {
    input.onChange({ url: '' })
  }

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title={title} />
      <CardContent>
        {input.value?.url ? (
          <div>
            <img alt={title} src={input.value?.url} />
            <Button onClick={handleDeleteImage}>Ištrinti</Button>
          </div>
        ) : (
          <FilesDropzone onFilesChanged={onFilesChanged} />
        )}
      </CardContent>
    </Card>
  )
}
