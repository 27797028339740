import { Company } from '../../types/company-type'

export const filterCompanies = (company: Company, search: string): boolean => {
  if (search) {
    if (company.id === search) return true
    const words = search.split(' ')
    return words.every(
      word =>
        contains(company.name, word) ||
        contains(company.businessName, word) ||
        contains(company.shortDescription, word) ||
        contains(company.alias, word),
    )
  } else {
    return true
  }
}
export const filterByCity = (company: Company, city: string): boolean => {
  let cities = company.locations ? company.locations.map(location => location.city) : []
  if (company.city) {
    cities.push(company.city)
  }
  return cities.some(c => c.toLowerCase() === city.toLowerCase())
}
export const sortByCreated = (companies: Company[]): Company[] => {
  return companies.slice().sort((a, b) => {
    if (!a.created) return 1
    if (!b.created) return -1
    if (a.created.toMillis() > b.created.toMillis()) {
      return -1
    } else {
      return 1
    }
  })
}
export const filterByCategory = (company: Company, categories: any): boolean => {
  if (categories) {
    if (!company.category) {
      //if company doesnt have a category
      return true
    } else {
      if (categories.hasOwnProperty(company.category)) {
        return categories[company.category]
      } else {
        //if company has a category that we do not handle
        return true
      }
    }
  } else {
    //if filters are empty just show company
    return true
  }
}

export const contains = (value, search): boolean =>
  value ? value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 : false
