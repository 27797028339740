import React from 'react'
import { Product } from '../../../types/product-type'
import { Company } from '../../../types/company-type'
import clsx from 'clsx'
import CardMedia from '@material-ui/core/CardMedia'
import { getImageUrl, ImageType } from '../../../images/image-utils'
import { Card, CardActions, CardContent, Divider, makeStyles, Typography } from '@material-ui/core'
import { getExpirationText, getExpirationTypeText, shortenDescription } from '../../../_core/utils/coupon-utils'
import palette from '../../../theme/palette'
import Avatar from '@material-ui/core/Avatar'
import CardHeader from '@material-ui/core/CardHeader'
import CardActionArea from '@material-ui/core/CardActionArea'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    width: 260,
    minWidth: 260,
    margin: '0 8px',
  },
  highlighted: {
    boxShadow: `0px 0px 0px 6px ${theme.palette.primary.main}`,
  },

  statsItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  largeBlueText: {
    color: palette.primary.main,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '35px',
    margin: '16px',
  },
  button: {
    fontWeight: 700,
    fontSize: '24px',
  },
  midBlueText: {
    color: palette.primary.main,
  },
  blueText: {
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: '14px',
  },
  smallInput: {
    textAlign: 'center',
    maxWidth: '32px',
    '& input': {
      textAlign: 'center',
    },
  },
  value: {
    display: 'inline-block',
    margin: '0 5px',
  },
  discountPrice: {
    fontSize: 18,
    display: 'inline-block',
    margin: '0 5px',
    fontWeight: 500,
    color: palette.text.secondary,
  },
  media: {
    height: 140,
  },
  badges: {
    fontSize: 12,
  },
  shortDescr: {
    height: 40,
  },
  avatar: {
    border: '1px solid #546e7a',
    background: '#ffffff',
    '& img': {
      objectFit: 'scale-down',
    },
  },
}))

type Props = {
  product: Product
  company: Company
}
const Discount = ({ product }) => {
  const classes = useStyles()
  if (product.discount) {
    return <span className={classes.discountPrice}>už €{product.price}</span>
  }
  return null
}
const Value = ({ product }) => {
  const classes = useStyles()
  if (product.discount) {
    return <span className={classes.value}>€{product.value}</span>
  }
  return <span>€{product.price}</span>
}
export const FeaturedProduct: React.FC<Props> = ({ product, company }) => {
  const classes = useStyles()
  return (
    <Card className={clsx(classes.root)}>
      <CardActionArea>
        <Link to={`/company/${company.alias}`}>
          <CardHeader
            avatar={
              <Avatar
                alt={company ? company.name : ''}
                src={company ? getImageUrl(company.logoUrl, ImageType.SMALL) : ''}
                className={classes.avatar}
              >
                R
              </Avatar>
            }
            title={company ? company.name : ''}
            subheader={
              product.hasExpirationDate
                ? `${product.expirationAmount} ${getExpirationTypeText(
                    product.expirationAmount,
                    product.expirationType,
                  )}`
                : 'Neribotą laiką'
            }
          />
          <CardMedia
            className={classes.media}
            image={
              product.images?.length > 0 && product.images[0].url
                ? getImageUrl(product.images[0].url, ImageType.SMALL)
                : '/images/brand-placeholder.png'
            }
            title={product.title}
          />
          <CardContent>
            <Typography align="center" gutterBottom className={classes.midBlueText} variant="h6">
              {product.title}
            </Typography>
            <Typography align="center" className={classes.largeBlueText} variant="h2">
              <Value product={product} />
              <Discount product={product} />
            </Typography>
            <Typography className={classes.shortDescr} align="center" variant="subtitle2">
              {shortenDescription(product.shortDescription, 60)}
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  )
}
