import { FormLabel, Grid, RadioGroup } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import React from 'react'

export const CubitRadioGroupAdapter: React.FC<any> = (props: any) => {
  const { name, options, direction, label, input, meta, ...custom } = props

  const radioButtons = options.map((option: any, index: number) => {
    return (
      <Grid key={index} item>
        <FormControlLabel
          label={option.label}
          value={option.value}
          style={option.style}
          control={<Radio color="primary" {...custom} />}
        />
      </Grid>
    )
  })

  const val = input.value === true ? 'true' : input.value === false ? 'false' : input.value

  const dir = direction || 'column'

  const handleOnChange = (event: any) => {
    const v = event.target.value
    const changedValue = v === 'true' ? true : v === 'false' ? false : v

    input.onChange(changedValue)
  }

  return (
    <FormControl component="fieldset">
      {label ? (
        <FormLabel component="legend" style={{ marginBottom: '8px' }}>
          {label}
        </FormLabel>
      ) : null}
      <RadioGroup name={name} value={val} onChange={handleOnChange}>
        <Grid container direction={dir}>
          {radioButtons}
        </Grid>
      </RadioGroup>
    </FormControl>
  )
}
