import { CouponFilter } from '../types/coupon/coupon-filter'
import { formatDate } from '../_core/utils/date-utils'
import { Coupon } from '../types/coupon-type'
import { getCouponHumanId, getExpirationDate } from '../_core/utils/coupon-utils'

export const getExportFileName = (filters: CouponFilter): string => {
  const fromDate = formatDate(filters.periodFrom)
  const toDate = formatDate(filters.periodTo)
  return `${fromDate}_${toDate}.csv`
}

export const getExportData = (coupons: Coupon[]): any => {
  return coupons.map((coupon: Coupon) => {
    return {
      uzsakymo_nr: coupon.order ? '#' + coupon.order.number : 'NO_ORDER',
      talono_id: getCouponHumanId(coupon),
      nominalo_kodas: coupon.product.sku,
      email: coupon.email,
      verte: toNumber(coupon.product.value),
      panaudotas: coupon.claimed,
      panaudojimo_data: formatDate(coupon.claimedDate),
      sukurtas: formatDate(coupon.created),
      galioja_iki: getExpirationDate(coupon),
    }
  })
}
const toNumber = value => {
  return Number(value).toFixed(2)
}
