import moment from 'moment'
import { Coupon, ShareStatus } from '../../types/coupon-type'

export const getExpirationText = (coupon: Coupon): string => {
  if (coupon.product.hasExpirationDate && coupon.product.expirationAmount) {
    const validUntil = moment(coupon.created.toDate())
      .add(coupon.product.expirationAmount, coupon.product.expirationType)
      .format('YYYY-MM-DD')
    return `Galioja iki: ${validUntil}`
  } else {
    return 'Galioja visada'
  }
}

export const getExpirationDate = (coupon: Coupon): string => {
  if (coupon.product.hasExpirationDate && coupon.product.expirationAmount) {
    const validUntil = moment(coupon.created.toDate())
      .add(coupon.product.expirationAmount, coupon.product.expirationType)
      .format('YYYY-MM-DD')
    return validUntil
  } else {
    return 'Visada'
  }
}

export const getExpiration = (coupon: Coupon): Date => {
  const validUntil = moment(coupon.created.toDate())
    .add(coupon.product.expirationAmount, coupon.product.expirationType)
    .toDate()
  return validUntil
}
export const getExpirationTypeText = (amount: number, type: string): string => {
  switch (type) {
    case 'years':
      return 'metus'
    case 'months':
      if ((amount > 10 && amount < 20) || amount % 10 === 0) {
        return 'mėnesių'
      }
      if (amount % 10 === 1) {
        return 'mėnesį'
      }
      return 'mėnesius'
    case 'days':
      if ((amount > 10 && amount < 20) || amount % 10 === 0) {
        return 'dienų'
      }
      if (amount % 10 === 1) {
        return 'dieną'
      }
      return 'dienas'
    default:
      return type
  }
}
export const isPastDueDate = (coupon: Coupon): boolean => {
  if (!coupon) return false
  if (!coupon.product.hasExpirationDate) return false
  const deadline = moment(coupon.created.toDate()).add(coupon.product.expirationAmount, coupon.product.expirationType)
  if (moment().isSameOrBefore(deadline)) {
    return false
  }
  return true
}

export const getCouponHumanId = (coupon: Coupon): string => {
  return getHumanId(coupon.id)
}

export const getHumanId = (id: string): string => {
  return id.slice(-5)
}

export const getUsedCouponsSum = (coupons: Coupon[]): number => {
  return coupons
    .filter(c => c.claimed)
    .reduce((total: number, coupon: Coupon) => {
      return (total += Number(coupon.product.price))
    }, 0)
}
export const getUnUsedCouponsSum = (coupons: Coupon[]): number => {
  return coupons
    .filter(c => !c.claimed)
    .reduce((total: number, coupon: Coupon) => {
      return (total += Number(coupon.product.price))
    }, 0)
}

export const getExpiredCouponSum = (coupons: Coupon[]): number => {
  return coupons
    .filter(c => isPastDueDate(c))
    .reduce((total: number, coupon: Coupon) => {
      return (total += Number(coupon.product.price))
    }, 0)
}

export const shortenDescription = (description?: string, length: number = 160): string => {
  if (!description) return ''
  let short = description.slice(0, length)
  if (description.length > short.length) {
    short += '...'
  }
  return short
}

export const getShareStatusText = (status: ShareStatus): string => {
  switch (status) {
    case ShareStatus.ACEPTED:
      return 'Priimtas'
    case ShareStatus.PENDING:
      return 'Laukiama'
    default:
      return 'Unknown'
  }
}
