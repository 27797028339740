import { Button, Card } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useSelector } from '../../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { getExpirationText } from '../../_core/utils/coupon-utils'
import { getImageUrl, ImageType } from '../../images/image-utils'
import ClearIcon from '@material-ui/icons/Clear'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Coupon } from '../../types/coupon-type'
import { actionIncrementShareStep, actionSelectCouponsGroup } from './share-actions'
import { useDispatch } from 'react-redux'
import { getValueText } from '../../products/product-helper'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  media: {
    height: 140,
  },
  avatar: {
    border: '1px solid #546e7a',
    '& img': {
      objectFit: 'scale-down',
    },
  },
  productCount: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.success.main,
    fontSize: 36,
    textAlign: 'right',
    width: '100%',
  },
}))
type Props = {
  group: Coupon[]
  coupon: Coupon
}
export const ShareCouponCard: React.FC<Props> = ({ group, coupon }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  useFirestoreConnect({ collection: `companies`, doc: coupon.companyId })
  const company = useSelector(
    ({
      firestore: {
        data: { companies },
      },
    }) => companies && companies[coupon.companyId],
  )
  const handleSelect = (group: Coupon[]) => {
    dispatch(actionSelectCouponsGroup(group))
    dispatch(actionIncrementShareStep(1))
  }
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardHeader
          avatar={
            <Avatar
              alt={company ? company.name : ''}
              src={company ? getImageUrl(company.logoUrl, ImageType.SMALL) : ''}
              className={classes.avatar}
            >
              R
            </Avatar>
          }
          title={company ? company.name : ''}
          subheader={getExpirationText(coupon)}
        />
        <CardMedia
          className={classes.media}
          image={
            coupon.product.images.length > 0 && coupon.product.images[0].url
              ? coupon.product.images[0].url
              : '/images/brand-placeholder.png'
          }
          title={coupon.product.title}
        />
        <CardContent>
          <Typography align="center" gutterBottom variant="h6">
            {coupon.product.title}
          </Typography>
          <Typography align="center" variant="h2">
            {getValueText(coupon.product)}
          </Typography>
          <Typography align="center" variant="subtitle2">
            {coupon.product.shortDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button variant={'contained'} color={'primary'} onClick={() => handleSelect(group)}>
          Pasirinkti{' '}
        </Button>
        <div className={classes.productCount}>
          <ClearIcon />
          <span>{group.length}</span>
        </div>
      </CardActions>
    </Card>
  )
}
