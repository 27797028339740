import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { acceptCoupon, getCoupon } from '../../share/share-utils'
import { useParams } from 'react-router-dom'
import { AcceptCouponsRequest } from '../../types/share-couons-request-type'
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CouponCard } from './coupon-card'
import { Coupon } from '../../types/coupon-type'
import { firestore } from 'firebase'
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(3),
  },
  box: {
    width: '100%',
    maxWidth: '400px',
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const AcceptCoupon: React.FC<any> = () => {
  const classes = useStyles()
  const firebase = useFirebase()
  const params = useParams<any>()
  const [coupon, setCoupon] = useState<Coupon | undefined>(undefined)
  const request: AcceptCouponsRequest = {
    companyId: params.companyId,
    couponId: params.couponId,
    code: params.code,
  }
  useEffect(() => {
    getCoupon(firebase, request)
      .then(result => {
        console.log(result.data)
        result.data.created = firestore.Timestamp.fromMillis(result.data.created._seconds * 1000)
        setCoupon(result.data)
      })
      .catch(() => {
        setCoupon(undefined)
      })
  }, [])

  const handleAccept = () => {
    acceptCoupon(firebase, request)
      .then(() => {
        console.log('success')
      })
      .catch(error => {
        console.log(error)
      })
  }
  console.log('coupon', coupon)
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {coupon ? (
          <div>
            <CouponCard coupon={coupon} />

            <Button onClick={handleAccept}>accept</Button>
          </div>
        ) : (
          <CircularProgress />
        )}
      </div>
    </div>
  )
}
