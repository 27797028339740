import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { tr } from '../../../translations/translate'
import { TEXT_FOR_BUSINESS, TEXT_MY_COUPONS } from '../../../translations/keys'
import React from 'react'

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: '160px',
    [theme.breakpoints.down('xs')]: {
      minWidth: 'none',
    },
  },
  start: {
    margin: '0 auto 0 0',
  },
  logo: {
    margin: '0',
    color: 'white',
    fontSize: '35px',
    textTransform: 'uppercase',
    lineHeight: 1,
    textShadow: '0px 0px 32px black',
    letterSpacing: '5px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },

  logoImg: {
    width: '150px',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90px',
    },
  },
}))
export const SplashTop = () => {
  const classes = useStyles()
  return (
    <Grid item>
      <Grid container spacing={2} justify="flex-end" alignItems="center">
        <Grid item className={classes.start}>
          <Typography variant="h3" className={classes.logo}>
            <Link to={'/'}>
              <img className={classes.logoImg} alt={'Talonai'} src={'/images/logos/logo.svg'} />
            </Link>
          </Typography>
        </Grid>
        <Grid item>
          <Button component={Link} to={'/login'} variant="contained" color="primary" className={classes.button}>
            {tr(TEXT_FOR_BUSINESS)}
          </Button>
        </Grid>
        <Grid item>
          <Button component={Link} to={'/user-login'} variant="contained" color="primary" className={classes.button}>
            {tr(TEXT_MY_COUPONS)}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
