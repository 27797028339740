import { Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from '../../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { CompanyCard } from './company-card'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import { filterByCategory, filterByCity, filterCompanies } from '../../_core/utils/filter-utils'
import { Link, useParams } from 'react-router-dom'
import { CategoryFilter } from './category-filter'
import Button from '@material-ui/core/Button'
import MapIcon from '@material-ui/icons/Map'

import { tr } from 'translations/translate'
import { TEXT_SEARCH, TEXT_SELECT_LOCATION, TEXT_TYPE_IN_KEYWORDS } from 'translations/keys'
import { useDispatch } from 'react-redux'
import { actionSetCity } from './filters/filters-actions'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    margin: '0 auto',
    marginTop: theme.spacing(2),
  },
  pagination: {
    // marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  showMore: {
    margin: '24px 0',
  },
  mapButton: { marginBottom: '12px' },
}))

export const CompanyList = props => {
  const classes = useStyles()
  const { companies } = props
  const [search, setSearch] = useState('')
  const [showCount, setShowCount] = useState(12)
  const params = useParams<any>()
  const dispatch = useDispatch()
  const city = useSelector(state => state.filters.city)
  useFirestoreConnect([
    {
      collection: 'cityFilters',
      storeAs: 'cities',
      orderBy: ['city', 'asc'],
    },
  ])
  useEffect(() => {
    if (params && params.city) {
      dispatch(actionSetCity(params.city))
    }
  }, [params])
  const cities = useSelector(({ firestore: { ordered: { cities } } }) => (cities ? cities : null))
  const categoryFilters = useSelector(state => state.filters.categories)
  console.log('showCount', showCount)

  const filteredCompanies = companies
    .filter(company => filterByCity(company, city))
    .filter(company => filterCompanies(company, search))
    .filter(company => filterByCategory(company, categoryFilters))
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {cities && cities.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={3}>
              <TextField
                label={tr(TEXT_SEARCH)}
                placeholder={tr(TEXT_TYPE_IN_KEYWORDS)}
                fullWidth
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
                variant={'outlined'}
                type={'text'}
                onChange={(event: any) => setSearch(event.target.value)}
              />
              <div className={classes.mapButton}>
                <Button variant={'contained'} component={Link} to={'/map'} startIcon={<MapIcon />}>
                  Žiūrėti žemėlapyje
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={5} lg={6}>
              <CategoryFilter />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <FormControl fullWidth variant={'outlined'}>
                <InputLabel htmlFor="demo-simple-select-label">{tr(TEXT_SELECT_LOCATION)}</InputLabel>
                <Select
                  label={'Pasirinkite miestą'}
                  inputProps={{
                    name: 'demo',
                    id: 'demo-simple-select-label',
                  }}
                  onChange={(event: any) => dispatch(actionSetCity(event.target.value))}
                  value={city}
                >
                  {cities.map(x => (
                    <MenuItem key={x.id} value={x.city}>
                      {x.city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} justify="space-around">
          {companies &&
            filteredCompanies.slice(0, showCount).map(company => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={company.id}>
                <CompanyCard company={company} />
              </Grid>
            ))}
        </Grid>
        {showCount < filteredCompanies.length && (
          <Grid container className={classes.showMore} justify={'center'}>
            <Button
              variant="contained"
              onClick={() => setShowCount(showCount + 12 * 8)}
              size={'large'}
              color={'primary'}
            >
              Rodyti daugiau
            </Button>
          </Grid>
        )}
      </div>
    </div>
  )
}
