import { Dialog } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import firebase from 'firebase/app'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from '../store'
import { useFirestore } from 'react-redux-firebase'
import { TextFieldAdapter } from '../forms/inputs/text-field-adapter'
import { validatorRequired } from '../_core/form-validators/validators'
import { SelectFieldAdapter } from '../forms/inputs/select-field-adapter'
import Typography from '@material-ui/core/Typography'
import { useSnackbar } from 'notistack'
const roles = [
  { label: 'Darbuotojas', value: 'user' },
  { label: 'Sistemos administratorius', value: 'admin' },
]
export const AddUser: React.FC<any> = props => {
  const { isOpen, close } = props
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const { enqueueSnackbar } = useSnackbar()
  const showSuccess = () => {
    enqueueSnackbar('Pakvietimas išsiųstas', {
      variant: 'success',
    })
  }
  const onSubmit = values => {
    const user = {
      ...values,
      companyId: profile.companyId,
      created: firebase.firestore.Timestamp.now(),
      updated: firebase.firestore.Timestamp.now(),
    }
    firestore
      .collection(`companies/${profile.companyId}/invites`)
      .add(user)
      .then(showSuccess)
      .catch(error => console.log(error))
    close()
  }
  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Pakviesti darbuotoją</DialogTitle>
            <DialogContent>
              <div>
                <Field
                  name="role"
                  defaultValue={'user'}
                  validate={validatorRequired}
                  component={SelectFieldAdapter}
                  items={roles}
                  label="Rolė"
                />
              </div>
              <div style={{ marginBottom: '16px' }}>
                {values.role === 'admin' && (
                  <Typography>
                    Administratoriaus rolė turi visas įmanomas teises: <br />
                    Kurti/trinti/redaguoti nominalus
                    <br />
                    Tvirtinti užsakymus
                    <br />
                    Redaguoti įmonės informaciją
                    <br />
                    Kviesti kitus administratorius
                    <br />
                  </Typography>
                )}
                {values.role === 'user' && (
                  <Typography>Darbuotojo rolė gali tik patvirtinti Talonus, kuomet jiems parodomas QR kodas</Typography>
                )}
              </div>
              <div>
                <Field name="email" component={TextFieldAdapter} label="El. paštas" />
              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" type="submit">
                Siųsti pakvietimą
              </Button>
              <Button variant="text" color="primary" onClick={close}>
                Atšaukti
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  )
}
