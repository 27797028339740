import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useSelector } from '../../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import { Order } from '../../types/order-type'
import { PdfInvoice } from '../../pdf/pdf-invoice'
import { Company } from '../../types/company-type'
import { FullScreenLoader } from '../../common/full-screen-loader'

const useStyles = makeStyles(theme => ({
  card: {},
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
}))
export const CustomerInvoice: React.FC<any> = props => {
  const params = useParams<any>()
  const classes = useStyles()
  const companyDocument = `companies/${params.companyId}`
  useFirestoreConnect(companyDocument)
  const company: Company | undefined = useSelector(
    ({ firestore: { data } }) => data.companies && data.companies[params.companyId],
  )
  useFirestoreConnect([
    {
      collection: `companies/${params.companyId}/orders/`,
      doc: `${params.orderId}`,
      storeAs: 'singleOrder',
    },
  ])
  const order: Order = useSelector(state =>
    state.firestore.ordered.singleOrder ? state.firestore.ordered.singleOrder[0] : null,
  )
  console.log('order', order)
  console.log('company', company)
  if (!order || !company) return <FullScreenLoader />
  return (
    <div className={classes.root}>
      <PdfInvoice order={order} company={company} />
    </div>
  )
}
