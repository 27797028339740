import { Company } from '../types/company-type'
export const ACTION_SET_SELECTED_COMPANY = 'ACTION_SET_SELECTED_COMPANY'
export const ACTION_UPDATE_VISIBLE_COMPANIES = 'ACTION_UPDATE_VISIBLE_COMPANIES'
export const ACTION_LIST_ITEM_HOVER_CHANGE = 'ACTION_LIST_ITEM_HOVER_CHANGE'

export const actionSetSelectedCompany = (company: Company) => ({
  type: ACTION_SET_SELECTED_COMPANY,
  company: company,
})
export const actionCompanyHoverChange = (companyId: string) => ({
  type: ACTION_LIST_ITEM_HOVER_CHANGE,
  companyId: companyId,
})
export const actionUpdateVisibleCompanies = (companyIds: string[]) => ({
  type: ACTION_UPDATE_VISIBLE_COMPANIES,
  companyIds: companyIds,
})
