export const getErrorText = error => {
  switch (error.code) {
    case 'auth/invalid-email':
      return 'Neteisingai suformatuotas el. pašto adresas'
    case 'auth/email-already-in-use':
      return 'Šis el. paštas jau užregistruotas'
    case 'auth/expired-action-code':
      return 'Ši magiškoji nuoroda prarado savo galias, reikės šviežios.'
    case 'auth/user-disabled':
      return 'Šis vartotojas yra suspenduotas. Jei galvojat, kad tai yra didelė neteisybė, susisiekite pagalba@talonai.lt'
    case 'auth/wrong-password':
      return 'Neteisingas slaptažodis'
    case 'auth/user-not-found':
      return 'Vartotojas su tokiu el. paštu nerastas'
    default:
      return error.message
  }
}
