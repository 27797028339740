import { AppBar } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Toolbar from '@material-ui/core/Toolbar'
import clsx from 'clsx'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  logo: {
    color: '#fff',
    marginLeft: 8,
    letterSpacing: '2px',
    fontWeight: 700,
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    width: '90px',
  },
  menuButton: {},
}))

export const MapTopBar = props => {
  const { className, onSidebarOpen, ...rest } = props

  const classes = useStyles()
  const history = useHistory()

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar variant="dense">
        <IconButton
          onClick={() => history.goBack()}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <ArrowBackIcon />
        </IconButton>
        <Link to="/">
          <div className={classes.logoWrapper}>
            <img className={classes.logoImage} alt="Logo" src={`/images/logos/logo.svg`} />
          </div>
        </Link>
        <div className={classes.flexGrow} />
      </Toolbar>
    </AppBar>
  )
}
