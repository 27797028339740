import { Card } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { QRCode } from 'react-qr-svg'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import { Config } from '../../config'
import { useSelector } from '../../store'
import { Coupon } from '../../types/coupon-type'
import { getExpirationText } from '../../_core/utils/coupon-utils'
import CardActions from '@material-ui/core/CardActions'
import { CouponStatus } from '../../coupons/components/coupon-status'
import { getImageUrl, ImageType } from '../../images/image-utils'
import { getValueText } from '../../products/product-helper'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    maxWidth: 345,
  },

  media: {
    height: 140,
    textAlign: 'center',
    '& svg': {
      height: 120,
      margin: '10px 0',
      padding: 10,
      background: '#ffffff',
    },
  },
  notClaimed: { backgroundColor: '#00ff00' },
  claimed: { backgroundColor: '#ff0000' },

  avatar: {
    border: '1px solid #546e7a',
  },
  helpText: {
    maxWidth: 345,
    padding: 10,
    marginBottom: 20,
  },
  companyName: {
    whiteSpace: 'pre',
  },
  couponActions: {
    display: 'flex',
    justifyContent: 'center',
  },
}))
export const ClaimCoupon: React.FC<any> = () => {
  const params = useParams<any>()
  const classes = useStyles()
  useFirestoreConnect({ collection: `companies`, doc: params.companyId })
  const company = useSelector(
    ({
      firestore: {
        ordered: { companies },
      },
    }) => companies && companies[0],
  )
  useFirestoreConnect([
    {
      collection: `companies/${params.companyId}/coupons/`,
      doc: `${params.couponId}`,
      storeAs: 'singleCoupon',
    },
  ])
  const coupon: Coupon = useSelector(state =>
    state.firestore.ordered.singleCoupon ? state.firestore.ordered.singleCoupon[0] : null,
  )
  return (
    <div className={classes.root}>
      {coupon && company ? (
        <div>
          <Typography align="center" className={classes.helpText} variant="subtitle2">
            Norint išnaudoti šį taloną, duokite nuskenuoti QR kodą <b className={classes.companyName}>{company.name}</b>{' '}
            darbuotojui
          </Typography>
          <Card className={classes.card}>
            <>
              <CardHeader
                avatar={
                  <Avatar
                    alt={company ? company.name : ''}
                    src={company ? getImageUrl(company.logoUrl, ImageType.SMALL) : ''}
                    className={classes.avatar}
                  >
                    R
                  </Avatar>
                }
                title={company ? company.name : ''}
                subheader={getExpirationText(coupon)}
              />
              <CardMedia
                className={classes.media}
                image={
                  coupon.product.images?.length > 0 ? coupon.product.images[0].url : '/images/brand-placeholder.png'
                }
                title={coupon.product.title}
              >
                <QRCode value={`${Config.baseUrl}/confirm-claim/${coupon.id}`} />
              </CardMedia>
              <CardContent>
                <Typography align="center" gutterBottom variant="h6">
                  {coupon.product.title}
                </Typography>
                <Typography align="center" variant="h2">
                  {getValueText(coupon.product)}
                </Typography>
                <Typography align="center" variant="subtitle2">
                  {coupon.product.shortDescription}
                </Typography>
              </CardContent>
              <CardActions className={classes.couponActions}>
                <CouponStatus coupon={coupon} />
              </CardActions>
            </>
          </Card>
        </div>
      ) : null}
    </div>
  )
}
