import { FormControlLabel } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import React from 'react'

export const CheckboxFieldAdapter: React.FC<any> = (props: any) => {
  const {
    input: { name, value, checked, onChange, onBlur, onFocus },
    options,
    meta: { touched, error },
    label,
    helperText,
    ...rest
  } = props
  return (
    <FormGroup row>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            error={touched && error ? true : false}
            helpertext={touched && error ? error : helperText}
            {...rest}
            name={name}
            onChange={(event: any) => onChange(event)}
            onFocus={onFocus}
            onBlur={onBlur}
            checked={checked}
          />
        }
      />
    </FormGroup>
  )
}
