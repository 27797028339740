import { Config } from '../config'
import { en } from './languages/en'
import { lt } from './languages/lt'
import { Language } from './language.enum'
export const strings: any = {}
strings[Language.English] = en
strings[Language.Lithuanian] = lt
const getLang = (): string => {
    //const lang = localStorage.getItem(Config.localStorageLangKey)
    //FOR NOW:
    const lang = Config.language
    return lang !== null ? lang : Config.language
}
export const tr = (key: string, params?: Array<any>): string => {
    const lang = getLang()
    let translated = ''
    try {
        const translatedString = strings[lang][key] || ''
        // eslint-disable-next-line
        translated = eval('`' + translatedString + '`')
    } catch (e) {
        console.log('translation error', { key, params })
    }
    return translated
}