import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useSelector } from '../../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import { Order } from '../../types/order-type'
import { PdfInvoice } from '../../pdf/pdf-invoice'
import { Company } from '../../types/company-type'
import { FullScreenLoader } from '../../common/full-screen-loader'
import { Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  card: {},
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
}))
export const Invoice: React.FC<any> = props => {
  const params = useParams<any>()
  const classes = useStyles()
  const profile = useSelector(state => state.firebase.profile)
  const company: Company | undefined = useSelector(
    ({ firestore: { data } }) => data.companies && data.companies[profile.companyId],
  )
  useFirestoreConnect([
    {
      collection: `companies/${profile.companyId}/orders/`,
      doc: `${params.orderId}`,
      storeAs: 'singleOrder',
    },
  ])
  const order: Order = useSelector(state =>
    state.firestore.ordered.singleOrder ? state.firestore.ordered.singleOrder[0] : null,
  )
  if (!order || !company) return <FullScreenLoader />
  return (
    <div className={classes.root}>
      <div className="no-print">
        <div style={{ textAlign: 'center', padding: 40 }}>
          <Typography>
            Kol neįgyvendintas patogesnis būdas, rekomenduojame spausti CTRL+P (Windows), Command + P (macOS) ir
            spausdinimo lange pasirinkti išsaugoti kaip PDF
          </Typography>
        </div>
      </div>
      <PdfInvoice order={order} company={company} />
    </div>
  )
}
