import { Container, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import firebase from 'firebase/app'
import React from 'react'
import { Field, FieldRenderProps, Form } from 'react-final-form'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from '../store'
import { ProductType } from '../types/product/product-type.enum'
import { ProductDetails } from './components/product-details'
import { ProductImages } from './components/product-images'
import { Company } from '../types/company-type'
import { FullScreenLoader } from '../common/full-screen-loader'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(3),
  },

  actions: {
    marginTop: theme.spacing(3),
  },
}))
export const AddProduct: React.FC<FieldRenderProps<string, HTMLInputElement>> = props => {
  // const { isOpen, close } = props
  const classes = useStyles()
  const firestore = useFirestore()
  const fb = useFirebase()
  const history = useHistory()
  const profile = useSelector(state => state.firebase.profile)
  const company: Company | undefined = useSelector(
    ({ firestore: { data } }) => data.companies && data.companies[profile.companyId],
  )
  const onSubmit = values => {
    let product = {
      ...values,
      companyId: profile.companyId,
      created: firebase.firestore.Timestamp.now(),
      updated: firebase.firestore.Timestamp.now(),
      value: values.discount ? values.value : values.price,
      images: values.images ?? [{ url: '' }],
    }
    console.log({ product })
    firestore
      .collection(`companies/${profile.companyId}/products`)
      .add(product)
      .then(() => history.goBack())
  }
  if (!company) return <FullScreenLoader />
  if (!company.accountNumber)
    return (
      <div className={classes.root}>
        <Container maxWidth="lg">
          <div>
            <Typography>
              Norint pridėti nominalus ir leisti klietams juos užsisakyti, suveskite reikalingą įmonės informaciją{' '}
              <Link to={'/account'}>čia</Link>
            </Typography>
          </div>
        </Container>
      </div>
    )
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div>
          <Typography component="h2" gutterBottom variant="overline">
            Naujas nominalas
          </Typography>
        </div>
        <Form
          onSubmit={onSubmit}
          initialValues={{ type: ProductType.STANDARD }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <ProductDetails values={values} className={classes.section} />

              <Field
                name="images[0]"
                component={ProductImages}
                companyId={profile.companyId}
                title="Nominalo foto"
                className={classes.section}
              />

              <div className={classes.actions}>
                <Button color="primary" type="submit" variant="contained">
                  Pridėti nominalą
                </Button>
              </div>
            </form>
          )}
        />
      </Container>
    </div>
  )
}
