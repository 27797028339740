import React from 'react'
import { Button, Dialog } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

export const ConfirmDialog: React.FC<any> = ({ children, title, content, onConfirm, okText, disabled = false }) => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      {React.cloneElement(children, { onClick: _ => setOpen(true) })}
      <Dialog open={open} onClose={_ => setOpen(false)} maxWidth={'sm'}>
        {title && <DialogTitle>{title}</DialogTitle>}
        {content && <DialogContent>{content}</DialogContent>}
        <DialogActions>
          <Button color="primary" onClick={_ => setOpen(false)}>
            Atšaukti
          </Button>
          <Button
            disabled={disabled}
            color="primary"
            variant="contained"
            onClick={() => {
              onConfirm()
              setOpen(false)
            }}
          >
            {okText ? okText : 'Patvirtinti'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
