import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { Company } from '../../types/company-type'
import { useSelector } from '../../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Product } from '../../types/product-type'
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '10px',
    color: '#ffffff',
    cursor: 'pointer',
    backgroundColor: theme.palette.error.dark,
  },
  text: {
    color: '#ffffff',
  },
  link: {
    textDecoration: 'underline',
    color: '#ffffff',
  },
}))
export const CompanyVerification: React.FC<any> = ({}) => {
  const classes = useStyles()
  const history = useHistory()
  const profile = useSelector(state => state.firebase.profile)
  const company: Company | undefined = useSelector(
    ({ firestore: { data } }) => data.companies && data.companies[profile.companyId],
  )

  if (!company || company.featured) return null
  return (
    <div className={classes.root} onClick={() => history.push('/feature-my-company')}>
      <Typography variant="body1" className={classes.text}>
        Jūsų įmonė nėra matoma pirmame puslapyje. Spauskite ir sužinosite kaip tą pakeisti{' '}
      </Typography>
    </div>
  )
}
