import React, { useEffect } from 'react'
import { Button, CircularProgress, Dialog } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from '../../store'
import { Company } from '../../types/company-type'
type MoreInfoDialogType = {
  company: Company
}
const Info: React.FC<MoreInfoDialogType> = ({ company }) => {
  const firestore = useFirestore()
  useEffect(() => {
    firestore.get({
      collection: 'users',
      doc: company.createdBy,
      storeAs: 'creator',
    })
  }, [firestore])
  const creator = useSelector(
    ({
      firestore: {
        ordered: { creator },
      },
    }) => creator && creator[0],
  )
  if (!creator) return <CircularProgress color="primary" />
  return (
    <div>
      <Typography>
        Įmonę užregistravo: <br />
        {creator.name}
        <br />
        <b>{creator.email}</b>
        <br />
        Rolė: {creator.role} - (čia turėtų būti admin)
        <br />
        įmonės id: {creator.companyId}
        <br />
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export const MoreInfoDialog: React.FC<MoreInfoDialogType> = ({ company }) => {
  const [open, setOpen] = React.useState(false)

  const classes = useStyles()
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <InfoIcon />
      </IconButton>
      <Dialog open={open} onClose={_ => setOpen(false)} maxWidth={'sm'}>
        <DialogContent>
          <Info company={company} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={_ => setOpen(false)}>
            Vsio jasna
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
