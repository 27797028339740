import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import React from 'react'
import { useDispatch } from 'react-redux'
import { actionSetSearch } from './filters-actions'

export const SearchFilter = () => {
  const dispatch = useDispatch()
  const setSearch = (search: string) => {
    dispatch(actionSetSearch(search))
  }
  return (
    <TextField
      label={'Paieška'}
      placeholder={'Įveskite paieškos žodžius'}
      fullWidth
      InputProps={{
        endAdornment: <SearchIcon />,
      }}
      variant={'outlined'}
      type={'text'}
      onChange={(event: any) => setSearch(event.target.value)}
    />
  )
}
