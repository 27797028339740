import React from 'react'
import { useSelector } from '../store'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { Redirect, Route } from 'react-router-dom'
import { CustomerLayout } from '../layout/customer-layout'
import { FullScreenLoader } from '../common/full-screen-loader'
import { registerPageView } from '../_core/analytics/analytics'

export const CustomerPanelRoute = ({ component: RouteComponent, ...rest }) => {
  const auth = useSelector(state => state.firebase.auth)
  return (
    <Route
      {...rest}
      render={routeProps => {
        if (isLoaded(auth) && !isEmpty(auth)) {
          registerPageView(rest.pageTitle)
          return (
            <CustomerLayout>
              <RouteComponent {...routeProps} />{' '}
            </CustomerLayout>
          )
        } else if (isLoaded(auth) && isEmpty(auth)) {
          return <Redirect to={'/user-login'} />
        } else {
          return <FullScreenLoader />
        }
      }}
    />
  )
}
