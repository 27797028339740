import * as keys from '../keys'
export const lt: { [index: string]: string } = {}
// home-splash
lt[keys.TEXT_FOR_BUSINESS] = 'Verslui'
lt[keys.TEXT_MY_COUPONS] = 'MANO TALONAI'
lt[keys.TEXT_HOME_SPLASH_1] = 'Pasiilgai savo lankomiausių vietų?'
lt[keys.TEXT_FEELING_IS_MUTUAL] = 'Jausmas abipusis.'
lt[keys.TEXT_FOLLOW_US] = 'Sekite mus:'
// globoja-vilnius
lt[keys.TEXT_GLOBOJA_VILNIUS_1] = "Projektą globoja Vilniaus miesto savivaldybė"
// home
lt[keys.TEXT_GET_COUPON_NOW] = "Įsigyk Taloną dabar."
lt[keys.TEXT_ENJOY_LATER] = "Mėgaukis vėliau."
lt[keys.TEXT_CHOOSE_A_PLACE] = "Pasirink vietą, kurią nori palaikyti."
lt[keys.TEXT_ORDER_COUPONS] = "Užsisakyk Talonų, ir jie atkeliaus į tavo el. paštą."
lt[keys.TEXT_ENJOY_WHEN] = "Mėgaukis kai tavo mėgstamiausieji vėl atvers duris."
lt[keys.TEXT_MISSION_WHY] = "Misija / Kodėl?"
lt[keys.TEXT_HOME_1] = "TALONAI gimė iš noro per karantiną padėti Lietuvos verslui, kuriam darbas iš namų neįmanomas. Dauguma turime širdžiai mielų vietų, kuriose norėtume kuo dažniau apsilankyti. Taip pat vertiname į savą verslą įdėtą sunkų darbą ir sukurtas darbo vietas."
lt[keys.TEXT_HOME_2] = "Ši sistema - tai paprastas būdas palaikyti tuos, kuriuos vertiname ir pasimėgauti gerumo vaisiais šiek tiek vėliau."
lt[keys.TEXT_HOW] = "KAIP?"
lt[keys.TEXT_HOME_3] = "Verslas gali savo lojaliems klientams pasiūlyti patogų būdą kaip jį paremti karantino metu, parduodant savo Talonus, kuriuos galima įsigyti banko pavedimu, o išleisti verslams ir vėl atvėrus duris. Tam nereikia jokios papildomos įrangos, užteks interneto ir išmaniojo telefono."
lt[keys.TEXT_FOR_FREE]= "VISKAS NEMOKAMAI?"
lt[keys.TEXT_HOME_4] = "Taip, ši paslauga jums nieko nekainuoja."
lt[keys.TEXT_OFFERING_COUPONS] = "Talonus siūlo:"
//tutorial section
lt[keys.TEXT_I_WANT_TO_OFFER_COUPONS] = "Noriu pasiūlyti Talonų"
lt[keys.TEXT_SIGN_UP] = "Užsiregistruokite"
lt[keys.TEXT_TUTORIAL_01] = "ir savo paskyroje užpildykite informaciją apie savo įmonę."
lt[keys.TEXT_TUTORIAL_02] = "Sukurkite savo talonų nominalus puslapyje 'Nominalai'."
lt[keys.TEXT_TUTORIAL_03] = "Norėdami patvirtinti įmonę, sekite instrukcijas (tai nesudėtinga)."
lt[keys.TEXT_TUTORIAL_04] = "Pasidalinkite savo įmonės puslapiu savo socialiniuose kanaluose, kad klientai galėtų užsisakyti Talonų."
lt[keys.TEXT_TUTORIAL_05] = "Laukite užsakymų: gausite pranešimus el. paštu taip pat visus užsakymus galite matyti prisijungę prie sistemos."
lt[keys.TEXT_TUTORIAL_06] = "Sukurtas užsakymas iškart patenka į 'laukiama patvirtinimo' būseną, patikrinkit savo banko sąskaitą kelis kartus per dieną ir patvirtinkite užsakymus, už kuriuos sumokėta."
lt[keys.TEXT_TUTORIAL_07] = "Patvirtinus užsakymą sugeneruojami Talonai (pvz., 5 po 20 Eur) ir pristatomi klientui elektroniniu paštu."
lt[keys.TEXT_TUTORIAL_08] = "Kiekvienas Talonas turi QR kodą, kurį nuskaičius, naršyklėje patenkat į patvirtinimo langą. Jame matyti ar Talonas yra galiojantis, turite galimybę jį išleisti, taip užbaigdami šio Talono kelionę."
lt[keys.TEXT_TUTORIAL_09] = "Paskutinis, bet ne prasčiausias: laukite sistemos tobulinimo ir aiškesnio naudojimosi vadovo"
lt[keys.TEXT_I_WANT_GET_SOME_COUPONS] = "Noriu įsigyti Talonų"
lt[keys.TEXT_TUTORIAL_11] = "Išsirinkite vietą, kurią norėtumėte palaikyti. Tikimės, kad verslai pasidalins nuorodomis savo socialiniuose kanaluose."
lt[keys.TEXT_TUTORIAL_12] = "Pasirinkite Talonus, kuriuos norėtumėte įsigyti."
lt[keys.TEXT_TUTORIAL_13] = "Užpildę reikalingus duomenis, gausite elektroninį laišką su informacija, reikalinga atlikti bankinį pavedimą."
lt[keys.TEXT_TUTORIAL_14] = "Talonų pardavėjas, gavęs jūsų užsakymą bei patvirtinęs, kad mokėjimas gautas, sugeneruoja jūsų Talonus, kuriuos galėsite išleisti mokėdami už pardavėjo prekes ar paslaugas."
lt[keys.TEXT_TUTORIAL_15] = "Gaunate elektroninį laišką, kuriame yra failas su visais jūsų įsigytais Talonais."
lt[keys.TEXT_TUTORIAL_16] = "Jūsų pateikti užsakymai ir Talonai taip pat matomi prisijungus prie paskyros (niekada nepamesit), paspaudus"
lt[keys.TEXT_TUTORIAL_17] = "Siekdami sistemą padaryti prieinamą visiems kuo greičiau ir nemokamai, nusprendėme atsisakyti integruotos apmokėjimo sistemos. Talonų galima įsigyti bankiniu pavedimu, pavedimą atliekant tiesiai į įmonės sąskaitą. Suprantam, 2020"
lt[keys.TEXT_MORE_INFO] = "Daugiau informacijos"
lt[keys.TEXT_CONTACTS] = "Kontaktai"
lt[keys.TEXT_HELP_FOR_BUSINESSES] = "Pagalba verslui"
lt[keys.TEXT_DO_YOU_HAVE_ANY_QUESTIONS] = "Turite klausimų dėl įsigytų kuponų?"
lt[keys.TEXT_EMAIL] = "El. paštas"
lt[keys.TEXT_FAQ] = "DUK"
lt[keys.TEXT_ANSWERS_TO_FREQUENTLY_ASKED_QUESTIONS] = "Atsakymai į dažniausiai verslui ir lankytojams kylančius klausimus"
lt[keys.TEXT_FOR_CUSTOMERS] = "Lankytojams"
// comapny-list
lt[keys.TEXT_SEARCH] = "Paieška"
lt[keys.TEXT_TYPE_IN_KEYWORDS] = "Įveskite paieškos žodžius"
lt[keys.TEXT_SELECT_LOCATION] = "Pasirinkite miestą"
//category-filter
lt[keys.TEXT_SELECT_CATEGORIES] = "Pasirinkite kategorijas"
lt[keys.TEXT_FOOD_AND_DRINK] = "Maistas ir gėrimai"
lt[keys.TEXT_ACTIVITIES] = "Laisvalaikis"
lt[keys.TEXT_SERVICES] = "Paslaugos"
// company-card
lt[keys.TEXT_GET_A_COUPON] = "Įsigyk Taloną"