import { firestore } from 'firebase'
import * as m from 'moment'

const moment = m.default

export const DEFAULT_DATE = 'YYYY-MM-DD'

export const formatDate = (date?: any, format?: string): string => {
  if (!format) {
    format = DEFAULT_DATE
  }
  if (!date) {
    return ''
  }
  return moment(date.toDate()).format(format)
}
export const getTimeStamp = () => firestore.Timestamp.now()

export const getStartOfMonth = () => moment().startOf('month')
export const getEndOfMonth = () => moment().endOf('month')

export const getStartOfYear = () => moment().startOf('year')
export const getEndOfYear = () => moment().endOf('year')

export const getStartOfLastMonth = () => moment().subtract(1, 'months').startOf('month')
export const getEndOfLastMonth = () => moment().subtract(1, 'months').endOf('month')
