import { Card } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { getExpirationText } from '../_core/utils/coupon-utils'
import { useSelector } from '../store'
import { CouponStatus } from './components/coupon-status'
import { ConfirmDialog } from '../_core/components/confirm-dialog'
import { Coupon } from '../types/coupon-type'
import { useSnackbar } from 'notistack'
import { getImageUrl, ImageType } from '../images/image-utils'
import firebase from 'firebase/app'
import { getValueText } from '../products/product-helper'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  avatar: {
    border: '1px solid #546e7a',
    background: '#ffffff',
    '& img': {
      objectFit: 'scale-down',
    },
  },
  couponActions: {
    display: 'flex',
    justifyContent: 'center',
  },
  pastDue: {
    backround: theme.palette.error.dark,
    color: '#ffffff',
  },
}))
type ConfirmCouponType = {
  coupon: Coupon
}
export const ConfirmCoupon: React.FC<ConfirmCouponType> = props => {
  const classes = useStyles()
  const { coupon } = props
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  useFirestoreConnect({ collection: `companies`, doc: coupon.companyId })
  const company = useSelector(
    ({
      firestore: {
        data: { companies },
      },
    }) => companies && companies[coupon.companyId],
  )
  const { enqueueSnackbar } = useSnackbar()
  const showSuccess = () => {
    enqueueSnackbar('Talonas išnaudotas sėkmingai', {
      variant: 'success',
    })
  }
  const showError = () => {
    enqueueSnackbar('Įvyko klaida, perkraukite ir įsitikinkite kad Talonas išnaudotas', {
      variant: 'error',
    })
  }
  const handleConfirm = () => {
    const itemUpdates = {
      claimed: true,
      claimedDate: firebase.firestore.Timestamp.now(),
    }
    firestore
      .update(`companies/${profile.companyId}/coupons/${coupon.id}`, itemUpdates)
      .then(showSuccess)
      .catch(showError)
    //firestore.collection(`companies/${params.companyId}/coupons/${params.couponId}`).update(itemUpdates)
  }
  return (
    <Grid item lg={4}>
      <Card className={classes.root}>
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar
                alt={company ? company.name : ''}
                src={company ? getImageUrl(company.logoUrl, ImageType.SMALL) : ''}
                className={classes.avatar}
              >
                R
              </Avatar>
            }
            title={company ? company.name : ''}
            subheader={getExpirationText(coupon)}
          />
          <CardMedia
            className={classes.media}
            image={
              coupon.product.images.length > 0 && coupon.product.images[0].url
                ? coupon.product.images[0].url
                : '/images/brand-placeholder.png'
            }
            title={coupon.product.title}
          />
          <CardActions className={classes.couponActions}>
            <CouponStatus coupon={coupon} />
          </CardActions>
          <CardContent>
            <Typography align="center" gutterBottom variant="h6">
              {coupon.product.title}
            </Typography>
            <Typography align="center" variant="h2">
              {getValueText(coupon.product)}
            </Typography>
            <Typography align="center" variant="subtitle2">
              {coupon.product.shortDescription}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          {!coupon.claimed ? (
            <ConfirmDialog
              onConfirm={() => handleConfirm()}
              title={`Talono patvirtinimas`}
              content={
                <Typography color="inherit" variant="body1">
                  <b>{getExpirationText(coupon)}</b> <br />
                  Talono užsakovas: <b>{coupon.email}</b> <br />
                  Talono pilnas kodas: <b>{coupon.id}</b> <br />
                  <br />
                  Talono vertė: <b>{getValueText(coupon.product)}</b>
                  <br />
                  <br />
                  Išnaudojus Taloną būdo sugrįžti atgal nėra. Klientas gaus el. laišką, kad Talonas sunaudotas.
                </Typography>
              }
            >
              <Button size="small" variant={'contained'} color="primary">
                Panaudoti taloną
              </Button>
            </ConfirmDialog>
          ) : (
            <Typography>Šis talonas yra išnaudotas</Typography>
          )}
        </CardActions>
      </Card>
    </Grid>
  )
}
