import { Container, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DeleteIcon from '@material-ui/icons/Delete'
import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from '../store'
import { Product } from '../types/product-type'
import { ProductCard } from '../views/company/product-card'
import { ConfirmDialog } from '../_core/components/confirm-dialog'
import { ProductDetails } from './components/product-details'
import { ProductImages } from './components/product-images'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(3),
  },

  topButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  deleteAction: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  currentProduct: {
    marginTop: theme.spacing(1),
  },
  currentProductText: {
    marginBottom: theme.spacing(2),
  },
}))
export const EditProduct: React.FC<any> = props => {
  // const { isOpen, close } = props
  const classes = useStyles()
  const params = useParams<any>()
  const firestore = useFirestore()
  const fb = useFirebase()
  const history = useHistory()
  const profile = useSelector(state => state.firebase.profile)
  const { enqueueSnackbar } = useSnackbar()
  const showSuccess = () => {
    enqueueSnackbar('Išsaugota sėkmingai', {
      variant: 'success',
    })
  }
  useFirestoreConnect([
    {
      collection: `companies/${profile.companyId}/products/`,
      doc: `${params.productId}`,
      storeAs: 'singleProduct',
    },
  ])
  const product: Product = useSelector(state =>
    state.firestore.ordered.singleProduct ? state.firestore.ordered.singleProduct[0] : null,
  )
  const onSubmit = values => {
    let product = {
      ...values,
      updated: firebase.firestore.Timestamp.now(),
      value: values.discount ? values.value : values.price,
      images: values.images ?? [{ url: '' }],
    }
    firestore.collection(`companies/${profile.companyId}/products`).doc(product.id).update(product).then(showSuccess)
  }
  const handleDelete = product => {
    firestore
      .delete({ collection: `companies/${profile.companyId}/products`, doc: product.id })
      .then(() => history.goBack())
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div>
          <Typography component="h2" gutterBottom variant="overline">
            Nominalo redagavimas
          </Typography>
        </div>
        {product && (
          <Grid container spacing={3}>
            <Grid item md={8} xl={9} xs={12}>
              <Form
                onSubmit={onSubmit}
                initialValues={{ ...product }}
                render={({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={classes.topButtons}>
                      <Button color="primary" type="submit" variant="contained">
                        Išsaugoti
                      </Button>
                      <ConfirmDialog
                        onConfirm={() => handleDelete(product)}
                        title={'Svarbus veiksmas'}
                        content={
                          <Typography color="inherit" variant="body1">
                            Ar tikrai norite ištrinti produktą negrįžtamai? Visi parduoti talonai turi šio produkto
                            kopija ir toliau veiks kaip ir anksčiau.
                          </Typography>
                        }
                      >
                        <Button
                          color="secondary"
                          className={classes.deleteAction}
                          variant="contained"
                          startIcon={<DeleteIcon />}
                        >
                          Ištrinti
                        </Button>
                      </ConfirmDialog>
                    </div>
                    <ProductDetails values={values} className={classes.section} />

                    <Field
                      name="images[0]"
                      component={ProductImages}
                      companyId={profile.companyId}
                      title="Nominalo foto"
                      className={classes.section}
                    />

                    <div className={classes.actions}>
                      <Button color="primary" type="submit" variant="contained">
                        Išsaugoti
                      </Button>
                    </div>
                  </form>
                )}
              />
            </Grid>
            <Grid item md={4} xl={3} xs={12}>
              <div className={classes.currentProduct}>
                <Typography className={classes.currentProductText}>
                  Taip atrodo šis nominalas jūsų klientams <br />
                  Paspaudus išsaugoti matysite pasikeitusius duomenis
                </Typography>
              </div>
              <ProductCard companyId={profile.companyId} product={product} />
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  )
}
