import { Button, Card, CardActions, CardContent, Divider, makeStyles, Typography } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import CardMedia from '@material-ui/core/CardMedia'
import InputBase from '@material-ui/core/InputBase'
import clsx from 'clsx'
import React from 'react'
import { useDispatch } from 'react-redux'
import palette from '../../theme/palette'
import { Product } from '../../types/product-type'
import { countOrderUpdateAction } from '../count-order/count-order-actions'
import { useSelector } from 'store'
import { getExpirationTypeText } from '../../_core/utils/coupon-utils'
import 'moment/locale/lt'
import { getImageUrl, ImageType } from '../../images/image-utils'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
  },
  highlighted: {
    boxShadow: `0px 0px 0px 6px ${theme.palette.primary.main}`,
  },

  statsItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  largeBlueText: {
    color: palette.primary.main,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '35px',
    margin: '16px',
  },
  button: {
    fontWeight: 700,
    fontSize: '24px',
  },
  midBlueText: {
    color: palette.primary.main,
  },
  blueText: {
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: '14px',
  },
  smallInput: {
    textAlign: 'center',
    maxWidth: '32px',
    '& input': {
      textAlign: 'center',
    },
  },
  value: {
    display: 'inline-block',
    margin: '0 5px',
  },
  discountPrice: {
    fontSize: 18,
    display: 'inline-block',
    margin: '0 5px',
    fontWeight: 500,
    color: palette.text.secondary,
  },
  media: {
    height: 140,
  },
  badges: {
    fontSize: 12,
  },
}))
const Discount = ({ product }) => {
  const classes = useStyles()
  if (product.discount) {
    return (
      <span className={classes.discountPrice}>
        už {product.price} {product.prefix ?? '€'}
      </span>
    )
  }
  return null
}

const Value = ({ product }) => {
  const classes = useStyles()
  if (product.discount) {
    return (
      <span className={classes.value}>
        {product.value}
        {product.prefix ?? '€'}
      </span>
    )
  }
  return (
    <span>
      {product.price}
      {product.prefix ?? '€'}
    </span>
  )
}
export const ProductCard = props => {
  const { className, companyId, mini, ...rest } = props
  const product = props.product as Product
  const classes = useStyles()
  const amount = useSelector(state => state.countOrder.orderData[product.id]?.count) ?? 0
  const dispatch = useDispatch()

  const handleChange = count => {
    const order = { id: product.id, count: count, title: product.title, price: product.price }
    dispatch(countOrderUpdateAction(order, companyId))
  }
  return (
    <Card {...rest} className={clsx(classes.root, className, { [classes.highlighted]: amount > 0 })}>
      <CardMedia
        className={classes.media}
        image={
          product.images?.length > 0 && product.images[0].url
            ? getImageUrl(product.images[0].url, ImageType.SMALL)
            : '/images/brand-placeholder.png'
        }
        title={product.title}
      />
      <CardContent>
        <Typography align="center" gutterBottom className={classes.midBlueText} variant="h6">
          {product.title}
        </Typography>
        <Typography align="center" className={classes.largeBlueText} variant="h2">
          <Value product={product} />
          <Discount product={product} />
        </Typography>
        <Typography align="center" variant="subtitle2">
          {product.shortDescription}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <div className={classes.statsItem}>
          <div>
            <Typography align="center" variant="subtitle2">
              Galioja:{' '}
              {product.hasExpirationDate
                ? `${product.expirationAmount} ${getExpirationTypeText(
                    product.expirationAmount,
                    product.expirationType,
                  )}`
                : 'Neribotą laiką'}
            </Typography>
          </div>
          <ButtonGroup variant="outlined" aria-label="small outlined button group">
            <Button
              className={classes.button}
              size="small"
              variant="outlined"
              onClick={() => handleChange(amount > 0 ? amount - 1 : 0)}
            >
              -
            </Button>
            <Button
              onClick={event => event.preventDefault()}
              className={classes.button}
              size="small"
              disableRipple={true}
              disableFocusRipple={true}
              disableTouchRipple={true}
              centerRipple={false}
              variant="outlined"
            >
              <InputBase
                onChange={event => handleChange(Number(event.target.value))}
                type={'number'}
                className={classes.smallInput}
                value={amount}
                inputProps={{ min: 0 }}
              />
            </Button>

            <Button className={classes.button} size="small" variant="outlined" onClick={() => handleChange(amount + 1)}>
              +
            </Button>
          </ButtonGroup>
        </div>
      </CardActions>
    </Card>
  )
}
