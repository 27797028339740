import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import React from 'react'

const useStyles = makeStyles(theme => ({
  homeSplash: {
    padding: theme.spacing(3),
    flexWrap: 'nowrap',
  },

  button: {
    minWidth: '160px',
  },

  fullHeight: {
    height: '100%',
  },

  homeSplashTextContainer: {
    cursor: 'default',
    userSelect: 'none',
    flexGrow: 1,
    textAlign: 'center',
    transform: 'translateZ(0)',
    textShadow: '0px 0px 32px black',
  },

  heroHeader: {
    maxWidth: '1000px',
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(1),
    margin: '0 auto',
    color: 'white',
    fontSize: '100px',
    lineHeight: 1,
    letterSpacing: '-1px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '600px',
      fontSize: '56px',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '42px',
    },
  },
  start: {
    margin: '0 auto 0 0',
  },
  logo: {
    // maxWidth: '1000px',
    // marginBottom: theme.spacing(12),
    //marginTop: theme.spacing(1),
    margin: '0',
    color: 'white',
    fontSize: '35px',
    textTransform: 'uppercase',
    lineHeight: 1,
    textShadow: '0px 0px 32px black',
    letterSpacing: '5px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      //   maxWidth: '600px',
      fontSize: '24px',
      //marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.down('xs')]: {
      //   maxWidth: '600px',
      fontSize: '16px',
      //marginBottom: theme.spacing(6),
    },
  },
  subHeader: {
    margin: '0 auto',
    color: 'white',
    fontWeight: 700,
    maxWidth: '600px',
    fontSize: '48px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '28px',
    },
  },
  emoji: {
    margin: '40px auto 0 auto',
    fontSize: '32px',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  down: {
    display: 'block',
    margin: '40px auto 40px auto',
  },
  vilnius: {
    marginTop: theme.spacing(5),
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  emojiDown: {
    transform: 'rotate(90deg)',
    fontSize: '48px',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
  },
  logoImg: {
    width: '150px',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },
}))
type Props = {
  linkTo?: string
}
export const TopHeader: React.FC<Props> = ({ linkTo }) => {
  const classes = useStyles()

  return (
    <Grid container direction="column" className={classes.homeSplash}>
      <Grid item>
        <Grid container spacing={2} justify="flex-end" alignItems="center">
          <Grid item className={classes.start}>
            <Typography variant="h3" className={classes.logo}>
              <Link to={linkTo ? linkTo : '/'}>
                <img className={classes.logoImg} alt={'Talonai'} src={'/images/logos/logo-dark.svg'} />
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Button component={Link} to={'/login'} variant="contained" color="primary" className={classes.button}>
              Verslui
            </Button>
          </Grid>
          <Grid item>
            <Button component={Link} to={'/user-login'} variant="contained" color="primary" className={classes.button}>
              Mano talonai
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
