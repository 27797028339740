import * as React from 'react'
import { Company } from '../types/company-type'
import { Order } from '../types/order-type'
import { getCompanyAddress } from '../_core/utils/company-utils'
import { formatDate } from '../_core/utils/date-utils'
import { getOrderTotal, getTotalBeforeVat, toCurrency } from '../_core/utils/orders-utils'

type Props = {
  order: Order
  company: Company
}
export const getCustomerInformation = (order: Order) => {
  if (order.companyName) {
    return (
      <div>
        <b>{order.companyName}</b> <br />
        <b>Įmonės kodas: </b> {order.companyOrgNumber}
        <br />
        <b>Adresas: </b> {order.companyAddress}
        <br />
        {order.vatNumber && (
          <span>
            <b>PVM kodas: </b> {order.vatNumber}
          </span>
        )}
        <br />
        {order.email}
      </div>
    )
  }
  return (
    <div>
      <b>
        {order.firstName} {order.lastName}
      </b>
      <br />
      {order.email}
    </div>
  )
}
export const PdfInvoice: React.FC<Props> = ({ order, company }) => {
  return (
    <div
      style={{
        width: '680px',
        margin: '0 auto',
        fontFamily: 'Roboto,Helvetica,sans-serif',
        fontSize: '12px',
        color: '#000000',
        padding: '24px',
      }}
    >
      <div style={{ fontSize: '16px' }}>
        <b>Sąskaitos nr:</b> #{order.number}
        <br />
        <b>Data:</b> {formatDate(order.created)}
        <br />
        <br />
      </div>
      <div style={{ fontFamily: 'Roboto,Helvetica,sans-serif', fontSize: '12px', color: '#000000' }}>
        <div>
          <table
            style={{
              borderCollapse: 'collapse',
              width: '100%',
              borderTop: '1px solid #dddddd',
              borderLeft: '1px solid #dddddd',
              marginBottom: '20px',
            }}
          >
            <thead>
              <tr>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    backgroundColor: '#efefef',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    padding: '7px',
                    color: '#222222',
                  }}
                >
                  Pirkėjas
                </td>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    backgroundColor: '#efefef',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    padding: '7px',
                    color: '#222222',
                  }}
                >
                  Pardavėjas
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    textAlign: 'left',
                    padding: '7px',
                  }}
                >
                  {getCustomerInformation(order)}
                  <br />
                </td>

                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    textAlign: 'left',
                    padding: '7px',
                  }}
                >
                  <b>{company.businessName}</b> <br />
                  <b>Įmonės kodas: </b> {company.orgNumber}
                  <br />
                  <b>Adresas: </b> {getCompanyAddress(company)}
                  <br />
                  {company.vatNumber && (
                    <span>
                      <b>PVM kodas: </b> {company.vatNumber}
                    </span>
                  )}
                  <br />
                  {company.email}
                </td>
              </tr>
            </tbody>
          </table>

          <table
            style={{
              borderCollapse: 'collapse',
              width: '100%',
              borderTop: '1px solid #dddddd',
              borderLeft: '1px solid #dddddd',
              marginBottom: '20px',
            }}
          >
            <thead>
              <tr>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    backgroundColor: '#efefef',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    padding: '7px',
                    color: '#222222',
                  }}
                >
                  Pavadinimas
                </td>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    backgroundColor: '#efefef',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    padding: '7px',
                    color: '#222222',
                  }}
                >
                  Prekės kodas
                </td>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    backgroundColor: '#efefef',
                    fontWeight: 'bold',
                    textAlign: 'right',
                    padding: '7px',
                    color: '#222222',
                  }}
                >
                  Kiekis
                </td>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    backgroundColor: '#efefef',
                    fontWeight: 'bold',
                    textAlign: 'right',
                    padding: '7px',
                    color: '#222222',
                  }}
                >
                  Kaina
                </td>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    backgroundColor: '#efefef',
                    fontWeight: 'bold',
                    textAlign: 'right',
                    padding: '7px',
                    color: '#222222',
                  }}
                >
                  Iš viso
                </td>
              </tr>
            </thead>
            <tbody>
              {order.products.map((product, index) => (
                <tr>
                  <td
                    style={{
                      fontSize: '12px',
                      borderRight: '1px solid #dddddd',
                      borderBottom: '1px solid #dddddd',
                      textAlign: 'left',
                      padding: '7px',
                    }}
                  >
                    {product.title}
                  </td>
                  <td
                    style={{
                      fontSize: '12px',
                      borderRight: '1px solid #dddddd',
                      borderBottom: '1px solid #dddddd',
                      textAlign: 'left',
                      padding: '7px',
                    }}
                  >
                    {product.sku}
                  </td>
                  <td
                    style={{
                      fontSize: '12px',
                      borderRight: '1px solid #dddddd',
                      borderBottom: '1px solid #dddddd',
                      textAlign: 'right',
                      padding: '7px',
                    }}
                  >
                    {product.count}
                  </td>
                  <td
                    style={{
                      fontSize: '12px',
                      borderRight: '1px solid #dddddd',
                      borderBottom: '1px solid #dddddd',
                      textAlign: 'right',
                      padding: '7px',
                    }}
                  >
                    {toCurrency(product.price)}
                  </td>
                  <td
                    style={{
                      fontSize: '12px',
                      borderRight: '1px solid #dddddd',
                      borderBottom: '1px solid #dddddd',
                      textAlign: 'right',
                      padding: '7px',
                    }}
                  >
                    {toCurrency(product.price * product.count)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    textAlign: 'right',
                    padding: '7px',
                  }}
                  colSpan={4}
                >
                  <b>Tarpinė suma:</b>
                </td>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    textAlign: 'right',
                    padding: '7px',
                  }}
                >
                  {toCurrency(getTotalBeforeVat(order, company.vat))}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    textAlign: 'right',
                    padding: '7px',
                  }}
                  colSpan={4}
                >
                  <b>PVM ({company.vat || '0'}%):</b>
                </td>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    textAlign: 'right',
                    padding: '7px',
                  }}
                >
                  {toCurrency(getOrderTotal(order) - getTotalBeforeVat(order, company.vat))}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    textAlign: 'right',
                    padding: '7px',
                  }}
                  colSpan={4}
                >
                  <b>Iš viso:</b>
                </td>
                <td
                  style={{
                    fontSize: '12px',
                    borderRight: '1px solid #dddddd',
                    borderBottom: '1px solid #dddddd',
                    textAlign: 'right',
                    padding: '7px',
                  }}
                >
                  <b>{toCurrency(getOrderTotal(order))}</b>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  )
}
