import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Grid, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { TopHeader } from '../../users/components/top-header'
import { analytics } from '../../index'
import { registerPageView } from '../../_core/analytics/analytics'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  content: {
    margin: '32px auto',
    width: '100%',
    maxWidth: 1100,
  },
  headerMid: {
    marginBottom: theme.spacing(2),
    margin: '0 auto',
    fontWeight: 700,
    fontSize: '17px',
    textTransform: 'uppercase',
    letterSpacing: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      marginTop: theme.spacing(0),
    },
  },
  questionHeading: {
    fontWeight: 500,
  },
  questions: {},
}))
const SimplePanel = props => {
  const { title, answer } = props
  const classes = useStyles()
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.questionHeading}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{answer}</ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
export const FaqCustomer: React.FC<any> = props => {
  const classes = useStyles()
  const history = useHistory()
  const handleBack = () => {
    history.goBack()
  }
  useEffect(() => {
    registerPageView('customer_faq')
  }, [])
  return (
    <>
      <TopHeader />
      <div className={classes.root}>
        <Container maxWidth="lg">
          <div>
            <IconButton onClick={handleBack}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <Grid container>
            <div className={classes.content}>
              <Typography variant="h2" className={classes.headerMid}>
                Lankytojams: Dažnai užduodami klausimai
              </Typography>

              <SimplePanel
                title={'Kaip veikia sistema?'}
                answer={
                  <Typography>
                    ☞ Išsirinkite vietą, kurią norėtumėte palaikyti. Tikimės, kad verslai pasidalins nuorodomis savo
                    socialiniuose kanaluose. Taip pat verslus, siūlančius Talonų matysite pagrindiniame puslapyje.
                    <br />
                    ☞ Pasirinkite Talonus, kuriuos norėtumėte įsigyti.
                    <br /> <br /> ☞ Suvedus visus reikalingus duomenis, gausite elektroninį laišką su informacija,
                    reikalinga bankiniam terminalui atlikti.
                    <br /> <br />
                    ☞ Jei laiško negavote būtinai pasitikrinkite nepageidaujamų laiškų skiltį <br /> <br />
                    ☞ Talonų pardavėjas, gavęs jūsų užsakymą bei patvirtinęs, kad mokėjimas gautas, sugeneruoja jūsų
                    Talonus, kuriuos galėsite išleisti mokėdami už pardavėjo prekes ir paslaugas. <br />
                    <br />
                    ☞ Gaunate elektroninį laišką, kuriame yra failas su visais jūsų įsigytais Talonais. <br /> <br />☞
                    Jūsų pateikti užsakymai ir Talonai taip pat matomi prisijungus prie paskyros (niekada nepamesit),
                    paspaudus <Link to={'/my-coupons'}>MANO TALONAI</Link> <br />
                    <br />☞ Siekdami sistemą padaryti prieinamą visiems kuo greičiau ir nemokamai, nusprendėme
                    atsisakyti integruotos apmokėjimo sistemos. Talonų galima įsigyti bankiniu pavedimu, pavedimą
                    atliekant tiesiai į įmonės sąskaitą.
                  </Typography>
                }
              />
              <SimplePanel
                title={'Kiek laiko galios mano įsigytas Talonas?'}
                answer={
                  <Typography>
                    Pirkdami Taloną matysite jo galiojimo laiką. Tai reiškia, kad tiek laiko nuo įsigijimo pradžios
                    Talonas ir galios. Skatinsime verslus pasirinkti ilgesnius galiojimo periodus. Be to, jei jūsų
                    Talono galiojimo laikas būtų pasibaigęs, sitema vis tiek leistų jį panaudoti, tai paliekame
                    nuspręsti verslui.
                  </Typography>
                }
              />
              <SimplePanel
                title={'Ar būtina turėti išspausdintą Taloną?'}
                answer={<Typography>Ne, Talonai turi QR kodą, kurį galite rodyti telefone.</Typography>}
              />
              <SimplePanel
                title={'Ištryniau el. laišką su Talonais, ką daryti?'}
                answer={
                  <Typography>
                    Nieko baisaus nenutiko, Talonai yra saugomi duomenų bazėje, ir visus savo užsakymus bei aktyvius ir
                    panaudotus Talonus galite matyti prisijungę prie sistemos sekdami šia nuoroda{' '}
                    <Link to={'/my-coupons'}>MANO TALONAI</Link> <br />
                    Jums nereikia turėti jokio slaptažodžio, reikia tik prieigos prie savo elektroninio pašto. Įvedę el.
                    pašto adresą, gausite specialią nuorodą, su kuria galėsite patekti į sistemą
                  </Typography>
                }
              />
              <SimplePanel
                title={'Kodėl toks nepatogus atsiskaitymas, juk Vilnius - fintech hub-as?'}
                answer={
                  <Typography>
                    Kadangi šis projektas nėra komercinis ir jo siekis kuo greičiau leisti verslui gauti pajamų, mes
                    atsisakėme integruotos mokėjimo sistemos, taip išvengdami daugybės reikalingų dokumentų pasirašant
                    sutartis, surenkant mokėjmus bei atsiskaitant su įmonėmis. Tai taip pat leidžia išvengti bet kokių
                    komisinių mokesčių, kurie šiuo metu būtų tik papildomi kaštai. Tikimės, kad žmonės bus supratingi ir
                    prisimins kaip padaryti seną gerą pavedimą.
                  </Typography>
                }
              />
            </div>
          </Grid>
        </Container>
      </div>
    </>
  )
}
