import { COUNT_ORDER_UPDATE, COUNT_ORDER_RESET } from './count-order-actions'
import { CountOrder } from 'app/app-reducer'

const initialState: CountOrder = {
  companyId: undefined,
  orderData: {},
}

export const countOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNT_ORDER_RESET:
      return initialState
    case COUNT_ORDER_UPDATE:
      if (action.order.count === 0) {
        const orders = { ...state.orderData }
        delete orders[action.order.id]
        return {
          ...state,
          companyId: action.companyId,
          orderData: orders,
        }
      }
      return {
        ...state,
        companyId: action.companyId,
        orderData: {
          ...state.orderData,
          [action.order.id]: action.order,
        },
      }
    default:
      return state
  }
}
