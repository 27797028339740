import { Avatar, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { useSelector } from '../../store'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
    '& img': {
      objectFit: 'scale-down',
    },
  },
  name: {
    marginTop: theme.spacing(1),
  },
}))

export const Profile = props => {
  const { className, company, ...rest } = props
  const profile = useSelector(state => state.firebase.profile)
  const classes = useStyles()
  const user = {
    name: profile.name,
    avatar: company ? company.logoUrl : '/images/avatars/avatar_11.png',
    bio: company?.name,
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar alt="Person" className={classes.avatar} component={RouterLink} src={user.avatar} to="/settings" />
      <Typography className={classes.name} variant="h4">
        {user.name}
      </Typography>
      <Typography variant="body2">{user.bio}</Typography>
    </div>
  )
}
