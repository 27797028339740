import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Container from '@material-ui/core/Container'
import { registerPageView } from '../../_core/analytics/analytics'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    marginTop: theme.spacing(4),
    maxWidth: 900,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  subHeader: {
    marginBottom: theme.spacing(0.5),
  },
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  navigationButton: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
}))

export const TermsForBusiness: React.FC<any> = props => {
  const classes = useStyles()
  const history = useHistory()
  const handleBack = () => {
    history.goBack()
  }
  useEffect(() => {
    registerPageView('terms_from_signup')
  }, [])
  return (
    <div className={classes.root}>
      <div className={classes.navigationButton}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.content}>
              <Typography variant="h4" className={classes.header}>
                Sąlygos
              </Typography>
              <Typography component="h2" className={classes.subHeader} variant="overline">
                Bendros sąlygos
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Sistema siekia veikti pagal visus galiojančius įstatymus, todėl verslas, atlikdamas registracija
                supranta, kad visi vartotojų duomenys, gauti atliekant užsakymus, yra reikalingi apskaitai atlikti ir
                užtikrinti paslaugų kokybei. Verslas supranta, kad negali naudoti šių vartotojų duomenų tiesioginiams
                marketinginiams tikslams, jei vartotojai nėra davę tam sutikimo.
                <br />
                <br />
                Talonai.lt nėra komercinis projektas ir neprisiima interneto svetainėje publikuojamų kuponuose nurodytų
                paslaugų teikėjų pareigų ir atsakomybės
                <br />
                <br />
                Esu informuotas, kad Alkoholio kontrolės įstatymo 28 straipsnio 1 dalies 2 punktas, Tabako, tabako
                gaminių ir su jais susijusių gaminių kontrolės įstatymo 18 straipsnio 1 dalies 1 punktas draudžia
                visuomenės informavimo priemonėse išspausdintų ar kitokiais būdais išplatintų kuponų savininkams taikyti
                fiksuotas nuolaidas alkoholiniams gėrimams ir tabako gaminiams arba laikyti šiuos kuponus kitokiomis
                dalinio arba viso mokėjimo priemonėmis ir įsipareigoju projekto „Talonai.lt“ kuponais nepriimti
                atsiskaitymų už alkoholinius gėrimus ir tabako gaminius.
              </Typography>
              <Typography component="h2" className={classes.subHeader} variant="overline">
                Privatumo ir duomenų saugojimo politika
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Privatumo politika reglamentuoja sistemos talonai.lt ir jos naudotojo pagrindinius asmens duomenų
                rinkimo, tvarkymo ir saugojimo principus bei tvarką. Ši informacija laikas nuo laiko gali būti
                koreguojama. Retkarčiais jūs turėtumėte patikrinti šį puslapį, kad mes būtume tikri, jog jus tenkina
                pateikta informacija.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Mes galime rinkti šiuos duomenis:
                <br />
                1. Vardas, pavardė.
                <br />
                2. Kontaktinė informacija, įskaitant el.pašto adresą.
                <br />
                3. IP adresas.
                <br />
                Mums reikalinga ši informacija, jog suprastume jūsų poreikius, pasiūlytume geresnį aptarnavimą bei
                atliktume šiuos veiksmus:
                <br />
                1. Vidinė apskaita.
                <br />
                2. Mes galime naudoti šią informaciją, jog pagerintume savo produktų bei paslaugų kokybę.
                <br />
                3. Mes galime periodiškai siųsti reklaminius el. laiškus su naujų produktų informacija, specialiais
                pasiūlymais bei kita informacija, kuri mūsų manymu gali būti naudinga ir įdomu adresatams.
                <br />
              </Typography>
              <Typography component="h2" className={classes.subHeader} variant="overline">
                Nuorodos į kitus puslapius
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Puslapis gali turėti nuorodų į kitus internetinius puslapius. Kai jūs pasinaudojate nuorodomis
                vedančiomis į kitus internetinius puslapius mes neturime jokios internetinio srauto kontrolės. Mes
                nesame atsakingi už jūsų pateiktos informacijos saugumo bei privatumo politiką. Mūsų parduotuvės
                pirkėjai prisijungimo prie elektroninės bankininkystės ar elektroninio mokėjimo sistemų duomenis įveda
                bankų puslapiuose, todėl mums ši informacija taip pat neprieinama.
              </Typography>
              <Typography component="h2" className={classes.subHeader} variant="overline">
                Asmeninės informacijos kontrolė
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Jūs galite uždrausti rinkti jūsų asmeninę šiais būdais :<br />
                Jeigu esate sutikę, jog galime naudoti jūsų asmeninę informaciją tiesioginiais marketingo tikslais, jūs
                galite apsigalvoti bet kuriuo metu pranešdami mums el. paštu: pagalba@talonai.lt. Laiško antraštę
                pavadinkite "atsisakau prenumeratos/naujienaiškio".
                <br />
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
