import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  makeStyles,
} from '@material-ui/core'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined'
import { Order } from '../../types/order-type'
import { Label } from '../../_core/components/label'
import { getOrderStatus, paymentStatusColors } from '../../_core/utils/orders-utils'
import { useHistory } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}))
const OrderInfo: React.FC<any> = ({ order, className, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Užsakymo informacija" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Kliento informacija</TableCell>
            <TableCell>
              <div>{order.email}</div>
              <div>{`${order.firstName} ${order.lastName}`}</div>
              <div>{order.phone}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Numeris</TableCell>
            <TableCell>#{order.number}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Būsena</TableCell>
            <TableCell>
              <Label color={paymentStatusColors[order.status]}>{getOrderStatus(order.status)}</Label>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>{moment(order.createdAt).format('YYYY-MM-DD HH:MM')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Suma</TableCell>
            <TableCell>€{order.price}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardActions>
        <Button onClick={() => history.push(`/orders/invoice/${order.id}`)}>
          <ReceiptIcon className={classes.actionIcon} />
          Peržiūrėti sąskaitą
        </Button>
      </CardActions>
    </Card>
  )
}

OrderInfo.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired,
}

export default OrderInfo
