export enum ImageType {
  THUMB = 'THUMB',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

const EXTENSIONS: { [key in ImageType]: string } = {
  THUMB: '_200x200',
  SMALL: '_512x512',
  MEDIUM: '_1200x1200',
  LARGE: '_2000x2000',
}

export const getImageUrl = (originalUrl: string, imageType: ImageType): string => {
  const result = originalUrl.replace(/%2F([a-zA-Z0-9]*?)\./i, `%2Fresized%2F$1${EXTENSIONS[imageType]}.`)
  return result
}
