import { Coupon } from '../types/coupon-type'
import { Company } from '../types/company-type'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from '../store'
import { PdfCoupon } from '../pdf/pdf-coupon'
import { FullScreenLoader } from '../common/full-screen-loader'

type Props = {}

export const SingleCoupon: React.FC<Props> = () => {
  const params = useParams<any>()
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const company: Company | undefined = useSelector(
    ({ firestore: { data } }) => data.companies && data.companies[profile.companyId],
  )
  useFirestoreConnect([
    {
      collection: `companies/${profile.companyId}/coupons/`,
      doc: `${params.couponId}`,
      storeAs: 'singleCoupon',
    },
  ])
  const coupon: Coupon = useSelector(state =>
    state.firestore.ordered.singleCoupon ? state.firestore.ordered.singleCoupon[0] : null,
  )
  if (!coupon || !company) {
    return <FullScreenLoader />
  }
  return <PdfCoupon coupon={coupon} company={company} />
}
