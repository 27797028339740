import { AnyAction } from 'redux'
import {
  ACTION_ADD_SHARE_EMAILS,
  ACTION_INCREMENT_SHARE_STEP,
  ACTION_REMOVE_EMAIL,
  ACTION_RESET_SHARE,
  ACTION_SELECT_COUPONS_GROUP,
} from './share-actions'
import { Coupon } from '../../types/coupon-type'

export type ShareState = {
  step: number
  coupons: Coupon[]
  emails: string[]
}

const initialState: ShareState = {
  step: 0,
  coupons: [],
  emails: [],
}

export const shareReducer = (state = initialState, action: AnyAction): ShareState => {
  switch (action.type) {
    case ACTION_INCREMENT_SHARE_STEP: {
      return { ...state, step: state.step + action.step }
    }
    case ACTION_RESET_SHARE: {
      return initialState
    }
    case ACTION_REMOVE_EMAIL: {
      return { ...state, emails: state.emails.filter((email, index) => index !== action.index) }
    }
    case ACTION_SELECT_COUPONS_GROUP: {
      return { ...state, coupons: action.group }
    }
    case ACTION_ADD_SHARE_EMAILS: {
      return { ...state, emails: action.emails }
    }
    default:
      return state
  }
}
