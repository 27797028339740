import React from 'react'
import { Card, CardContent, Container, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from '../store'
import { useSnackbar } from 'notistack'
import TextField from '@material-ui/core/TextField'
import CardActions from '@material-ui/core/CardActions'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(3),
  },

  actions: {
    marginTop: theme.spacing(3),
  },
}))
export const Feedback: React.FC<any> = () => {
  const classes = useStyles()
  const [feedback, setFeedback] = React.useState('')
  const history = useHistory()
  const profile = useSelector(state => state.firebase.profile)
  const firestore = useFirestore()
  const { enqueueSnackbar } = useSnackbar()
  const showSuccess = () => {
    enqueueSnackbar('Atsiliepimas išsiųstas sėkmingai', {
      variant: 'success',
    })
  }

  const sendFeedback = () => {
    if (feedback.length > 0) {
      firestore
        .collection(`feedback`)
        .add({ feedback: feedback, companyId: profile.companyId, email: profile.email })
        .then(showSuccess)
        .then(() => history.goBack())
    }
  }
  const handleOnChange = event => {
    setFeedback(event.target.value)
  }
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div>
          <Typography component="h2" gutterBottom variant="overline">
            Parašykite atsiliepimą ar pasiūlymą
          </Typography>
          <Card>
            <CardContent>
              <TextField
                value={feedback}
                onChange={handleOnChange}
                label="Atsiliepimas"
                fullWidth={true}
                multiline
                rows="8"
                variant="outlined"
              />
            </CardContent>
            <CardActions>
              <Button onClick={() => sendFeedback()} variant="contained" color="primary">
                Siųsti
              </Button>
            </CardActions>
          </Card>
        </div>
      </Container>
    </div>
  )
}
