import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useSelector } from '../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Coupon } from '../types/coupon-type'
import { CouponsTable } from './components/coupons-table'
import { CouponsToolbar } from './components/coupons-toolbar'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { getOrderStatus, toCurrency } from '../_core/utils/orders-utils'
import { getExpiredCouponSum, getUnUsedCouponsSum, getUsedCouponsSum } from '../_core/utils/coupon-utils'
import { InfoDialog } from '../_core/components/info-dialog'
import Label from '../_core/components/label'
import { OrderStatus } from '../types/order/order-status.enum'
import { CouponFilters } from './components/coupon-filters'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  statsItem: {
    padding: 10,
  },
  stats: {
    marginBottom: theme.spacing(3),
  },
  topTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  noMargin: {
    margin: 0,
  },
}))
const CouponsTutorial: React.FC<any> = props => {
  return (
    <InfoDialog title={'Kas tie Talonai? ¯\\_(ツ)_/¯'}>
      <Typography variant="body1">
        Talonai yra jau sistemos sugeneruoti "davonų kuponai" su kuriais galima atsiskaityti jūsų įmonėje
        <br />
        <br />
        Jeigu ieškote kaip pradėti pardavinėti savo Talonus, tai jus dominą <b>Nominalai</b>. <br />
        Pvz 20 EUR nominalas - tokių nominalų klientas gali nusipirkti betkokį kiekį, ir jei jis nusipirks 5 po 20 EUR
        ir jūs patvirtinsite užsakymą, čia bus sugeneruoti 5 skirtingi Talonai
        <br />
        <br />
        Talonai yra automatiškai sugeneruojami, kuomet paspaudžiate patvirtinti užsakymą. Užsakymą tvirtinkite tik tada,
        kai esate įsitikinę, kad mokėjimas atliktas sėkmingai.
        <br />
        <br />
        "Pridėti Taloną" naudokite tik tada kai norite sugeneruoti Taloną ir išsiųsti jį paštu ne pagal užsakymą, o
        tiesiog dovanojant ar gavus pinigus kitais būdais.
      </Typography>
    </InfoDialog>
  )
}
const CouponsStats: React.FC<any> = props => {
  const { coupons } = props
  const classes = useStyles()
  return (
    <div className={classes.stats}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.statsItem}>
            <div>
              <Typography variant="overline">Panaudoti / Iš viso</Typography>
            </div>
            <div>
              <Typography variant="h3">{`${coupons.filter(c => c.claimed).length} / ${coupons.length}`}</Typography>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Paper className={classes.statsItem}>
            <div>
              <Typography variant="overline">Panaudotų Talonų suma</Typography>
            </div>
            <div>
              <Typography variant="h3">{toCurrency(getUsedCouponsSum(coupons))}</Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.statsItem}>
            <div>
              <Typography variant="overline">Nepanaudotų talonų suma</Typography>
            </div>
            <div>
              <Typography variant="h3">{toCurrency(getUnUsedCouponsSum(coupons))}</Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.statsItem}>
            <div>
              <Typography variant="overline">Pasibaigusių suma:</Typography>
            </div>
            <div>
              <Typography variant="h3">{toCurrency(getExpiredCouponSum(coupons))}</Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
export const Coupons: React.FC<any> = props => {
  const profile = useSelector(state => state.firebase.profile)
  const classes = useStyles()
  const collection = `companies/${profile.companyId}/coupons`
  useFirestoreConnect([{ collection: collection, orderBy: ['created', 'desc'] }])
  const coupons: Coupon[] = useSelector(state => state.firestore.ordered[collection])

  return (
    <div className={classes.root}>
      <CouponsToolbar />
      <div>
        <Typography component="h2" gutterBottom variant="overline"></Typography>
        <div className={classes.topTitle}>
          <Typography className={classes.noMargin} component="h2" gutterBottom variant="overline">
            Talonai
          </Typography>
          <CouponsTutorial />
        </div>
      </div>
      <div className={classes.content}>
        {coupons && coupons.length > 0 ? (
          <>
            <CouponsStats coupons={coupons} />
            <CouponFilters />
            <CouponsTable coupons={coupons} />
          </>
        ) : (
          <Typography variant="body1">Šiuo metu Talonų nėra</Typography>
        )}
      </div>
    </div>
  )
}
