import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import moment from 'moment'
import React from 'react'
import { useSelector } from '../../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Order } from 'types/order-type'
import { ProductDetailsCard } from './product-details-card'
import { getOrderStatus, paymentStatusColors } from '../../_core/utils/orders-utils'
import Label from '../../_core/components/label'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined'
import { useHistory } from 'react-router-dom'
import 'moment/locale/lt'
import { formatDate } from '../../_core/utils/date-utils'
const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2),
  },
  order: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2, 0),
  },
  orderDetails: {
    fontFamily: theme.typography.fontFamily,
  },

  orderStatus: {
    fontSize: '14px',
    fontWeight: 500,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
}))

export const MyOrders: React.FC<any> = () => {
  const auth = useSelector(state => state.firebase.auth)
  useFirestoreConnect([
    {
      where: ['email', '==', auth.email],
      collectionGroup: 'orders',
      storeAs: 'orders',
      orderBy: ['created', 'desc'],
    },
  ])
  moment.locale('lt')
  const orders: Order[] = useSelector(state => state.firestore.ordered.orders)
  const history = useHistory()
  const classes = useStyles()

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.paddingBottom}>
        <Typography variant="h3">Mano užsakymai</Typography>
      </Grid>

      {orders &&
        orders.map((order, index) => (
          <Grid key={index} item xs={12} className={classes.order}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center" className={classes.orderDetails}>
                  <Grid item style={{ fontSize: 0 }}>
                    <AccessTimeIcon />
                  </Grid>
                  <Grid item>{moment(order.created.toDate()).fromNow()}</Grid>
                  <Grid item>{formatDate(order.created)}</Grid>
                  <Grid item>
                    <Label color={paymentStatusColors[order.status]}>{getOrderStatus(order.status)}</Label>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant={'outlined'}
                      onClick={() => history.push(`/my-orders/invoice/${order.companyId}/${order.id}`)}
                    >
                      <ReceiptIcon />
                      Peržiūrėti sąskaitą
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {order.products.map((product, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <ProductDetailsCard product={product} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
    </Grid>
  )
}
