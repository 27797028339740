import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { Typography } from '@material-ui/core'
import { Company } from '../../types/company-type'
import { getImageUrl, ImageType } from '../../images/image-utils'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: 200,
    height: 200,
    marginTop: '-50px',
    overflow: 'hidden',
    border: '5px solid #eeeeee',
    borderRadius: '50%',
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
      marginLeft: '8px',
      border: '2px solid #ffffff',
    },
  },
  mini: {
    width: '140px',
    height: '140px',
    marginLeft: '8px',
    border: '2px solid #ffffff',
  },
  img: {
    objectFit: 'fill',
  },
  letter: {
    fontSize: 48,
    color: theme.palette.primary.dark,
  },
}))
type CompanyLogo = {
  company: Company
  mini?: boolean
}
export const CompanyLogo: React.FC<CompanyLogo> = props => {
  const { company, mini } = props
  const classes = useStyles()
  return (
    <div className={clsx(classes.wrapper, mini ? classes.mini : '')}>
      {company.logoUrl ? (
        <img className={classes.img} alt={company.name} src={getImageUrl(company.logoUrl, ImageType.SMALL)} />
      ) : (
        <Typography className={classes.letter}>{company.name.charAt(0)}</Typography>
      )}
    </div>
  )
}
