import { Divider, Drawer } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CardGiftcard from '@material-ui/icons/CardGiftcard'
import InputIcon from '@material-ui/icons/Input'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import clsx from 'clsx'
import React from 'react'
import { SidebarNav } from './sidebar-nav'
import ShareIcon from '@material-ui/icons/Share'
const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

export const CustomerSidebar = props => {
  const { open, variant, onClose, className, ...rest } = props

  const classes = useStyles()

  const pages = [
    {
      title: 'Mano talonai',
      roles: ['admin', 'user', 'customer', ''],
      href: '/my-coupons',
      icon: <CardGiftcard />,
    },
    {
      title: 'Mano užsakymai',
      href: '/my-orders',
      roles: ['admin', 'user', 'customer', ''],
      icon: <ShoppingBasketIcon />,
    },
    {
      bottom: true,
      title: 'Atsijungti',
      href: '/sign-out',
      roles: ['admin', 'user', 'customer', ''],
      icon: <InputIcon />,
    },
  ]

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        {/*<Profile />*/}
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}
