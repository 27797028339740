import * as React from 'react'
import { QRCode } from 'react-qr-svg'
import { Company } from '../types/company-type'
import { Coupon } from '../types/coupon-type'
import { getCouponHumanId, getExpirationText } from '../_core/utils/coupon-utils'
import { getCompanyAddress } from '../_core/utils/company-utils'
import { getImageUrl, ImageType } from '../images/image-utils'

type Props = {
  coupon: Coupon
  company: Company
}

export const PdfCoupon: React.FC<Props> = ({ coupon, company }) => {
  return (
    <div
      style={{
        margin: '24px',
        fontFamily: 'Roboto, sans-serif',
        border: '1px solid #37474f',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
          }}
        >
          <img
            style={{
              height: '50px',
              backgroundColor: '#ffffff',
              marginRight: '16px',
              padding: '3px',
            }}
            alt={company.name}
            src={getImageUrl(company.logoUrl, ImageType.SMALL)}
          />{' '}
          <div>{company.name}</div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '32px',
            height: '50px',
            width: '232px',
            textAlign: 'center',
            color: 'white',
            backgroundColor: '#4caf50',
          }}
        >
          <b>{coupon.product.value}€</b>
        </div>
      </div>
      <div style={{ display: 'flex', padding: '16px', borderTop: '1px solid #37474f' }}>
        <div style={{ flex: 1, paddingRight: '32px' }}>
          <div>
            <b>#{getCouponHumanId(coupon)}</b>
          </div>
          <div style={{ color: '#37474f' }}>
            <b>{getExpirationText(coupon)}</b>
          </div>
          <h3>{coupon.product.title}</h3>
          <p>{coupon.product.shortDescription}</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <QRCode value={`https://talonai.lt/confirm-claim/${coupon.id}`} style={{ width: 200 }} />
        </div>
      </div>
      <div style={{ padding: '16px' }}>
        <div>Kontaktai</div>
        <div style={{ width: '400px', height: '1px', marginBottom: '10px', background: '#888888' }} />
        <div>
          <b>Įmonė:</b> {company.businessName}
          <br />
          <b>Telefono numeris:</b> {company.phone}
          <br />
          <b>Elekroninis paštas:</b> {company.email}
          <br />
          <b>Adresas:</b> {getCompanyAddress(company)}
        </div>
      </div>
      <div style={{ padding: '16px' }}>
        <div>Bendra informacija</div>
        <div style={{ width: '400px', height: '1px', marginBottom: '10px', background: '#37474f' }} />
        <p>
          Šio talono atsispausdinti nereikia, galite darbuotojams rodyti telefone arba matyti visus savo kuponus
          prisijungę talonai.lt puslapyje{' '}
        </p>
      </div>
    </div>
  )
}
