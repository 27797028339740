import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(3),
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subHeader: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  error: {
    color: theme.palette.error.main,
    textAlign: 'center',
  },
}))
export const FullScreenLoader: React.FC<any> = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>
          <Typography className={classes.subHeader} variant="h2">
            <CircularProgress color={'primary'} />
          </Typography>
        </div>
      </div>
    </div>
  )
}
