import React, { useEffect } from 'react'
import { Grid, IconButton, Typography, Divider } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useSelector } from '../../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import { countTotal } from '../count-order/count-total'
import { PlaceOrder } from './place-order'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { toCurrency } from '../../_core/utils/orders-utils'
import { registerPageView } from '../../_core/analytics/analytics'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: 'fit-content',
    margin: theme.spacing(0, 3),
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  grid: {
    height: '100%',
  },
  gridItem: {
    marginTop: theme.spacing(6),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: 400,
    marginRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
  },
  headline: {
    marginTop: theme.spacing(1),
  },
  name: {
    marginTop: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  list: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  listItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export const Order: React.FC<any> = props => {
  const classes = useStyles()
  let { alias, productId } = useParams<any>()
  const history = useHistory()
  useEffect(() => {
    registerPageView('order_page')
  }, [])
  useFirestoreConnect([
    {
      where: [
        ['alias', '==', alias],
        ['verified', '==', true],
        ['isPublic', '==', true],
      ],
      collection: 'companies',
    },
  ])
  const handleBack = () => {
    history.goBack()
  }
  const company = useSelector(
    ({
      firestore: {
        ordered: { companies },
      },
    }) => companies && companies[0],
  )
  const orderObject = useSelector(state => state.countOrder.orderData)
  const order = Object.keys(orderObject).map(key => orderObject[key])

  return (
    <div className={classes.root}>
      <div>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      {company && (
        <Grid className={classes.grid} container>
          <Grid className={classes.gridItem} item lg={4}>
            <div className={classes.content}>
              <Typography className={classes.headline} variant="h4">
                Talonų užsakymas
              </Typography>
              <Typography className={classes.name} variant="h2">
                {company.name}
              </Typography>
              <Typography className={classes.description} variant="body1">
                Jūsų užsakymo detalės ir informacija kaip juos aktyvuoti atkeliaus į elektroninį paštą. Talonai tampa
                aktyvūs po užsakymo apmokėjimo patvirtinimo.
              </Typography>
              <Typography className={classes.headline} variant="h4">
                Užsakymo santrauka:
              </Typography>
              <div className={classes.list}>
                {order.length &&
                  order.map(item => (
                    <div key={item.id}>
                      <Typography className={classes.listItem} variant="body1">
                        <div>
                          {item.title} x {item.count}
                        </div>
                        €{item.price}
                      </Typography>
                      <Divider />
                    </div>
                  ))}
              </div>
              <Typography className={classes.headline} variant="h4">
                Suma: {toCurrency(countTotal(orderObject))}
              </Typography>
            </div>
          </Grid>
          <Grid className={classes.gridItem} item lg={8} xs={12}>
            <PlaceOrder companyId={company.id} company={company} productId={productId} />
          </Grid>
        </Grid>
      )}
    </div>
  )
}
