import React from 'react'
import { Company } from '../../types/company-type'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { getImageUrl, ImageType } from '../../images/image-utils'
import { Button, Grid, Typography } from '@material-ui/core'
import { CompanyLogo } from '../../_core/components/company-logo'
import { ProductList } from '../../views/company/product-list'
import { toCurrency } from '../../_core/utils/orders-utils'
import { countTotal } from '../../views/count-order/count-total'
import { Link, Link as RouterLink } from 'react-router-dom'
import { useSelector } from '../../store'
import { useFirestore } from 'react-redux-firebase'
import { MapProductList } from './map-product-list'

const useStyles = makeStyles(theme => ({
  cover: {
    backgroundImage: `url(../images/cover.jpg)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height: '200px',
  },
  order: {
    padding: '10px',
  },
  products: {
    margin: '10px 0',
  },
  bottomButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  companyName: {
    display: 'flex',
  },
}))
type Props = {
  company: Company
  onClose: (company: any) => any
}
export const SelectedCompany: React.FC<Props> = ({ company, onClose }) => {
  const classes = useStyles()
  const orderData = useSelector(state => state.countOrder.orderData)
  const firestore = useFirestore()
  firestore.get({
    collection: `companies/${company.id}/products`,
    where: ['isPublished', '==', true],
    storeAs: `products_${company.id}`,
  })
  return (
    <>
      <div
        style={
          company.coverPhotoUrl
            ? {
                backgroundImage: `url(${getImageUrl(company.coverPhotoUrl, ImageType.MEDIUM)})`,
              }
            : {}
        }
        className={classes.cover}
      />
      <div className={classes.companyName}>
        <CompanyLogo company={company} mini={true} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4" style={{ marginLeft: 20, marginRight: 20 }}>
            {company.name}
          </Typography>
          <Button component={Link} to={`/company/${company.alias}`}>
            Į įmonės puslapį
          </Button>
        </div>
      </div>
      <div className={classes.products}>
        <MapProductList companyId={company.id} />
      </div>
      <div className={classes.order}>
        <div className={classes.bottomButtons}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              component={RouterLink}
              to={`/company/${company.alias}/new-order`}
              variant="contained"
              color="primary"
              disabled={countTotal(orderData) === 0}
            >
              Užsakyti
            </Button>
            <Typography variant="h4" style={{ marginLeft: 20 }}>
              {toCurrency(countTotal(orderData))}
            </Typography>
          </div>
          <Button onClick={onClose}>Uždaryti</Button>
        </div>
      </div>
    </>
  )
}
