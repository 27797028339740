import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useSelector } from '../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Product } from '../types/product-type'
import { ProductsTable } from './components/products-table'
import { ProductsToolbar } from './components/products-toolbar'
import { Typography } from '@material-ui/core'
import { InfoDialog } from '../_core/components/info-dialog'
import { OrdersTable } from '../orders/components/orders-table'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  topTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  noMargin: {
    margin: 0,
  },
}))
const ProductsTutorial: React.FC<any> = props => {
  return (
    <InfoDialog title={'Kas tie nominalai? ¯\\_(ツ)_/¯'}>
      <Typography variant="body1">
        Nominalai tai skirtingi talonų tipai, kuriuos klientai gali įsigyti arba jūs galite išsiųsti el. paštu. Keli
        potencialūs naudojimo pavyzdžiai:
        <br />
        <br />
        1. Paprastas 10 Eur nominalas
        <br />
        2. 10 Eur vertės nominalas su kaina 8eur (20% nauolaida)
        <br />
        3. 20 Eur vertės nominalas, galiojantis 6mėn <br />
        4. 20 Eur vertės nominalas, neturintis galiojimo laiko <br />
        5. 10 Eur vertės nominalas pavadinimu "Bet kuri didelė pica už 10 Eur" <br />
        <br />
        Sukūrus nominalą, jei jis yra publikuotas, jūsų klientai jį gali įsigyti apsilankę jūsų įmonės puslapyje.
        <br />
        Publikavimą galit naudot limituoto laiko talonams (išjungus publikavimą po kurio laiko) ar specialaus leidimo
        talonams, kuriuos galėsite išsiųsti tik jūs el. paštu
      </Typography>
    </InfoDialog>
  )
}
export const Products: React.FC<any> = props => {
  const profile = useSelector(state => state.firebase.profile)
  const classes = useStyles()
  const collection = `companies/${profile.companyId}/products`
  useFirestoreConnect([{ collection: collection, orderBy: ['created', 'desc'] }])
  const products: Product[] = useSelector(state => state.firestore.ordered[collection])

  return (
    <div className={classes.root}>
      <ProductsToolbar />
      <div className={classes.topTitle}>
        <Typography className={classes.noMargin} component="h2" gutterBottom variant="overline">
          Nominalai
        </Typography>
        <ProductsTutorial />
      </div>
      <div className={classes.content}>
        {products && products.length > 0 ? (
          <ProductsTable products={products} />
        ) : (
          <Typography variant="body1">
            Kol kas nesate sukūrę nė vieno nominalo. <Link to={'products/add-product'}>Sukurti</Link>
          </Typography>
        )}
      </div>
    </div>
  )
}
