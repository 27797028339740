import React, { useEffect, useState } from 'react'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from '../../store'
import { MenuItem, Select } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useDispatch } from 'react-redux'
import { actionSetInitialCategories, actionUpdateCategoryFilters } from './filters/filters-actions'
import { tr } from 'translations/translate'
import { TEXT_SELECT_CATEGORIES } from 'translations/keys'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {},
}))
export const CategoryFilter = () => {
  const firestore = useFirestore()

  const dispatch = useDispatch()
  useEffect(() => {
    firestore.get({
      collection: 'filters',
      doc: 'categories',
      storeAs: 'categories',
    })
  }, [firestore])
  const filters = useSelector(
    ({
      firestore: {
        ordered: { categories },
      },
    }) => categories && categories[0].list,
  )
  useEffect(() => {
    if (filters) {
      dispatch(actionSetInitialCategories(filters))
    }
  }, [filters])
  const handleOnChange = event => {
    dispatch(actionUpdateCategoryFilters(event.target.name, event.target.checked))
  }
  const classes = useStyles()
  const categoryFilters = useSelector(state => state.filters.categories)
  if (!filters || !categoryFilters) return null
  return (
    <div>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">{tr(TEXT_SELECT_CATEGORIES)}</FormLabel>
        <FormGroup row>
          {filters.map(x => (
            <FormControlLabel
              key={x.key}
              control={
                <Checkbox
                  color={'primary'}
                  checked={categoryFilters && categoryFilters[x.key]}
                  onChange={handleOnChange}
                  name={x.key}
                />
              }
              label={x.value_lt}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  )
}
