import React from 'react'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { Redirect, Route } from 'react-router-dom'
import { AdminLayout } from '../layout/admin-layout'
import { useSelector } from '../store'
import { FullScreenLoader } from '../common/full-screen-loader'

export const AdminPanelRoute = ({ component: RouteComponent, ...rest }) => {
  const profile = useSelector(state => state.firebase.profile)
  return (
    <Route
      {...rest}
      render={routeProps =>
        isLoaded(profile) && !isEmpty(profile) ? (
          <AdminLayout>
            <RouteComponent {...routeProps} />{' '}
          </AdminLayout>
        ) : isLoaded(profile) && isEmpty(profile) ? (
          <Redirect to={`/login`} />
        ) : (
          <FullScreenLoader />
        )
      }
    />
  )
}

export const AdminClaimRoute = ({ component: RouteComponent, ...rest }) => {
  const profile = useSelector(state => state.firebase.profile)
  return (
    <Route
      {...rest}
      render={routeProps =>
        isLoaded(profile) && !isEmpty(profile) ? (
          <AdminLayout>
            <RouteComponent {...routeProps} />{' '}
          </AdminLayout>
        ) : isLoaded(profile) && isEmpty(profile) ? (
          <Redirect to={`/login${window.location.pathname}`} />
        ) : (
          <FullScreenLoader />
        )
      }
    />
  )
}
