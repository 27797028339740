import { combineEpics } from 'redux-observable'
import { EMPTY } from 'rxjs'
import { mergeMap, tap, withLatestFrom } from 'rxjs/operators'

const logEpic = (action$, state$) =>
  action$.pipe(
    withLatestFrom(state$),
    tap(([action, state]) => console.log({ state, action })),
    mergeMap(() => EMPTY),
  )

export const appEpics = combineEpics(logEpic)
