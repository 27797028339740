import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useFirestore } from 'react-redux-firebase'
import Label from '../../_core/components/label'
import { ConfirmDialog } from '../../_core/components/confirm-dialog'
import IconButton from '@material-ui/core/IconButton'
import { Delete, Remove } from '@material-ui/icons'
import { useSelector } from '../../store'
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}))

export const UsersTable = props => {
  const { className, users, ...rest } = props
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const classes = useStyles()

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handlePageChange = (event, page) => {
    setPage(page)
  }

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value)
  }

  const handleDelete = userId => {
    firestore.delete(`users/${userId}`)
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Vardas</TableCell>
                  <TableCell>Administratorius</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => (
                  <TableRow hover key={user.id}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.role === 'admin' && <Label color={'success'}>Taip</Label>}</TableCell>
                    <TableCell>
                      {user.email !== profile.email && (
                        <ConfirmDialog
                          onConfirm={() => handleDelete(user.id)}
                          title={'Svarbus veiksmas'}
                          content={
                            <Typography color="inherit" variant="body1">
                              Ar tikrai norite ištrinti vartotoją negrįžtamai?
                            </Typography>
                          }
                        >
                          <IconButton>
                            <Delete />
                          </IconButton>
                        </ConfirmDialog>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={users.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  )
}
