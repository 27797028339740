import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Grid, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { TopHeader } from '../../users/components/top-header'
import { analytics } from '../../index'
import { registerPageView } from '../../_core/analytics/analytics'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  content: {
    margin: '32px auto',
    maxWidth: 1100,
  },
  headerMid: {
    marginBottom: theme.spacing(2),
    margin: '0 auto',
    fontWeight: 700,
    fontSize: '17px',
    textTransform: 'uppercase',
    letterSpacing: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      marginTop: theme.spacing(0),
    },
  },
  questionHeading: {
    fontWeight: 500,
  },
  questions: {},
}))
const SimplePanel = props => {
  const { title, answer } = props
  const classes = useStyles()
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.questionHeading}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{answer}</ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
export const FaqBusiness: React.FC<any> = props => {
  const classes = useStyles()
  const history = useHistory()
  const handleBack = () => {
    history.goBack()
  }
  useEffect(() => {
    registerPageView('faq_business')
  }, [])
  return (
    <>
      <TopHeader />
      <div className={classes.root}>
        <Container maxWidth="lg">
          <div>
            <IconButton onClick={handleBack}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <Grid container>
            <div className={classes.content}>
              <Typography variant="h2" className={classes.headerMid}>
                Verslui: Dažnai užduodami klausimai
              </Typography>

              <SimplePanel
                title={'Kaip patekti į sistemą?'}
                answer={
                  <Typography>
                    Norėdami tapti talonai.lt nariu, turite užsiregistruoti. Nuoroda yra{' '}
                    <Link to={'/sign-up'}>čia</Link> <br />
                  </Typography>
                }
              />
              <SimplePanel
                title={'Ar čia gali būti tik Vilniaus miesto restoranai?'}
                answer={
                  <Typography>
                    Projektą globoja Vilniaus miesto savivaldybė, tačiau registruotis ir naudotis sistema gali visų
                    miestų verslininkai.
                  </Typography>
                }
              />

              <SimplePanel
                title={'Ar Talonai.lt skirta tik maitinimo įstaigoms?'}
                answer={
                  <Typography>
                    Ne, sistema gali naudotis bet kokias prekes ar paslaugas teikiančios įmonės. Tai gali būti Talonas
                    grožio procedūroms atlikti, masažo paslaugų Talonas, Talonas gimtadienio tortui įsigyti. Visi esame
                    susipažinę su dovanų kuponais, veikimo principas toks pat.
                  </Typography>
                }
              />
              <SimplePanel
                title={'Kaip mano klientai galės pasinaudoti Talonais?'}
                answer={
                  <Typography>
                    Sėkmingai įvykus užsakymui, jūsų klientas el. paštu gaus pdf dokumentą su automatiškai sugeneruotais
                    Talonais. Tas dokumentas, panašiai kaip lėktuvo ar koncerto bilietas, turi kodą, kurį nuskaitę
                    išmaniuoju telefonu jūs galite patvirtinti Talono validumą. <br />
                    Net jei klientas prarastų ar negautų laiško, visi Talonai yra saugiai padėti duomenų bazėje; prie
                    savo įmonės Talonų turi teisę prieiti tik tos įmonės registruoti darbuotojai ir pats klientas.
                    Klientui tereikia prieigos prie savo elektroninio pašto, su kuriuo darė užsakymą.
                  </Typography>
                }
              />
              <SimplePanel
                title={'Ar reikia kokios nors papildomos įrangos norint skenuoti Talonus?'}
                answer={
                  <Typography>
                    Ne. Jums užteks išmaniojo telefono ar planšetės su įdiegta QR kodo skenavimo programėle (jų daug ir
                    nemokamai) bei interneto.
                  </Typography>
                }
              />
              <SimplePanel
                title={'Ar galės Talonus administruoti mano įmonės darbuotojai?'}
                answer={
                  <Typography>
                    Taip, jūs, kaip įmonę užregistravęs asmuo savaime tampate jos administratoriumi. Administratorius
                    turi teisę pakviesti kitus naudotojus elektroniniu paštu. Pakviesti naudotojai užbaigia registraciją
                    sugalvodami slaptažodį ir tampa naudotojais, kurie gali skenuoti Talonus ir matyti, ar jie validūs,
                    bei juos panaudoti. Tokie sistemos naudotojai neturi kitų teisių, tokių kaip matyti užsakymus, juos
                    tvirtinti, kurti nominalus ar rankiniu būdu kam nors išsiųsti Talonus kam nors.
                  </Typography>
                }
              />
              <SimplePanel
                title={'Kaip klientai galės įsigyti Talonų?'}
                answer={
                  <Typography>
                    Jums užsiregistravus, susikūrus Talonų nominalus ir mums patvirtinus, kad jūsų įmonė yra jūsų, o ne
                    kieno nors kito, Jūs galite pasiekti savo įmonės profilio puslapį. Rekomenduojame šiuo puslapiu
                    pasidalinti savo socialiniais kanalais. Šiame puslapyje apsilankę klientai gali matyti jūsų
                    parduodamus Talonų tipus (arba Nominalus), pavyzdžiui, „Vakarienė dviem už 50 EUR“ ar „20 EUR vertės
                    Talonas“. Pasirinkęs, kiek ir kokių Talonų nori įsigyti klientas užpildo reikalingus duomenis ir
                    spaudžia „užsakyti“. Klientas į savo el. paštą gauną laišką su jūsų įmonės duomenimis kaip atlikti
                    banko pavedimą tiesiai į jūsų sąskaitą. Mokėjimo paskirtyje kliento prašoma nurodyti užsakymo
                    numerį, kad jums būtų lengva atskirti, kurie užsakymai yra apmokėti, o kurie ne. Jums patvirtinus
                    užsakymą sistemoje spaudžiant „patvirtinti“, automatiškai sugeneruojami visi užsakyti kuponai ir
                    pristatomi el. paštu.
                  </Typography>
                }
              />
              <SimplePanel
                title={'Kodėl toks sudėtingas atsiskaitymas?'}
                answer={
                  <Typography>
                    Kadangi šis projektas nėra komercinis ir siekiama kuo greičiau padėti verslui gauti pajamų, mes
                    atsisakėme integruotos mokėjimo sistemos, taip išvengdami daugybės reikalingų dokumentų pasirašant
                    sutartis, surenkant mokėjmus bei atsiskaitant su įmonėmis. Tai taip pat leidžia išvengti bet kokių
                    komisinių mokesčių, kurie šiuo metu būtų tik papildomi kaštai. Tikimės, kad žmonės bus supratingi ir
                    prisimins, kaip padaryti seną gerą pavedimą.
                  </Typography>
                }
              />
              <SimplePanel
                title={'Kodėl mano įmonė nėra matoma pirmame puslapyje?'}
                answer={
                  <Typography>
                    Patvirtiname įmones, kurios tvarkingai susiveda visus reikalingus duomenis bei susikuria nominalus.
                    Jeigu įmonė yra nepatvirtinta, sitemoje matysite specialų pranešimą, kurį paspaudus, būsite perkelti
                    į paaiškinimų ką daryti puslapį.
                  </Typography>
                }
              />
              {/*<SimplePanel*/}
              {/*  title={'Užsiregistravus rodo, kad įmonė nėra patvirtinta, ką daryti?'}*/}
              {/*  answer={*/}
              {/*    <Typography>*/}
              {/*      Kadangi šiuo atsiskaitoma tiesioginiu bankiniu pavedimu, nereikia standartinių patvirtinimo*/}
              {/*      procedūrų norint gauti pinigus.*/}
              {/*      <br />*/}
              {/*      Tai leistų piktavaliams pasisavinti jūsų įmonės vardą ir siūlyti Talonų nurodant neteisingą*/}
              {/*      sąskaitą. <br />*/}
              {/*      Parašykite mums*/}
              {/*      <a href={'https://m.me/talonai'} target="_top">*/}
              {/*        {' '}*/}
              {/*        mums*/}
              {/*      </a>{' '}*/}
              {/*      iš savo įmonės valdomo puslapio. <br /> Taip pat galite susisiekti el. paštu verslas@talonai.lt,*/}
              {/*      susisiekdami naudokite el. paštą, kuris naudoją jūsų valdoma domeną (pvz., @talonai.lt).*/}
              {/*      <br />*/}
              {/*      Stengsimės būti operatyvūs ir patvirtinti jūsų įmonę kuo greičiau*/}
              {/*    </Typography>*/}
              {/*  }*/}
              {/*/>*/}
            </div>
          </Grid>
        </Container>
      </div>
    </>
  )
}
