import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, IconButton, Link, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Company } from '../types/company-type'
import { useSelector } from '../store'
import { FullScreenLoader } from '../common/full-screen-loader'
import Button from '@material-ui/core/Button'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { useSnackbar } from 'notistack'
import { Config } from '../config'
const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 3),
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  content: {
    marginTop: theme.spacing(4),
    maxWidth: 900,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  header: {
    fontSize: 18,
    marginBottom: theme.spacing(2),
  },
  subHeader: {
    fontSize: 16,
    marginBottom: theme.spacing(0.5),
  },
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  verification: {
    display: 'inline-block',
    border: '1px solid',
    borderColor: theme.palette.text.primary,
    borderRadius: 5,
    padding: 8,
    whiteSpace: 'normal',
  },
}))
const InfoCopy = ({ company }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const copySuccess = () => {
    enqueueSnackbar('Nuoroda nukopijuota', {
      variant: 'success',
    })
  }
  if (!company) return null
  return (
    <>
      <div className={classes.verification}>
        <Typography>{`${Config.baseUrl}/company/${company.alias}`}</Typography>
      </div>
      <br />
      <br />
      <Button
        variant={'outlined'}
        onClick={() => navigator.clipboard.writeText(`https://talonai.lt/company/${company.alias}`).then(copySuccess)}
        startIcon={<FileCopyIcon />}
      >
        Kopijuoti nuorodą
      </Button>
    </>
  )
}
export const PromoteCompany: React.FC<any> = props => {
  const classes = useStyles()
  const history = useHistory()
  const profile = useSelector(state => state.firebase.profile)
  const company: Company | undefined = useSelector(
    ({ firestore: { data } }) => data.companies && data.companies[profile.companyId],
  )
  const handleBack = () => {
    history.goBack()
  }
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.content}>
            <Typography variant="h4" className={classes.header}>
              Padėkite savo klientams įsigyti jūsų siūlomų Talonų.
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
              <br />
              <br />
              Keletas legvų žingsnių, leisiančių pasiekti didesnį klientų ratą:
              <br />
              <br />
              Pasidalinkite nuorodo į savo įmonės talonų puslapį savo socialiniuose tinkluose - Facebook, Instagram,
              LinkedIn, Snapchat, Twitter ir pan.
              <br />
              <br />
              Susiekite su savo ištikimiausiais klientais išsiųsdami jiems naujienlaikraštį/elektroninį laišką/SMS su
              nuoroda į jūsų įmonės talonų puslapį.
              <br />
              <br />
              Paprašykite savo darbuotojų, draugų, artimųjų pasidalinti nuoroda į jūsų talonų pusplapį.
              <br /> <br />
              Pasidalinkite nuoroda į savo talonų puslapį savo įmonės internetinėje svetainėje.
              <br />
              <br />
              Jeigu reklamuojatės internete, panaudokite nuorodą į savo talonų puslapį internetinėje reklamoje.
              <br />
              <br />
              <br />
              <br />
            </Typography>
            <Typography variant="h4" className={classes.header}>
              Jūsų įmonės nuoroda
            </Typography>

            <InfoCopy company={company} />
            <br />
            <br />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
