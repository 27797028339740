import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useSelector } from '../../store'
//import { useFirestoreConnect } from 'react-redux-firebase'
import { ProductCard } from './product-card'
import { Product } from '../../types/product-type'
import { Company } from '../../types/company-type'
//import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    //padding: theme.spacing(3)
    margin: '24px 0',
  },
  content: {
    // marginTop: theme.spacing(2)
  },
  pagination: {
    // marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))
type Props = {
  companyId: string
}
export const ProductList: React.FC<Props> = props => {
  const { companyId } = props
  const classes = useStyles()
  const products: Product[] = useSelector(({ firestore: { ordered } }) => ordered[`products_${companyId}`]) ?? []
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container spacing={3}>
          {products
            .filter(product => product.isPublished && product.prefix !== '%')
            .map(product => (
              <Grid item key={product.id} lg={4} md={4} xs={12}>
                <ProductCard companyId={companyId} product={product} />
              </Grid>
            ))}
        </Grid>
      </div>
    </div>
  )
}
