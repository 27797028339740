// home-splash
export const TEXT_FOR_BUSINESS = 'TEXT_FOR_BUSINESS'
export const TEXT_MY_COUPONS = 'TEXT_MY_COUPONS'
export const TEXT_HOME_SPLASH_1 = 'TEXT_HOME_SPLASH_1'
export const TEXT_FEELING_IS_MUTUAL = 'TEXT_FEELING_IS_MUTUAL'
export const TEXT_FOLLOW_US = 'TEXT_FOLLOW_US'
//globoja-vilnius:
export const TEXT_GLOBOJA_VILNIUS_1 = 'TEXT_GLOBOJA_VILNIUS_1'
// home
export const TEXT_GET_COUPON_NOW = 'TEXT_GET_COUPON_NOW'
export const TEXT_ENJOY_LATER = 'TEXT_ENJOY_LATER'
export const TEXT_CHOOSE_A_PLACE = 'TEXT_CHOOSE_A_PLACE'
export const TEXT_ORDER_COUPONS = 'TEXT_ORDER_COUPONS'
export const TEXT_ENJOY_WHEN = 'TEXT_ENJOY_WHEN'
export const TEXT_MISSION_WHY = 'TEXT_MISSION_WHY'
export const TEXT_HOME_1 = 'TEXT_HOME_1'
export const TEXT_HOME_2 = 'TEXT_HOME_2'
export const TEXT_HOW = 'TEXT_HOW'
export const TEXT_HOME_3 = 'TEXT_HOME_3'
export const TEXT_FOR_FREE = 'TEXT_FOR_FREE'
export const TEXT_HOME_4 = 'TEXT_HOME_4'
export const TEXT_OFFERING_COUPONS = 'TEXT_OFFERING_COUPONS'
//tutorial section
export const TEXT_I_WANT_TO_OFFER_COUPONS = 'TEXT_I_WANT_TO_OFFER_COUPONS'
export const TEXT_SIGN_UP = 'TEXT_SIGN_UP'
export const TEXT_TUTORIAL_01 = 'TEXT_TUTORIAL_01'
export const TEXT_TUTORIAL_02 = 'TEXT_TUTORIAL_02'
export const TEXT_TUTORIAL_03 = 'TEXT_TUTORIAL_03'
export const TEXT_TUTORIAL_04 = 'TEXT_TUTORIAL_04'
export const TEXT_TUTORIAL_05 = 'TEXT_TUTORIAL_05'
export const TEXT_TUTORIAL_06 = 'TEXT_TUTORIAL_06'
export const TEXT_TUTORIAL_07 = 'TEXT_TUTORIAL_07'
export const TEXT_TUTORIAL_08 = 'TEXT_TUTORIAL_08'
export const TEXT_TUTORIAL_09 = 'TEXT_TUTORIAL_09'
export const TEXT_I_WANT_GET_SOME_COUPONS = 'TEXT_I_WANT_GET_SOME_COUPONS'
export const TEXT_TUTORIAL_11 = 'TEXT_TUTORIAL_11'
export const TEXT_TUTORIAL_12 = 'TEXT_TUTORIAL_12'
export const TEXT_TUTORIAL_13 = 'TEXT_TUTORIAL_13'
export const TEXT_TUTORIAL_14 = 'TEXT_TUTORIAL_14'
export const TEXT_TUTORIAL_15 = 'TEXT_TUTORIAL_15'
export const TEXT_TUTORIAL_16 = 'TEXT_TUTORIAL_16'
export const TEXT_TUTORIAL_17 = 'TEXT_TUTORIAL_17'
export const TEXT_MORE_INFO = 'TEXT_MORE_INFO'
export const TEXT_CONTACTS = 'TEXT_CONTACTS'
export const TEXT_HELP_FOR_BUSINESSES = 'TEXT_HELP_FOR_BUSINESSES'
export const TEXT_DO_YOU_HAVE_ANY_QUESTIONS = 'TEXT_DO_YOU_HAVE_ANY_QUESTIONS'
export const TEXT_EMAIL = 'TEXT_EMAIL'
export const TEXT_FAQ = 'TEXT_FAQ'
export const TEXT_ANSWERS_TO_FREQUENTLY_ASKED_QUESTIONS = 'TEXT_ANSWERS_TO_FREQUENTLY_ASKED_QUESTIONS'
export const TEXT_FOR_CUSTOMERS = 'TEXT_FOR_CUSTOMERS'
// comapny-list
export const TEXT_SEARCH = 'TEXT_SEARCH'
export const TEXT_TYPE_IN_KEYWORDS = 'TEXT_TYPE_IN_KEYWORDS'
export const TEXT_SELECT_LOCATION = 'TEXT_SELECT_LOCATION'
//category-filter
export const TEXT_SELECT_CATEGORIES = 'TEXT_SELECT_CATEGORIES'
export const TEXT_FOOD_AND_DRINK = 'TEXT_FOOD_AND_DRINK'
export const TEXT_ACTIVITIES = 'TEXT_ACTIVITIES'
export const TEXT_SERVICES = 'TEXT_SERVICES'
// company-card
export const TEXT_GET_A_COUPON = 'TEXT_GET_A_COUPON'
