import { AcceptCouponsRequest, ShareCouponsRequest } from '../types/share-couons-request-type'
import { ExtendedFirebaseInstance } from 'react-redux-firebase'

export const shareCoupons = (firebase: ExtendedFirebaseInstance, data: ShareCouponsRequest) => {
  //@ts-ignore
  const share = firebase.functions().httpsCallable('shareCoupons')
  return share(data)
}

export const acceptCoupon = (firebase: ExtendedFirebaseInstance, data: AcceptCouponsRequest) => {
  //@ts-ignore
  const share = firebase.functions().httpsCallable('acceptCoupon')
  return share(data)
}

export const getCoupon = (firebase: ExtendedFirebaseInstance, data: AcceptCouponsRequest) => {
  //@ts-ignore
  const share = firebase.functions().httpsCallable('getCoupon')
  return share(data)
}
