import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useSelector } from '../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Order } from '../types/order-type'
import { OrdersTable } from './components/orders-table'
import { Redirect } from 'react-router-dom'
import { DEFAULT_COMPANY_USER_ROUTE } from '../router/router-utils'
import { Typography } from '@material-ui/core'
import { InfoDialog } from '../_core/components/info-dialog'
import Label from '../_core/components/label'
import { getOrderStatus, toCurrency } from '../_core/utils/orders-utils'
import { OrderStatus } from '../types/order/order-status.enum'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  topTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  noMargin: {
    margin: 0,
  },
  statsItem: {
    padding: 10,
  },
  stats: {
    marginBottom: theme.spacing(3),
  },
}))
const OrderStats: React.FC<any> = props => {
  const { statistics } = props
  const classes = useStyles()
  return (
    <div className={classes.stats}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.statsItem}>
            <div>
              <Typography variant="overline">Užsakymų kiekis</Typography>
            </div>
            <div>
              <Typography variant="h3">{statistics.ordersTotal}</Typography>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Paper className={classes.statsItem}>
            <div>
              <Typography variant="overline">Bendra užsakymų suma</Typography>
            </div>
            <div>
              <Typography variant="h3">{toCurrency(statistics.ordersTotalPrice)}</Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.statsItem}>
            <div>
              <Typography variant="overline">Patvirtinti užsakymai</Typography>
            </div>
            <div>
              <Typography variant="h3">{statistics.ordersApproved}</Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.statsItem}>
            <div>
              <Typography variant="overline">Patvirtintų užsakymų suma</Typography>
            </div>
            <div>
              <Typography variant="h3">{toCurrency(statistics.ordersApprovedPrice)}</Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
const OrdersTutorial: React.FC<any> = props => {
  return (
    <InfoDialog title={'Kas tie užsakymai? ¯\\_(ツ)_/¯'}>
      <Typography variant="body1">
        Užsakymus formuoja jūsų klientai atlikti pirkimus iš įmonės puslapio. Suformavus užsakymą jis patenka į
        'Laukiama patvirtinimo' būsena, klientas gauna laišką, kuriame nurodytos detalės kaip atlikti mokėjimą.
        <br />
        <br />
        Būsenos:
        <br />
        1. <Label color={'warning'}>{getOrderStatus(OrderStatus.PENDING)}</Label> - klientas suformavo užsakymą, bet
        nebūtinai pervedė pinigus. Įsitikinę, kad mokėjimo paskirtyje nurodytas užsakymo numeris sutampa su šio užsakymo
        numeriu - patvirtinkite;
        <br />
        2.<Label color={'success'}>{getOrderStatus(OrderStatus.FINISHED)}</Label> - jūs patvirtinote šį užsakymą ir
        talonai jau yra kliento rankose.
        <br />
        <br />
        Kitos sąvokos:
        <br />
        <b>Vertė</b> - bendra užsakymo suma, kurią turite matyti pervesta savo banko sąskaitoje
      </Typography>
    </InfoDialog>
  )
}
export const Orders: React.FC<any> = props => {
  const profile = useSelector(state => state.firebase.profile)
  const classes = useStyles()
  const collection = `companies/${profile.companyId}/orders`
  useFirestoreConnect([{ collection: collection, orderBy: ['created', 'desc'] }])
  const statistics = useSelector(
    ({
      firestore: {
        ordered: { statistics },
      },
    }) => statistics && statistics[0],
  )
  const orders: Order[] = useSelector(state => state.firestore.ordered[collection])
  if (profile.role === 'user') {
    return <Redirect to={{ pathname: DEFAULT_COMPANY_USER_ROUTE }} />
  }

  console.log('statistics', statistics)
  return (
    <div className={classes.root}>
      <div className={classes.topTitle}>
        <Typography className={classes.noMargin} component="h2" gutterBottom variant="overline">
          Užsakymai
        </Typography>
        <OrdersTutorial />
      </div>
      <div className={classes.content}>
        {statistics && <OrderStats statistics={statistics} />}
        {orders && orders.length > 0 ? (
          <OrdersTable orders={orders} />
        ) : (
          <Typography variant="body1">Šiuo metu užsakymų nėra</Typography>
        )}
      </div>
    </div>
  )
}
