import { Button, Card, CardActions, CardContent, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import CardMedia from '@material-ui/core/CardMedia'
import PinDropIcon from '@material-ui/icons/PinDrop'
import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import palette from '../../theme/palette'
import { getImageUrl, ImageType } from '../../images/image-utils'
import { tr } from 'translations/translate'
import { TEXT_GET_A_COUPON } from 'translations/keys'
import { getIconByCategory, getTextByCategory } from '../../map/map'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  largeBlueText: {
    color: palette.primary.main,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '35px',
    margin: '16px',
  },
  button: {
    fontWeight: 700,
    fontSize: '24px',
  },
  midBlueText: {
    color: palette.primary.main,
  },
  blueText: {
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: '14px',
  },
  smallInput: {
    textAlign: 'center',
    maxWidth: '32px',
    '& input': {
      textAlign: 'center',
    },
  },
  value: {
    display: 'inline-block',
    margin: '0 5px',
  },
  discountPrice: {
    fontSize: 18,
    display: 'inline-block',
    margin: '0 5px',
    fontWeight: 500,
    color: palette.text.secondary,
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
    backgroundColor: '#ffffff',
  },
  badges: {
    fontSize: 12,
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
}))

export const CompanyCard = props => {
  const { className, company, ...rest } = props
  // const product = props.product as Product
  const classes = useStyles()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Link to={`/company/${company.alias}`}>
        <CardMedia
          className={classes.media}
          image={
            company.logoUrl && company.logoUrl.length > 0
              ? getImageUrl(company.logoUrl, ImageType.SMALL)
              : '/images/brand-placeholder.png'
          }
          title={company.name}
        />
        <CardContent>
          <Typography align="center" gutterBottom variant="h4">
            {company.name}
          </Typography>
          <Typography align="center" variant="subtitle2">
            {company.shortDescription}
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          <Grid container alignItems="center" justify="center">
            <Grid item style={{ fontSize: 0 }}>
              <img
                title={getTextByCategory(company.category)}
                src={getIconByCategory(company.category)}
                alt={'category'}
              />
            </Grid>
            <Grid item className={classes.marginLeftAuto}>
              <Button variant="outlined" color="primary" size="small">
                {tr(TEXT_GET_A_COUPON)}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Link>
    </Card>
  )
}
