import { Button, Grid, Link, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom'
import { EditorPreview } from '../../forms/inputs/editor-field-adapter'
import { getImageUrl, ImageType } from '../../images/image-utils'
import { useSelector } from '../../store'
import { TopHeader } from '../../users/components/top-header'
import { countOrderResetAction } from '../../views/count-order/count-order-actions'
import { registerPageView } from '../../_core/analytics/analytics'
import { CompanyLogo } from '../../_core/components/company-logo'
import { toCurrency } from '../../_core/utils/orders-utils'
import { countTotal } from '../count-order/count-total'
import { ProductList } from './product-list'
import { Facebook } from '@material-ui/icons'
import { FacebookShareButton } from 'react-share'
import { FullScreenLoader } from '../../common/full-screen-loader'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    minHeight: 'fit-content',
    margin: theme.spacing(0, 3),
    padding: theme.spacing(0, 0, 6, 0),
  },
  name: {
    marginTop: theme.spacing(1),
  },
  city: {
    marginTop: theme.spacing(1),
  },
  link: {
    marginTop: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(4),
  },
  grid: {
    height: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  imageContainer: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0),
    },
  },
  textContainer: {
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  text: {
    marginTop: theme.spacing(2),
  },
  productContainer: {
    marginTop: theme.spacing(8),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    //marginRight: theme.spacing(2),
  },
  bottom: {
    marginTop: theme.spacing(4),
  },
  cover: {
    backgroundImage: `url(../images/cover.jpg)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height: '400px',
    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
  },
  logo: {
    borderRadius: '50%',
    border: '5px solid #ffffff',
    background: '#ffffff',
    marginTop: '-50px',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      marginLeft: '8px',
      border: '2px solid #ffffff',
    },
  },
  topBar: {
    margin: theme.spacing(1, 0),
  },
  subHeader: {
    lineHeight: '1.5em',
    fontSize: 18,
  },
}))
export const Company: React.FC<any> = props => {
  const classes = useStyles()
  const history = useHistory()
  let params = useParams<any>()
  const orderData = useSelector(state => state.countOrder.orderData)
  const orderCompanyId = useSelector(state => state.countOrder.companyId)
  useEffect(() => {
    registerPageView('company_page')
  }, [])
  useFirestoreConnect([
    {
      where: [
        ['alias', '==', params.alias],
        ['verified', '==', true],
        ['isPublic', '==', true],
      ],
      collection: 'companies',
      storeAs: 'company',
    },
  ])
  const company = useSelector(
    ({
      firestore: {
        ordered: { company },
      },
    }) => company && company.find(x => x.alias === params.alias),
  )

  const companyId = company?.id ?? '_'

  const dispatch = useDispatch()
  useEffect(() => {
    console.log({ orderCompanyId }, companyId)
    if (orderCompanyId !== undefined && companyId !== '_' && orderCompanyId !== companyId) {
      dispatch(countOrderResetAction())
    }
  }, [dispatch, orderCompanyId, companyId])

  useFirestoreConnect({
    collection: `companies/${companyId}/products`,
    where: ['isPublished', '==', true],
    storeAs: `products_${companyId}`,
  })
  const products = useSelector(({ firestore: { ordered } }) => ordered[`products_${companyId}`]) ?? []

  const handleBack = () => {
    history.push('/')
  }
  if (!company) return <FullScreenLoader />

  return (
    <>
      <TopHeader linkTo={company.city && company.city.toLowerCase() === 'klaipėda' ? '/klaipeda' : undefined} />

      <div className={classes.root}>
        {company && (
          <div>
            <Grid className={classes.grid} container>
              <Grid item xs={12}>
                <div
                  style={
                    company.coverPhotoUrl
                      ? {
                          backgroundImage: `url(${getImageUrl(company.coverPhotoUrl, ImageType.MEDIUM)})`,
                        }
                      : {}
                  }
                  className={classes.cover}
                />
              </Grid>
              <Grid className={classes.imageContainer} item lg={2} md={2} xs={12}>
                <CompanyLogo company={company} />
              </Grid>
              <Grid className={classes.textContainer} item lg={10} md={10} xs={12}>
                <div className={classes.content}>
                  <div className={classes.text}>
                    <Typography className={classes.name} variant="h2">
                      {company.name}
                    </Typography>
                    <div style={{ margin: '10px 0' }}>
                      <FacebookShareButton
                        url={`https://talonai.lt/company/${company.alias}`}
                        quote={`${company.name}`}
                        hashtag={'#talonai'}
                        children={<Button variant={'outlined'} startIcon={<Facebook />}>{`Pasidalink`}</Button>}
                      />
                    </div>
                    <Typography className={classes.city} variant="h6">
                      {company.streetAddress}, {company.city}
                    </Typography>
                    {company.website && (
                      <Typography className={classes.link} color="textSecondary" variant="body1">
                        <Link href={company.website} variant="h6" rel="noreferrer noopener" target="_blank">
                          Mūsų svetainė
                        </Link>
                      </Typography>
                    )}
                    <Typography className={classes.description} variant="body1">
                      <EditorPreview value={company.description} />
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid className={classes.productContainer} item lg={12}>
                <Grid className={classes.grid} container>
                  <Grid item xs={12}>
                    <Typography className={classes.subHeader} component="h2" gutterBottom variant="overline">
                      Mūsų siūlomi Talonų nominalai.
                    </Typography>
                    <Typography variant={'body1'}>Išsirinkę kiek ir kokių pageidaujate, spauskite užsakyti</Typography>
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    {products.length ? (
                      <ProductList companyId={companyId} />
                    ) : (
                      <>
                        <Typography gutterBottom={true} variant="h4">
                          <br />( '._.)
                        </Typography>
                        <Typography variant="h6">
                          <br />
                          Šiuo metu <strong>{company.name}</strong> nėra išleidę Talonų.
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} className={classes.bottom}>
                    <Typography variant="h4">Suma: {toCurrency(countTotal(orderData))}</Typography>
                    <Button
                      component={RouterLink}
                      className={classes.button}
                      //to={`/company/${params.alias}/new-order/${x.id}`}
                      to={`/company/${params.alias}/new-order`}
                      variant="contained"
                      color="primary"
                      disabled={countTotal(orderData) === 0}
                    >
                      Užsakyti talonus
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </>
  )
}
