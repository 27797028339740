import { Dialog } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import firebase from 'firebase/app'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from '../store'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { Coupon } from 'types/coupon-type'
import { SelectFieldAdapter } from '../forms/inputs/select-field-adapter'
import { TextFieldAdapter } from '../forms/inputs/text-field-adapter'
import { Product } from '../types/product-type'
import { validatorEmail, validatorMinValue, validatorRequired } from '../_core/form-validators/validators'
import { toNumber } from '../_core/utils/parse-values'
import { EditorField } from '../forms/inputs/editor-field-adapter'
import draftToHtml from 'draftjs-to-html'

export const AddCoupon: React.FC<any> = props => {
  const { isOpen, close } = props
  const firestore = useFirestore()
  const [inProgress, setInProgress] = useState(false)
  const profile = useSelector(state => state.firebase.profile)
  const collection = `companies/${profile.companyId}/products`
  useFirestoreConnect([{ collection: collection, orderBy: ['created', 'desc'] }])
  const products: Product[] = useSelector(state => state.firestore.ordered[collection])
  const productOptions = products
    ? products.map((pr: Product) => {
        return { value: pr.id, label: pr.title }
      })
    : []
  const onSubmit = values => {
    const selectedProduct = products.find(pr => pr.id === values.product)
    if (!selectedProduct) return
    const coupon: Coupon = {
      ...values,
      email: values.email.toLowerCase(),
      claimed: false,
      content: draftToHtml(values.content),
      companyId: profile.companyId,
      product: {
        ...selectedProduct,
        count: values.count,
      },
      created: firebase.firestore.Timestamp.now(),
      updated: firebase.firestore.Timestamp.now(),
    }
    console.log('inProgress', inProgress)
    if (!inProgress) {
      setInProgress(true)
      firestore
        .collection(`companies/${profile.companyId}/manualCoupons`)
        .add(coupon)
        .then(_ => {
          setInProgress(false)
        })
    }
    close()
  }
  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <Form
        initialValues={{ count: 1 }}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Sukurti naują taloną</DialogTitle>
            <DialogContent>
              <div>
                <Field name="email" validate={validatorEmail} component={TextFieldAdapter} label="El. paštas" />
              </div>
              <div>
                <Field
                  name="product"
                  validate={validatorRequired}
                  component={SelectFieldAdapter}
                  label="Talono nominalas"
                  items={productOptions}
                />
              </div>
              <div>
                <Field
                  name="count"
                  validate={validatorMinValue(1)}
                  parse={toNumber}
                  fieldType="number"
                  component={TextFieldAdapter}
                  label="Kiekis"
                />
              </div>
              <div>
                <Field name="content" component={EditorField} label="Pridėkite žinutę nuo savęs! (neprivaloma)" />
              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="text" color="primary" onClick={close}>
                Atšaukti
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Sukurti ir siųsti el. paštu
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  )
}
