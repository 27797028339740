import { ACTION_UPDATE_FILES } from './products-actions'

const initialState: { files: File[] } = {
  files: [],
}

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_UPDATE_FILES:
      return {
        ...state,
        files: action.payload,
      }
    default:
      return state
  }
}
