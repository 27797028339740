import React, { useEffect } from 'react'
import { MapComponent } from './map'
import { Grid } from '@material-ui/core'
import { useSelector } from '../store'
import { MapCompanyList } from './companies/map-company-list'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Hidden from '@material-ui/core/Hidden'
import CircularProgress from '@material-ui/core/CircularProgress'
import { SearchFilter } from '../views/home/filters/search-filter'
import Typography from '@material-ui/core/Typography'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CategoryFilter } from '../views/home/category-filter'
import { FullScreenLoader } from '../common/full-screen-loader'
import { MapTopBar } from './map-top-bar'
import { countOrderResetAction } from '../views/count-order/count-order-actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    paddingTop: 48,
  },
  listColumn: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
  },
  topFilters: {
    padding: '10px 10px 0 10px',
  },
  panel: { marginTop: '-16px' },
  heading: {},
}))
type Props = {}
const MoreFilters = () => {
  const classes = useStyles()
  return (
    <div className={classes.panel}>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>Daugiau filtrų</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <CategoryFilter />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}
export const MapPage: React.FC<Props> = () => {
  const classes = useStyles()
  const companies = useSelector(
    ({
      firestore: {
        ordered: { publicCompanies },
      },
    }) => publicCompanies && publicCompanies,
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(countOrderResetAction())
  }, [dispatch])
  if (!companies) return <FullScreenLoader />
  return (
    <Grid container className={classes.root}>
      <MapTopBar />
      <Hidden smDown>
        <Grid item md={3} className={classes.listColumn}>
          <div className={classes.topFilters}>
            <SearchFilter />
          </div>
          {/*<MoreFilters />*/}
          <MapCompanyList companies={companies} />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={12} md={9}>
        <MapComponent companies={companies} />
      </Grid>
    </Grid>
  )
}
