import { Divider, Drawer } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import DashboardIcon from '@material-ui/icons/Dashboard'
import InputIcon from '@material-ui/icons/Input'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import OrdersIcon from '@material-ui/icons/LibraryBooks'
import CouponIcon from '@material-ui/icons/LocalOffer'
import FeedbackIcon from '@material-ui/icons/Feedback'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { Profile } from './profile'
import { SidebarNav } from './sidebar-nav'
import { useFirebase, useFirestoreConnect } from 'react-redux-firebase'
import { Company } from '../../types/company-type'
import { useSelector } from '../../store'
import Label from '../../_core/components/label'
import { Home } from '@material-ui/icons'
import { Product } from '../../types/product-type'
import StarIcon from '@material-ui/icons/Star'
const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))
const MissingData = props => {
  const company: Company = props.company
  if (company && (!company.accountNumber || !company.locations || company.locations.length === 0)) {
    return <Label color={'error'}>Užpildykite duomenis</Label>
  }
  return null
}
const MissingProducts = props => {
  const { profile } = props
  const collection = `companies/${profile.companyId}/products`
  useFirestoreConnect([{ collection: collection, orderBy: ['created', 'desc'] }])
  const products: Product[] = useSelector(state => state.firestore.ordered[collection])
  if (products && products.length === 0) {
    return <Label color={'error'}>Susikurkite nominalų</Label>
  }
  return null
}
const NewTag = props => {
  return <Label color={'secondary'}>Naujas!</Label>
}
type Page = {
  target?: string
  roles: Array<string>
  hidden?: boolean
  title: any
  href: string
  icon: any
  bottom?: boolean
}
const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props
  const profile = useSelector(state => state.firebase.profile)
  const companyDocument = `companies/${profile.companyId}`
  useFirestoreConnect(companyDocument)
  useFirestoreConnect([
    {
      collection: 'statistics',
      doc: profile.companyId,
      storeAs: 'statistics',
    },
  ])
  const company: Company | undefined = useSelector(
    ({ firestore: { data } }) => data.companies && data.companies[profile.companyId],
  )
  const classes = useStyles()
  const pages: Page[] = [
    {
      target: '_blank',
      roles: ['user', 'admin'],
      hidden: company && (!company.verified || !company.isPublic),
      title: 'Įmonės pradinis puslapis',
      href: `/company/${company ? company.alias : ''}`,
      icon: <Home />,
    },
    {
      title: 'Užsakymai',
      roles: ['admin'],
      href: '/orders',
      icon: <OrdersIcon />,
    },
    {
      title: (
        <div>
          <div>Nominalai</div>
          <div>
            <MissingProducts profile={profile} />
          </div>
        </div>
      ),
      roles: ['admin'],
      href: '/products',
      icon: <ShoppingBasketIcon />,
    },
    {
      title: 'Talonai',
      roles: ['admin'],
      href: '/coupons',
      icon: <CouponIcon />,
    },
    {
      title: 'Darbuotojai',
      roles: ['admin'],
      href: '/users',
      icon: <PeopleIcon />,
    },
    {
      title: (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 8 }}>Įmonės duomenys</div>
          </div>
          <div>
            <MissingData company={company} />
          </div>
        </div>
      ),
      href: '/account',
      roles: ['admin'],
      icon: <AccountBoxIcon />,
    },
    {
      title: (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 8 }}>Patarimai</div>
          </div>
        </div>
      ),
      href: '/promote',
      roles: ['admin'],
      icon: <StarIcon />,
    },
    {
      title: 'Nustatymai',
      roles: ['admin'],
      href: '/settings',
      icon: <SettingsIcon />,
    },
    {
      bottom: true,
      roles: ['user', 'admin'],
      title: 'Atsiliepimai',
      href: '/feedback',
      icon: <FeedbackIcon />,
    },
    {
      bottom: true,
      roles: ['user', 'admin'],
      title: 'Atsijungti',
      href: '/sign-out',
      icon: <InputIcon />,
    },
  ]

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile company={company} />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}

export default Sidebar
