import React from 'react'
import { useSelector } from '../../store'
//import { useFirestoreConnect } from 'react-redux-firebase'
import { Product } from '../../types/product-type'
import { MapProductCard } from './map-product-card'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import makeStyles from '@material-ui/core/styles/makeStyles'
//import mockData from './data';

const useStyles = makeStyles(theme => ({
  content: {
    // marginTop: theme.spacing(2)
  },
  pagination: {
    // marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  tile: {
    width: '100%',
    height: 400,
  },
}))
type Props = {
  companyId: string
}
export const MapProductList: React.FC<Props> = props => {
  const { companyId } = props
  const classes = useStyles()
  const products: Product[] = useSelector(({ firestore: { ordered } }) => ordered[`products_${companyId}`]) ?? []
  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={1}>
        {products
          .filter(product => product.isPublished)
          .map(product => (
            <MapProductCard companyId={companyId} product={product} />
          ))}
      </GridList>
    </div>
  )
}
