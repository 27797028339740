import { AppBar, Button } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Toolbar from '@material-ui/core/Toolbar'
import InputIcon from '@material-ui/icons/Input'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { getRandomInt } from '../_core/utils/random-int'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  logo: {
    color: '#fff',
    marginLeft: 8,
    letterSpacing: '2px',
    fontWeight: 700,
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    width: '130px',
  },
}))

export const TopBar = props => {
  const { className, onSidebarOpen, ...rest } = props

  const classes = useStyles()
  const history = useHistory()
  // const [notifications] = useState([])
  const handleLogout = () => {
    history.push('/sign-out')
  }

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Link to="/">
          <div className={classes.logoWrapper}>
            <img className={classes.logoImage} alt="Logo" src={`/images/logos/logo.svg`} />
          </div>
        </Link>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          {/*<IconButton color="inherit">*/}
          {/*  <Badge badgeContent={notifications.length} color="primary" variant="dot">*/}
          {/*    <NotificationsIcon />*/}
          {/*  </Badge>*/}
          {/*</IconButton>*/}
          <Button className={classes.logoutButton} color="inherit" onClick={handleLogout}>
            <InputIcon className={classes.logoutIcon} />
            Atsijungti
          </Button>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}
