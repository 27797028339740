import React, { useEffect } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { UserAcceptInvite } from 'users/user-accept-invite'
import { Account } from '../account/account'
import { AuthProvider } from '../auth/auth-provider'
import { SignOut } from '../auth/sign-out'
import { ConfirmClaim } from '../coupons/confirm-claim'
import { Coupons } from '../coupons/coupons'
import { ClaimCoupon } from '../customer/coupon/claim-coupon'
import { CustomerCoupons } from '../customer/coupons/customer-coupons'
import { MyOrders } from '../customer/orders/orders'
import { Dashboard } from '../dashboard/dashboard'
import { SignIn } from '../login/sign-in'
import { SingleOrder } from '../orders/order/order'
import { Orders } from '../orders/orders'
import { AddProduct } from '../products/add-product'
import { Products } from '../products/products'
import { AdminClaimRoute, AdminPanelRoute } from '../router/admin-panel-route'
import { CustomerPanelRoute } from '../router/customer-panel-route'
import { Settings } from '../settings/settings'
import { SignUp } from '../sign-up/sign-up'
import { Users } from '../users/users'
import { Company } from '../views/company/company'
import { Home } from '../views/home/home'
import { Order } from '../views/order/order'
import { FinishLogin } from '../views/user/finish-login'
import { UserLogin } from '../views/user/user-login'
import './app.css'
import { SingleCoupon } from '../coupons/single-coupon'
import { Invoice } from '../orders/invoice/invoice'
import { EditProduct } from '../products/edit-product'
import { Terms } from '../views/terms/terms'
import { VerifyCompany } from '../company/verify-company'
import { Feedback } from '../settings/feedback'
import { CustomerInvoice } from '../customer/orders/customer-invoice'
import { TermsForBusiness } from '../views/terms/terms-for-business'
import { FaqBusiness } from '../views/faq/faq-business'
import { FaqCustomer } from '../views/faq/faq-customer'
import { PdfTestComponent } from '../pdf/pdf-test'
import { FeaturedCompany } from '../company/feature-company'
import { ControlCenterRoute } from '../router/control-center-route'
import { ControlCenterCompanies } from '../control-center/companies/control-companies'
import { PromoteCompany } from '../company/promote-company'
import { ReduxFirestoreQuerySetting, useFirestoreConnect } from 'react-redux-firebase'
import { CookiesNotification } from '../_core/analytics/consent'
import { MapPage } from '../map/map-page'
import { ControlCenterEmails } from '../control-center/emails/control-center-emails'
import { HomeKlaipeda } from '../views/home/cities/klaipėda'
import { ThankYou } from '../views/thank-you/thank-you'
import { ShareCoupons } from '../customer/share/share-coupons'
import { AcceptCoupon } from '../customer/coupons/accept-coupon'

const companiesQuery: ReduxFirestoreQuerySetting[] = [
  {
    collection: 'companies',
    where: [
      ['verified', '==', true],
      ['isPublic', '==', true],
      ['featured', '==', true],
    ],
    orderBy: ['order', 'desc'],
    storeAs: 'publicCompanies',
  },
]
const featuredProducts: ReduxFirestoreQuerySetting[] = [
  {
    collectionGroup: 'products',
    where: [
      ['isPublished', '==', true],
      ['featured', '==', true],
    ],
    storeAs: 'publicProducts',
  },
]

const App: React.FC = () => {
  useFirestoreConnect(companiesQuery)
  useFirestoreConnect(featuredProducts)
  return (
    <AuthProvider>
      <BrowserRouter>
        <CookiesNotification />
        <Route exact path="/" component={Home} />
        <Route exact path="/klaipeda" component={HomeKlaipeda} />
        <Route exact path="/map" component={MapPage} />
        <Route exact path="/miestas/:city" component={Home} />
        <Route exact path="/company/:alias" component={Company} />
        <Route exact path="/company/:alias/new-order" component={Order} />

        <Route exact path="/login/:redirectUrl?/:param1?" component={SignIn} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/terms-for-business" component={TermsForBusiness} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/sign-out" component={SignOut} />
        <Route exact path="/user-login" component={UserLogin} />
        <Route exact path="/finish-signup" component={FinishLogin} />
        <Route exact path="/duk/verslui" component={FaqBusiness} />
        <Route exact path="/thank-you" component={ThankYou} />
        <Route exact path="/duk/lankytojams" component={FaqCustomer} />
        <Route exact path="/pdf/:companyId/:couponId" component={PdfTestComponent} />
        <Route exact path="/user-accept-invite/:email/:companyId/:inviteId" component={UserAcceptInvite} />

        <Route
          exact
          pageTitle={'accept_coupon'}
          path="/accept-coupon/:companyId/:couponId/:code"
          component={AcceptCoupon}
        />
        <CustomerPanelRoute exact pageTitle={'my_coupons'} path="/my-coupons" component={CustomerCoupons} />
        <CustomerPanelRoute exact pageTitle={'my_orders'} path="/my-orders" component={MyOrders} />
        <CustomerPanelRoute exact pageTitle={'share'} path="/share" component={ShareCoupons} />
        <CustomerPanelRoute
          exact
          pageTitle={'my_invoice'}
          path="/my-orders/invoice/:companyId/:orderId"
          component={CustomerInvoice}
        />
        <CustomerPanelRoute
          exact
          pageTitle={'my_claim_coupon'}
          path="/claim/:companyId/:couponId"
          component={ClaimCoupon}
        />

        <AdminPanelRoute exact path="/dashboard" component={Dashboard} />
        <AdminPanelRoute exact path="/coupons" component={Coupons} />
        <AdminPanelRoute exact path="/products" component={Products} />
        <AdminPanelRoute exact path="/products/edit/:productId" component={EditProduct} />
        <AdminPanelRoute exact path="/products/add-product" component={AddProduct} />
        <AdminPanelRoute exact path="/orders" component={Orders} />
        <AdminPanelRoute exact path="/orders/:orderId" component={SingleOrder} />
        <AdminPanelRoute exact path="/orders/invoice/:orderId" component={Invoice} />
        <AdminClaimRoute exact path="/confirm-claim/:couponId" component={ConfirmClaim} />
        <AdminPanelRoute exact path="/coupons/:couponId" component={SingleCoupon} />
        <AdminPanelRoute exact path="/users" component={Users} />
        <AdminPanelRoute exact path="/account" component={Account} />
        <AdminPanelRoute exact path="/settings" component={Settings} />
        <AdminPanelRoute exact path="/feedback" component={Feedback} />
        <AdminPanelRoute exact path="/verify-company" component={VerifyCompany} />
        <AdminPanelRoute exact path="/feature-my-company" component={FeaturedCompany} />
        <AdminPanelRoute exact path="/promote" component={PromoteCompany} />

        <ControlCenterRoute exact path="/control-center/companies" component={ControlCenterCompanies} />
        <ControlCenterRoute exact path="/control-center/send-email" component={ControlCenterEmails} />
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
