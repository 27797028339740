import { Grid, Typography } from '@material-ui/core'
import { tr } from '../../../translations/translate'
import {
  TEXT_CHOOSE_A_PLACE,
  TEXT_ENJOY_LATER,
  TEXT_ENJOY_WHEN,
  TEXT_FOR_FREE,
  TEXT_GET_COUPON_NOW,
  TEXT_HOME_1,
  TEXT_HOME_2,
  TEXT_HOME_3,
  TEXT_HOME_4,
  TEXT_HOW,
  TEXT_MISSION_WHY,
  TEXT_ORDER_COUPONS,
} from '../../../translations/keys'
import React from 'react'
import { useHomeStyles } from '../styles/style'

export const Mission = () => {
  const classes = useHomeStyles()
  return (
    <div className={classes.box}>
      <div className={classes.wrapper}>
        <Grid container spacing={4} justify="space-between">
          <Grid item lg={4} xs={12}>
            <div className={classes.squareGreen}>
              <Typography variant="h2" className={classes.headerBold}>
                {tr(TEXT_GET_COUPON_NOW)}
              </Typography>
              <Typography variant="h2" className={classes.headerBold}>
                {tr(TEXT_ENJOY_LATER)}
              </Typography>
              <Grid item lg={6} xs={12} className={classes.listItemContainer}>
                <Typography variant="h2" className={classes.listItemNo}>
                  ( •ᴗ• )❤
                </Typography>
                <Typography variant="h4" className={classes.listItem}>
                  {tr(TEXT_CHOOSE_A_PLACE)}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12} className={classes.listItemContainer}>
                <Typography variant="h2" className={classes.listItemNo}>
                  ~(˘▾˘~)
                </Typography>
                <Typography variant="h4" className={classes.listItem}>
                  {tr(TEXT_ORDER_COUPONS)}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12} className={classes.listItemContainer}>
                <Typography variant="h2" className={classes.listItemNo}>
                  ☜(˚▽˚)☞
                </Typography>
                <Typography variant="h4" className={classes.listItem}>
                  {tr(TEXT_ENJOY_WHEN)}
                </Typography>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={7} xs={12}>
            <Grid container direction="column">
              <Grid item xs={12} className={classes.row}>
                <Typography variant="h2" className={classes.headerMain}>
                  {tr(TEXT_MISSION_WHY)}
                </Typography>
                <Typography variant="h3" className={classes.intro}>
                  {tr(TEXT_HOME_1)}
                </Typography>
                <Typography variant="h3" className={classes.intro}>
                  {tr(TEXT_HOME_2)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item lg={6} xs={12} className={classes.square}>
                    <Typography variant="h2" className={classes.headerMid}>
                      {tr(TEXT_HOW)}
                    </Typography>
                    <Typography variant="body1" className={classes.para}>
                      {tr(TEXT_HOME_3)}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={12} className={classes.square}>
                    <Typography variant="h2" className={classes.headerMid}>
                      {tr(TEXT_FOR_FREE)}
                    </Typography>
                    <Typography variant="body1" className={classes.para}>
                      {tr(TEXT_HOME_4)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
