import { Box, Container } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useSelector } from '../../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import { Order } from '../../types/order-type'
import { SingleOrderToolbar } from '../components/single-order-toolbar'
import OrderInfo from './order-info'
import OrderItems from './order-items'

const useStyles = makeStyles(theme => ({
  card: {},
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
}))
export const SingleOrder: React.FC<any> = props => {
  const params = useParams<any>()
  const classes = useStyles()
  const profile = useSelector(state => state.firebase.profile)
  useFirestoreConnect([
    {
      collection: `companies/${profile.companyId}/orders/`,
      doc: `${params.orderId}`,
      storeAs: 'singleOrder',
    },
  ])
  const order: Order = useSelector(state =>
    state.firestore.ordered.singleOrder ? state.firestore.ordered.singleOrder[0] : null,
  )
  return (
    <div className={classes.root}>
      <SingleOrderToolbar order={order} />
      {order && (
        <Container maxWidth={false}>
          <Box mt={2}>
            <Grid container spacing={3}>
              <Grid item md={4} xl={3} xs={12}>
                <OrderInfo order={order} />
              </Grid>
              <Grid item md={8} xl={9} xs={12}>
                <OrderItems order={order} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </div>
  )
}
