import { OrderStatus } from '../../types/order/order-status.enum'
import { Order } from '../../types/order-type'
import { Product } from '../../types/product-type'

export const paymentStatusColors = {
  [OrderStatus.CANCELED]: 'error',
  [OrderStatus.PENDING]: 'warning',
  [OrderStatus.FINISHED]: 'success',
  [OrderStatus.REJECTED]: 'error',
}
export const getOrderStatus = (status: OrderStatus): string => {
  const statuses = {
    [OrderStatus.PENDING]: 'Laukia patvirtinimo',
    [OrderStatus.FINISHED]: 'Patvirtintas',
    [OrderStatus.CANCELED]: 'Atšauktas',
    [OrderStatus.REJECTED]: 'Atmestas',
  }
  return statuses[status]
}

export const getTotalBeforeVat = (order: Order, vat?: number): number => {
  if (!vat) {
    vat = 0
  }
  const total = getOrderTotal(order)
  return total / (1 + vat / 100)
}

export const toCurrency = (value: any): string => {
  return `${Number(value).toFixed(2)}€`
}

export const getOrderTotal = (order: Order): number => {
  return order.products.reduce((a: number, product: Product) => {
    return (a += Number(product.price) * Number(product.count))
  }, 0)
}

export const formatOrderForEmail = (order: Order): any => {
  return {
    ...order,
    products: order.products.map((product: Product) => {
      return { ...product, total: toCurrency(product.price * product.count) }
    }),
  }
}
