import makeStyles from '@material-ui/core/styles/makeStyles'
import { white } from '../../../theme/palette'

export const useHomeStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  box: {
    width: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
  },
  wrapper: {
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
  header: {
    marginBottom: theme.spacing(2),
    //marginTop: theme.spacing(4),
    textAlign: 'center',
    margin: '0 auto',
    //textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: 1.5,
    //letterSpacing: '6px',
    //color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
      marginTop: theme.spacing(0),
    },
  },
  headerMain: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '100px',
    textAlign: 'center',
    marginBottom: theme.spacing(6),
    lineHeight: '1.2',
    [theme.breakpoints.down('sm')]: {
      fontSize: '44px',
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(4),
    },
  },
  headerBold: {
    marginBottom: theme.spacing(2),
    margin: '0 auto',
    fontWeight: 700,
    fontSize: '44px',
    lineHeight: '1.2',
    color: white,
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
      marginTop: theme.spacing(0),
    },
  },
  row: {
    marginBottom: theme.spacing(8),
    //marginTop: theme.spacing(12),
  },
  listItemContainer: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: '280px',
  },
  listItem: {
    marginTop: theme.spacing(4),
    fontWeight: 500,
    fontSize: '17px',
    //textAlign: 'center',
    //color: 'white',
  },
  listItemNo: {
    marginTop: theme.spacing(0),
    fontWeight: 500,
    //textAlign: 'center',
    //color: theme.palette.primary.main,
  },
  para: {
    fontSize: '17px',
    marginBottom: theme.spacing(2),
    lineHeight: '1.6',
  },
  headerMid: {
    marginBottom: theme.spacing(2),
    margin: '0 auto',
    fontWeight: 700,
    fontSize: '17px',
    textTransform: 'uppercase',
    letterSpacing: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      letterSpacing: '2px',
      marginTop: theme.spacing(0),
      textAlign: 'center',
    },
  },
  headerComp: {
    marginBottom: theme.spacing(2),
    //marginTop: theme.spacing(4),
    textAlign: 'center',
    margin: '0 auto',
    textTransform: 'uppercase',
    fontWeight: 300,
    fontSize: '64px',
    lineHeight: 1.5,
    letterSpacing: '20px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
      letterSpacing: '2px',
      marginTop: theme.spacing(0),
      textAlign: 'center',
    },
  },
  square: {
    //maxWidth: '360px',
    //margin: theme.spacing(6),
  },
  squareGreen: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
    padding: '48px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  squareOutlined: {
    borderRadius: '4px',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: '48px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },

  intro: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '24px',
    marginBottom: theme.spacing(4),
    lineHeight: '1.6',
    [theme.breakpoints.down('sm')]: {
      fontSize: '17px',
    },
  },
  emojiHand: {
    //transform: 'rotate(90deg)',
    fontSize: '48px',
    color: 'white',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
  },
  infoButton: {
    marginBottom: theme.spacing(2),
    color: 'white',
    borderColor: 'white',
  },
  tutorialText: { fontWeight: 500, fontSize: 14 },
  emojiHandSmall: {
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
}))
