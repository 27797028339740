export const ACTION_UPDATE_CATEGORY_FILTERS = 'ACTION_UPDATE_CATEGORY_FILTERS'
export const ACTION_SET_INITIAL_CATEGORIES = 'ACTION_SET_INITIAL_CATEGORIES'
export const ACTION_SET_SEARCH = 'ACTION_SET_SEARCH'
export const ACTION_SET_CITY = 'ACTION_SET_CITY'

export const actionUpdateCategoryFilters = (name, value) => ({
  type: ACTION_UPDATE_CATEGORY_FILTERS,
  name,
  value,
})
export const actionSetCity = city => ({
  type: ACTION_SET_CITY,
  city,
})
export const actionSetInitialCategories = categories => ({
  type: ACTION_SET_INITIAL_CATEGORIES,
  categories,
})

export const actionSetSearch = search => ({
  type: ACTION_SET_SEARCH,
  search,
})
