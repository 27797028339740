import React, { useState } from 'react'
import { Button, Dialog } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { actionAddShareUsers, actionIncrementShareStep, actionRemoveEmail } from '../share-actions'
import { useSelector } from '../../../store'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Field, Form } from 'react-final-form'
import { validatorRequired } from '../../../_core/form-validators/validators'
import { TextFieldAdapter } from '../../../forms/inputs/text-field-adapter'
import { EditorField } from '../../../forms/inputs/editor-field-adapter'
import draftToHtml from 'draftjs-to-html'
import firebase from 'firebase'
import { ShareCouponsRequest } from '../../../types/share-couons-request-type'
import { useSnackbar } from 'notistack'
import { Coupon } from '../../../types/coupon-type'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Tooltip from '@material-ui/core/Tooltip'
import { Add, Delete } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  spacer: {
    marginBottom: theme.spacing(2),
  },
}))
type Props = {}
const AddUsers = () => {
  const [open, setOpen] = useState(false)
  const [emails, setEmails] = useState([])
  const dispatch = useDispatch()
  const handleOnChange = event => {
    const value = event.target.value
    setEmails(value.split('\n'))
  }
  const handleAddUsers = () => {
    dispatch(actionAddShareUsers(emails))
    setOpen(false)
  }
  return (
    <div>
      <Button startIcon={<Add />} variant={'contained'} color={'primary'} onClick={() => setOpen(true)}>
        Pridėti gavėjus
      </Button>
      <Dialog open={open} onClose={_ => setOpen(false)} fullWidth={true} maxWidth={'md'}>
        <DialogTitle>Pridėkite gavėjų el. pašto adresus</DialogTitle>
        <DialogContent>
          <TextField
            label="Gavėjų sąrašas"
            placeholder="El. pašto adresai, kiekvienas iš naujos eilutės"
            multiline
            onChange={handleOnChange}
            rows={20}
            fullWidth={true}
            margin="normal"
          />
          {emails.length > 0 && <Typography variant={'caption'}>{`Iš viso įvesta ${emails.length}`}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={_ => setOpen(false)}>
            Atšaukti
          </Button>
          <Button color="primary" variant={'contained'} onClick={handleAddUsers}>
            Pridėti
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
const createShareArray = (
  coupons: Coupon[],
  emails: string[],
): Array<{ couponId: string; companyId: string; email: string }> => {
  if (emails.length > coupons.length) {
    return []
  }
  return emails.map((email, index) => {
    return {
      companyId: coupons[index].companyId,
      couponId: coupons[index].id,
      email: email,
    }
  })
}
export const AddShareUsers: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const [sending, setSending] = useState(false)
  const coupons = useSelector(state => state.share.coupons)
  const emails = useSelector(state => state.share.emails)
  const { enqueueSnackbar } = useSnackbar()
  const showMessage = (type, message) => {
    enqueueSnackbar(message, {
      variant: type,
    })
  }
  const classes = useStyles()
  const onSubmit = values => {
    const shareArray = createShareArray(coupons, emails)
    if (emails.length === 0) {
      showMessage('error', 'Nėra pridėta nei vieno gavejo')
      return
    }
    if (shareArray.length === 0) {
      showMessage('error', 'Neužtenka talonų visiems gavėjams')
      return
    }
    setSending(true)
    const data: ShareCouponsRequest = {
      customHtml: draftToHtml(values.content),
      senderName: values.senderName,
      coupons: shareArray,
    }

    const shareCoupons = firebase.functions().httpsCallable('shareCoupons')
    shareCoupons(data)
      .then(function (result) {
        console.log('result.', result)
        setSending(false)
        showMessage(result.data.type, result.data.message)
      })
      .catch(error => {
        setSending(false)
        showMessage('error', error.message)
        console.error(error)
      })
  }
  const handleRemove = index => {
    dispatch(actionRemoveEmail(index))
  }
  return (
    <div>
      <div>
        <Typography variant={'h4'}>Iš viso galima išdalinti Talonų: {coupons.length}</Typography>
        <div className={classes.spacer} />
      </div>
      <div>
        <AddUsers />
        <div className={classes.spacer} />
      </div>
      <div>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.spacer} />
              <Field
                validate={validatorRequired}
                name="senderName"
                component={TextFieldAdapter}
                label="Siuntėjo pavadinimas"
              />
              <Typography variant={'caption'}>{`Naudojamas pavadinime 'Gavote dovanų nuo ${
                values.senderName || 'Pavadinimas'
              }'`}</Typography>
              <div className={classes.spacer} />
              <div className={classes.spacer} />
              <div className={classes.spacer} />
              <div className={classes.spacer} />
              <Field name="content" component={EditorField} label="Pridėkite žinutę nuo savęs!" />
              <div className={classes.spacer} />
              <Button onClick={() => dispatch(actionIncrementShareStep(-1))}>Atgal</Button>
              <Button
                endIcon={sending ? <CircularProgress size={24} color={'secondary'} /> : null}
                disabled={sending}
                variant={'contained'}
                color={'primary'}
                type="submit"
              >
                Siųsti
              </Button>
            </form>
          )}
        />
      </div>
      <div>
        <div className={classes.spacer} />
        <div className={classes.spacer} />
        <Typography variant={'h4'}>Gavėjai</Typography>
        <div className={classes.spacer} />
        {emails.length > 0 ? (
          <table cellPadding={4}>
            <thead>
              <tr>
                <th>El. paštas</th>
                <th>Talono kodas</th>
                <th>Veiksmai</th>
              </tr>
            </thead>

            {emails.map((email, index) => {
              return (
                <tr>
                  <td>
                    <b>{email}</b>
                  </td>
                  <td>{index < coupons.length ? `#${coupons[index].id}` : 'Neužtenka Talonų'}</td>
                  <td>
                    <Tooltip title={'Pašalinti'}>
                      <Delete onClick={() => handleRemove(index)} />
                    </Tooltip>
                  </td>
                </tr>
              )
            })}
          </table>
        ) : (
          <Typography>Gavėjų nėra, spauskite pridėti gavėjus</Typography>
        )}
      </div>
    </div>
  )
}
