import * as React from 'react'
import { QRCode } from 'react-qr-svg'
import { getImageUrl, ImageType } from '../../images/image-utils'
import { Company } from '../../types/company-type'
import { Coupon } from '../../types/coupon-type'
import { getCompanyAddress } from '../../_core/utils/company-utils'
import { getCouponHumanId, getExpirationDate, shortenDescription } from '../../_core/utils/coupon-utils'
import { getValueText } from '../../products/product-helper'
type Props = {
  coupon: Coupon
  company: Company
}
export const PdfCouponV3: React.FC<Props> = ({ coupon, company }) => {
  return (
    <div id="_idContainer003" className="Basic-Text-Frame">
      <table id="table001" className="Basic-Table">
        <colgroup>
          <col className="_idGenTableRowColumn-1" />
          <col className="_idGenTableRowColumn-2" />
        </colgroup>
        <tbody>
          <tr className="Basic-Table _idGenTableRowColumn-3">
            <td className="Basic-Table CellOverride-1">
              <p className="Basic-Paragraph ParaOverride-1">
                <span className="CharOverride-1">
                  <img
                    className="_idGenObjectAttribute-1"
                    alt={company.name}
                    src={getImageUrl(company.logoUrl, ImageType.SMALL)}
                  />
                </span>
              </p>
            </td>
            <td className="Basic-Table CellOverride-2">
              <p className="Basic-Paragraph ParaOverride-1">
                <span className="CharOverride-2">{getValueText(coupon.product)}</span>
              </p>
            </td>
          </tr>
          <tr className="Basic-Table _idGenTableRowColumn-4">
            <td className="Basic-Table CellOverride-3 _idGenCellOverride-1" colSpan={2}>
              <p className="Basic-Paragraph ParaOverride-1">
                <span className="CharOverride-3">{shortenDescription(coupon.product.shortDescription)}</span>
              </p>
            </td>
          </tr>
          <tr className="Basic-Table _idGenTableRowColumn-5">
            <td className="Basic-Table CellOverride-4 _idGenCellOverride-1" colSpan={2}>
              <p className="Basic-Paragraph ParaOverride-1">
                <span className="CharOverride-4">#{getCouponHumanId(coupon)}</span>
              </p>
            </td>
          </tr>
          <tr className="Basic-Table _idGenTableRowColumn-6">
            <td className="Basic-Table CellOverride-5 _idGenCellOverride-1" colSpan={2}>
              <p className="Basic-Paragraph ParaOverride-1">
                <span className="CharOverride-1">
                  <QRCode
                    className="_idGenObjectAttribute-2"
                    value={`https://talonai.lt/confirm-claim/${coupon.id}`}
                    style={{ width: 340, height: 340 }}
                  />
                </span>
              </p>
            </td>
          </tr>
          <tr className="Basic-Table _idGenTableRowColumn-7">
            <td className="Basic-Table CellOverride-6 _idGenCellOverride-1" colSpan={2}>
              <p className="Basic-Paragraph ParaOverride-1">
                <span className="CharOverride-5">Galioja iki: </span>
              </p>
              <p className="Basic-Paragraph ParaOverride-1">
                <span className="CharOverride-4">{getExpirationDate(coupon)}</span>
              </p>
            </td>
          </tr>
          <tr className="Basic-Table _idGenTableRowColumn-8">
            <td className="Basic-Table CellOverride-7 _idGenCellOverride-1" colSpan={2}>
              <p className="Basic-Paragraph">
                <span className="CharOverride-6">Kontaktai</span>
              </p>
              <p className="Basic-Paragraph">
                <span className="CharOverride-6">Įmonė: </span>
                <span className="CharOverride-7">{`${company.name} (${company.businessName})`}</span>
              </p>
              <p className="Basic-Paragraph">
                <span className="CharOverride-6">Telefono numeris: </span>
                <span className="CharOverride-7">{`${company.phone}`}</span>
              </p>
              <p className="Basic-Paragraph">
                <span className="CharOverride-6">Elektroninis paštas: </span>
                <span className="CharOverride-7">{`${company.email}`}</span>
              </p>
              <p className="Basic-Paragraph">
                <span className="CharOverride-6">Adresas: </span>
                <span className="CharOverride-7">{`${getCompanyAddress(company)}`}</span>
              </p>
            </td>
          </tr>
          <tr className="Basic-Table _idGenTableRowColumn-9">
            <td className="Basic-Table CellOverride-8 _idGenCellOverride-1" colSpan={2}>
              <p className="Basic-Paragraph">
                <span className="CharOverride-5">
                  <img
                    className="_idGenObjectAttribute-3"
                    src="https://talonai.lt/images/logos/logo-dark.svg"
                    alt="talonai.lt"
                  />
                </span>
              </p>
            </td>
          </tr>
          <tr className="Basic-Table _idGenTableRowColumn-9">
            <td className="Basic-Table CellOverride-9 _idGenCellOverride-1" colSpan={2}>
              <p className="Basic-Paragraph">
                <span className="CharOverride-5">
                  Šio Talono spausdinti nereikia, galite darbuotojams rodyti telefone <br />
                  arba matyti visus savo Talonus prisijungę prie talonai.lt puslapio.
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
