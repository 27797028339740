import React from 'react'
import { Company } from '../../types/company-type'
import { CompanyListItem } from './company-item'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { List } from '@material-ui/core'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useSelector } from '../../store'
import { filterByCategory, filterCompanies } from '../../_core/utils/filter-utils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}))
type Props = {
  companies: Company[]
}
export const MapCompanyList: React.FC<Props> = ({ companies }) => {
  const classes = useStyles()
  const search = useSelector(state => state.filters.search)
  const categoryFilters = useSelector(state => state.filters.categories)
  const visibleCompanies = useSelector(state => state.map.visibleCompanies)
  return (
    <PerfectScrollbar>
      <List className={classes.root}>
        {companies
          .filter(company => visibleCompanies.indexOf(company.id) !== -1)
          .filter(company => filterCompanies(company, search))
          .filter(company => filterByCategory(company, categoryFilters))
          .map((company, index) => (
            <CompanyListItem company={company} />
          ))}
      </List>
    </PerfectScrollbar>
  )
}
