import {
  ACTION_SET_CITY,
  ACTION_SET_INITIAL_CATEGORIES,
  ACTION_SET_SEARCH,
  ACTION_UPDATE_CATEGORY_FILTERS,
} from './filters-actions'
export const KEY_CATEGORY_FILTERS = 'KEY_CATEGORY_FILTERS'
const getInitialState = () => {
  const categories = JSON.parse(window.localStorage.getItem(KEY_CATEGORY_FILTERS))
  return {
    categories: categories,
    search: '',
    city: 'Vilnius',
  }
}

export const filtersReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ACTION_UPDATE_CATEGORY_FILTERS:
      const categories = { ...state.categories, [action.name]: action.value }
      //window.localStorage.setItem(KEY_CATEGORY_FILTERS, JSON.stringify(categories))
      return {
        ...state,
        categories: categories,
      }
    case ACTION_SET_CITY:
      return {
        ...state,
        city: action.city,
      }
    case ACTION_SET_INITIAL_CATEGORIES:
      let initialCategories = null
      if (!initialCategories) {
        initialCategories = {}
        action.categories.map(cat => {
          initialCategories[cat.key] = true
        })
        //window.localStorage.setItem(KEY_CATEGORY_FILTERS, JSON.stringify(initialCategories))
        return {
          ...state,
          categories: initialCategories,
        }
      } else {
        return state
      }
    case ACTION_SET_SEARCH:
      return {
        ...state,
        search: action.search,
      }
    default:
      return state
  }
}
