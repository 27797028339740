import React from 'react'
import { useSelector } from '../store'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import { Coupon } from '../types/coupon-type'
import { ConfirmCoupon } from './coupon-card'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import { isPastDueDate } from '../_core/utils/coupon-utils'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    height: '100vh',
  },
  pastDue: {
    background: theme.palette.error.light,
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))
export const ConfirmClaim: React.FC<any> = () => {
  const params = useParams<any>()
  const classes = useStyles()
  const profile = useSelector(state => state.firebase.profile)
  const firestore = useFirestore()
  useFirestoreConnect([
    {
      collection: `companies/${profile.companyId}/coupons/`,
      doc: `${params.couponId}`,
      storeAs: 'singleCoupon',
    },
  ])
  const coupon: Coupon = useSelector(state =>
    state.firestore.ordered.singleCoupon ? state.firestore.ordered.singleCoupon[0] : null,
  )
  return (
    <div className={clsx(classes.root, isPastDueDate(coupon) ? classes.pastDue : '')}>
      {coupon && <ConfirmCoupon coupon={coupon} />}
    </div>
  )
}
