import { Button, IconButton, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import React, { useEffect, useState } from 'react'
import { useSelector } from '../../store'
import { isEmpty, isLoaded, useFirebase } from 'react-redux-firebase'
import { Redirect, useHistory } from 'react-router-dom'
import { Config } from '../../config'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { FullScreenLoader } from '../../common/full-screen-loader'
import { registerPageView } from '../../_core/analytics/analytics'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(3),
  },
  box: {
    width: '100%',
    maxWidth: '400px',
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  header: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  subHeader: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
}))
//
const getErrorText = error => {
  switch (error.code) {
    case 'auth/invalid-email':
      return 'Neteisingai suformatuotas el. pašto adresas'
    default:
      return error.message
  }
}
export const UserLogin: React.FC<any> = () => {
  const firebase = useFirebase()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const classes = useStyles()
  useEffect(() => {
    registerPageView('magic_login')
  }, [])
  const history = useHistory()
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: `${Config.baseUrl}/finish-signup`,
    handleCodeInApp: true,
  }
  const profile = useSelector(state => state.firebase.profile)
  const [email, setEmail] = useState('')
  const handleBack = () => {
    history.goBack()
  }
  const handleMagic = () => {
    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        setSuccess(true)
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email)
      })
      .catch(error => {
        setError(getErrorText(error))
        console.log('error', error)
        // Some error occurred, you can inspect the code: error.code
      })
  }
  const handleEmailChange = (value: string) => {
    setEmail(value)
    setError(null)
  }
  if (!isLoaded(profile)) return <FullScreenLoader />

  if (isLoaded(profile) && !isEmpty(profile)) {
    return <Redirect to={{ pathname: '/my-coupons' }} />
  }
  return (
    <div className={classes.root}>
      <div>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <div className={classes.content}>
        <div className={classes.box}>
          <Typography className={classes.header} variant="h1">
            MAGIŠKAS PRISIJUNGIMAS
          </Typography>
          <Typography className={classes.subHeader} variant="body1">
            (∩｀-´)⊃━☆ﾟ.*･｡ﾟ
          </Typography>

          {!success ? (
            <>
              <TextField
                error={!!error}
                helperText={error ? error : ''}
                fullWidth={true}
                type={'email'}
                name="email"
                required
                variant={'outlined'}
                label={'El. paštas'}
                onChange={(event: any) => handleEmailChange(event.target.value)}
              />
              <div className={classes.button}>
                <Button fullWidth={true} color={'primary'} variant={'contained'} onClick={() => handleMagic()}>
                  Atsiųkite man magišką nuorodą
                </Button>
              </div>
              <Typography className={classes.description} variant="body1">
                Jūs gausite elektroninį laišką su nuoroda, kurią paspaudus prisijungsite prie sistemos. Prisijungę
                galėsit matyti visus savo talonus ir užsakymus.
              </Typography>
            </>
          ) : (
            <Typography className={classes.description} variant="body1">
              Puiku! galite eiti ieškoti magiškos nuorodos šiame el. pašte: <b>{email}</b>
            </Typography>
          )}
        </div>
      </div>
    </div>
  )
}
