import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useSelector } from '../../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Coupon } from '../../types/coupon-type'
import { CouponCard } from './coupon-card'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))

export const CustomerCoupons: React.FC<any> = () => {
  const classes = useStyles()
  const auth = useSelector(state => state.firebase.auth)
  useFirestoreConnect([
    {
      where: ['email', '==', auth.email],
      collectionGroup: 'coupons',
      storeAs: 'coupons',
      orderBy: ['created', 'desc'],
    },
  ])
  const coupons: Coupon[] = useSelector(state => state.firestore.ordered.coupons)
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {coupons && coupons.map((coupon, index) => <CouponCard key={index} coupon={coupon} />)}
      </Grid>
    </div>
  )
}
