import { Card } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useSelector } from '../../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import { getExpirationText, getShareStatusText } from '../../_core/utils/coupon-utils'
import { getImageUrl, ImageType } from '../../images/image-utils'
import { Coupon, ShareStatus } from '../../types/coupon-type'
import Label from '../../_core/components/label'
import { getValueText } from '../../products/product-helper'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  avatar: {
    border: '1px solid #546e7a',
    '& img': {
      objectFit: 'scale-down',
    },
  },
})
type Props = {
  coupon: Coupon
}
export const CouponCard: React.FC<Props> = props => {
  const classes = useStyles()
  const history = useHistory()
  const { coupon } = props
  useFirestoreConnect({ collection: `companies`, doc: coupon.companyId })
  const company = useSelector(
    ({
      firestore: {
        data: { companies },
      },
    }) => companies && companies[coupon.companyId],
  )
  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Card className={classes.root}>
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar
                alt={company ? company.name : ''}
                src={company ? getImageUrl(company.logoUrl, ImageType.SMALL) : ''}
                className={classes.avatar}
              >
                R
              </Avatar>
            }
            // action={
            //   <IconButton aria-label="settings">
            //     <MoreVertIcon />
            //   </IconButton>
            // }
            title={company ? company.name : ''}
            subheader={getExpirationText(coupon)}
          />
          <CardMedia
            className={classes.media}
            image={
              coupon.product.images.length > 0 && coupon.product.images[0].url
                ? coupon.product.images[0].url
                : '/images/brand-placeholder.png'
            }
            title={coupon.product.title}
          />
          <CardContent>
            <Typography align="center" gutterBottom variant="h6">
              {coupon.product.title}
            </Typography>
            <Typography align="center" variant="h2">
              {getValueText(coupon.product)}
            </Typography>
            <Typography align="center" variant="subtitle2">
              {coupon.product.shortDescription}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            onClick={() => history.push(`/claim/${coupon.companyId}/${coupon.id}`)}
            disabled={coupon.claimed}
            size="small"
            variant={'contained'}
            color="primary"
          >
            {coupon.claimed ? 'Išnaudotas' : 'Naudoti'}
          </Button>
        </CardActions>
        {coupon.sharedWith && (
          <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography>Pasidalinta su:</Typography>
              <Typography>{coupon.sharedWith}</Typography>
            </div>
            <div>
              <Typography>statusas:</Typography>
              <Typography>
                <Label color={coupon.shareStatus === ShareStatus.ACEPTED ? 'success' : 'warning'}>
                  {getShareStatusText(coupon.shareStatus)}
                </Label>
              </Typography>
            </div>
          </CardActions>
        )}
      </Card>
    </Grid>
  )
}
