import React from 'react'
import { Coupon } from '../../../types/coupon-type'
import { getGroupedArray } from '../share-utils'
import { CircularProgress } from '@material-ui/core'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from '../../../store'
import Typography from '@material-ui/core/Typography'
import { ShareCouponCard } from '../share-coupon-card'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  info: {
    marginBottom: theme.spacing(4),
  },
}))
type Props = {}
export const SelectCouponType: React.FC<Props> = () => {
  const classes = useStyles()
  const auth = useSelector(state => state.firebase.auth)

  useFirestoreConnect([
    {
      where: ['email', '==', auth.email],
      collectionGroup: 'coupons',
      storeAs: 'coupons',
      orderBy: ['created', 'desc'],
    },
  ])
  const coupons: Coupon[] = useSelector(state => state.firestore.ordered.coupons)
  if (!coupons) return <CircularProgress />
  const groupedArray = getGroupedArray(coupons.filter(coupon => !coupon.claimed && !coupon.sharedWith))
  return (
    <div>
      <Typography className={classes.info}>
        Dalintis leidžiama po vieną nominalą vienu metu. Pasirinkite kuriuo nominalu norite dalintis
      </Typography>
      <Grid container spacing={2}>
        {groupedArray.map((group: Coupon[], index: number) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <ShareCouponCard group={group} coupon={group[0]} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
