import { firestore } from 'firebase'
import { LogEntry } from './log-entry-type'
import { Order } from './order-type'
import { Product } from './product-type'

export type Coupon = {
  email: string
  id: string
  order: Partial<Order>
  companyId: string
  claimed: boolean
  emailSent: boolean
  product: Product
  claimedDate?: firestore.Timestamp | null
  sharedWith?: string | null
  shareCode?: string | null
  content?: string | null
  shareStatus?: ShareStatus | null
  created: firestore.Timestamp
  updated: firestore.Timestamp
  log: LogEntry[]
}

export enum ShareStatus {
  PENDING = 'PENDING',
  ACEPTED = 'ACEPTED',
}
