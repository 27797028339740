import { Coupon } from '../../types/coupon-type'

export const ACTION_INCREMENT_SHARE_STEP = 'ACTION_INCREMENT_SHARE_STEP'
export const ACTION_RESET_SHARE = 'ACTION_RESET_SHARE'
export const ACTION_SELECT_COUPONS_GROUP = 'ACTION_SELECT_COUPONS_GROUP'
export const ACTION_ADD_SHARE_EMAILS = 'ACTION_ADD_SHARE_EMAILS'
export const ACTION_REMOVE_EMAIL = 'ACTION_REMOVE_EMAIL'

export const actionIncrementShareStep = (step: number) => ({
  type: ACTION_INCREMENT_SHARE_STEP,
  step,
})
export const actionSelectCouponsGroup = (group: Coupon[]) => ({
  type: ACTION_SELECT_COUPONS_GROUP,
  group,
})
export const actionAddShareUsers = (emails: string[]) => ({
  type: ACTION_ADD_SHARE_EMAILS,
  emails,
})
export const actionResetShare = () => ({
  type: ACTION_RESET_SHARE,
})
export const actionRemoveEmail = index => ({
  type: ACTION_REMOVE_EMAIL,
  index,
})
