import { Grid, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useSelector } from '../../store'
import { CompanyList } from './company-list'
import { HomeSplash } from './home-splash'
import { registerPageView } from '../../_core/analytics/analytics'
import { tr } from 'translations/translate'
import { TEXT_OFFERING_COUPONS } from 'translations/keys'
import { useHomeStyles } from './styles/style'
import { Mission } from './parts/mission'
import { Bottom } from './parts/bottom'
import { NewCompaniesList } from './new-companies'
import { FeaturedProductsList } from './featured/featured-products'

export const Home: React.FC = () => {
  const classes = useHomeStyles()
  useEffect(() => {
    registerPageView('home')
  }, [])
  const companies = useSelector(
    ({
      firestore: {
        ordered: { publicCompanies },
      },
    }) => publicCompanies && publicCompanies,
  )

  return (
    <div className={classes.root}>
      <HomeSplash />

      {companies && <NewCompaniesList companies={companies} />}
      {companies && <FeaturedProductsList companies={companies} />}
      <div style={{ marginTop: '48px' }} />
      <div id="info" className={classes.box}>
        {companies && companies.length > 0 && (
          <div className={classes.wrapper}>
            <Typography variant={'h2'} style={{ marginBottom: '16px' }}>
              Talonus siūlo
            </Typography>
            <CompanyList companies={companies} />
          </div>
        )}
        <div style={{ marginTop: '96px' }} />
        <Mission />
        <div style={{ marginTop: '48px' }} />
        <Bottom />
      </div>
    </div>
  )
}
