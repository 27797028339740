import { makeStyles } from '@material-ui/core'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { tr } from '../../translations/translate'
import { TEXT_GLOBOJA_VILNIUS_1 } from 'translations/keys'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 6),
    flexDirection: 'column',
    borderRadius: '10px',
    cursor: 'pointer',
    background: 'rgba(0,0,0, 0.3)',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  text: {
    color: '#ffffff',
    fontWeight: 500,
    fontSize: 16,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textShadow: '0px 0px 32px black',
  },
  vilniusLogo: {
    width: '100px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '60px',
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const Vilnius = props => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <a className={classes.link} href={'https://vilnius.lt'} target="_blank">
        <Typography className={classes.text} variant="body1">
          {tr(TEXT_GLOBOJA_VILNIUS_1)}
        </Typography>
        <img className={classes.vilniusLogo} alt={'Vilniaus savivaldybė'} src={'/images/vilnius.svg'} />
      </a>
    </div>
  )
}
