import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import { toCurrency } from '../../_core/utils/orders-utils'
import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Coupon } from '../../types/coupon-type'
import { useSelector } from '../../store'
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  topTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  noMargin: {
    margin: 0,
  },
  statsItem: {
    padding: 10,
  },
  stats: {
    marginBottom: theme.spacing(3),
  },
}))
export const Stats = () => {
  const classes = useStyles()
  useFirestoreConnect([
    {
      collection: `statistics`,
      doc: 'global',
      storeAs: 'stats',
    },
  ])
  const stats = useSelector(state => (state.firestore.ordered.stats ? state.firestore.ordered.stats[0] : null))
  if (!stats) return null
  return (
    <div className={classes.stats}>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={2}>
          <Paper className={classes.statsItem}>
            <div>
              <Typography variant="overline">Įmonių</Typography>
            </div>
            <div>
              <Typography variant="h3">{stats.companiesTotal}</Typography>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={2}>
          <Paper className={classes.statsItem}>
            <div>
              <Typography variant="overline">Talonų</Typography>
            </div>
            <div>
              <Typography variant="h3">{stats.couponsTotal}</Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.statsItem}>
            <div>
              <Typography variant="overline">Užsakymai</Typography>
            </div>
            <div>
              <Typography variant="h3">
                {stats.ordersApproved} / {stats.ordersTotal}
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={5}>
          <Paper className={classes.statsItem}>
            <div>
              <Typography variant="overline">Pinigėliai</Typography>
            </div>
            <div>
              <Typography variant="h3">
                {toCurrency(stats.ordersApprovedPrice)} / {toCurrency(stats.ordersTotalPrice)}
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
