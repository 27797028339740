import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Company } from '../types/company-type'
import { useSelector } from '../store'
import { FullScreenLoader } from '../common/full-screen-loader'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 3),
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  content: {
    marginTop: theme.spacing(4),
    maxWidth: 900,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  header: {
    fontSize: 18,
    marginBottom: theme.spacing(2),
  },
  subHeader: {
    fontSize: 16,
    marginBottom: theme.spacing(0.5),
  },
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  verification: {
    display: 'inline-block',
    border: '1px solid',
    borderColor: theme.palette.text.primary,
    borderRadius: 5,
    padding: 8,
    whiteSpace: 'normal',
  },
}))

export const VerifyCompany: React.FC<any> = props => {
  const classes = useStyles()
  const history = useHistory()
  const profile = useSelector(state => state.firebase.profile)
  const handleBack = () => {
    history.goBack()
  }
  return (
    <div className={classes.root}>
      <div>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.content}>
            <Typography variant="h4" className={classes.header}>
              Kodėl turiu patvirtinti įmonę?
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
              Kadangi šiuo metu atsiskaitymas vyksta tiesioginiu bankiniu pavedimu, nereikia praeiti standartinių
              patvirtinimo procedūrų norint gauti pinigus.
              <br />
              Tai leistų kam nors pasisavinti jūsų įmonės vardą ir prašyti pirkti <i>talonus</i> mokant į neteisingą
              sąskaitą. <br />
              <br />
              <br />
            </Typography>
            <Typography variant="h4" className={classes.header}>
              Kaip patvirtinti įmonę?
            </Typography>
            <Typography component="h2" className={classes.subHeader} variant="overline">
              Būdas #1 Per Facebook paskyra
            </Typography>

            <Typography variant="body1" className={classes.paragraph}>
              Šiuo būdų galite pasinaudoti jei jūsų įmonė turi oficialią Facebook paskyrą
              <br />
              1. Apsilankę mūsų facebook paskyroje parašykite žinutę <br />
              <a href="https://www.facebook.com/talonai" target="_top">
                https://www.facebook.com/talonai
              </a>
              <br />
              Nuoroda tiesiai į messenger: <br />
              <a href={'https://m.me/talonai'} target="_top">
                Eiti tiesiai į messenger
              </a>
              <br />
              2. Parašykite žinutę su šia informacija:
              <br />
              <pre className={classes.verification}>Įmonės verifikavimo kodas: {profile.companyId}</pre>
              <br />
              3. Įsitikinę, kad jūs atstovaujat būtent šiai įmonei, patvirtinsime per 24 valandas.
              <br />
            </Typography>
            <br />
            <br />
            <Typography component="h2" className={classes.subHeader} variant="overline">
              Būdas #2 Elektroniniu paštu
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
              Šiuo būdų galite pasinaudoti jei jūsų įmonė turi tinklapį ir domeną su elektroninu paštu. pvz jūsų
              svetainės adresas yra www.kepyklele.lt ir turite elektroninio pašto adresą, kuris baigiasi @kepyklele.lt
              <br />
              <br />
              1. Parašykite laišką naudodamiesi savo įmonės elektroniniu paštu adresu <b>verslas@talonai.lt</b> su šia
              informacija:
              <br />
              <pre className={classes.verification}>Įmonės verifikavimo kodas: {profile.companyId}</pre>
              <br />
              2. Įsitikinę, kad jūs atstovaujat būtent šiai įmonei, patvirtinsime per 24 valandas.
              <br />
            </Typography>
            <br />
            <br />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
