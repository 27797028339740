import { Coupon } from '../../types/coupon-type'
export const getGroupedArray = (coupons: Coupon[]): Array<Coupon[]> => {
  const groupedProducts = coupons.reduce((obj: object, coupon: Coupon) => {
    if (!obj.hasOwnProperty(coupon.product.id)) {
      obj[coupon.product.id] = []
    }
    obj[coupon.product.id].push(coupon)
    return obj
  }, {})
  let array = []
  for (let [key, productArray] of Object.entries(groupedProducts)) {
    array.push(productArray)
  }
  return array
}
