import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useSelector } from '../../store'
import Switch from '@material-ui/core/Switch'
import { TableCell } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { CategoryFilter } from '../../views/home/category-filter'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import { SearchFilter } from '../../views/home/filters/search-filter'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  topTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  noMargin: {
    margin: 0,
  },
}))

export const ControlCenterCompaniesFilter: React.FC<any> = props => {
  const { hideFeatured, setHideFeatured } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Switch
                checked={hideFeatured}
                onChange={event => setHideFeatured(event.target.checked)}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
            label="Hide featured companies"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CategoryFilter />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <SearchFilter />
        </Grid>
      </Grid>
    </div>
  )
}
