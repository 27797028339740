import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core'
import { Order } from '../../types/order-type'
import { Product } from '../../types/product-type'

const useStyles = makeStyles(() => ({
  root: {},
}))
const OrderItems: React.FC<any> = ({ order, className, ...rest }) => {
  const classes = useStyles()
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Užsakyti talonai" />
      <Divider />
      <Box minWidth={700}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nominalas</TableCell>
              <TableCell>Kodas</TableCell>
              <TableCell>Kaina</TableCell>
              <TableCell>Vertė</TableCell>
              <TableCell>Iš viso</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.products.map((item: Product) => (
              <TableRow key={item.id}>
                <TableCell>
                  {item.title} x {item.count}
                </TableCell>
                <TableCell>{item.sku}</TableCell>
                <TableCell>€{item.price}</TableCell>
                <TableCell>€{item.value}</TableCell>
                <TableCell>€{item.price * item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  )
}

OrderItems.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired,
}

export default OrderItems
