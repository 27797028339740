import {
  Button,
  colors,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import MoreIcon from '@material-ui/icons/MoreVert'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { DropEvent, useDropzone } from 'react-dropzone'
import uuid from 'uuid/v1'
import { bytesToSize } from '../utils/bytes-to-size'
import { useSnackbar } from 'notistack'
import { getErrorText } from '../utils/firebase-error-text'

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}))

export const FilesDropzone: React.FC<any> = ({ className, onFilesChanged, ...rest }) => {
  const classes = useStyles()
  const [files, setFiles] = useState([] as File[])
  const handleDrop = useCallback(acceptedFiles => {
    setFiles(prevFiles => [...prevFiles].concat(acceptedFiles))
  }, [])
  useEffect(() => {
    onFilesChanged(files)
  }, [files, onFilesChanged])
  const handleRemoveAll = () => {
    setFiles([])
  }
  const { enqueueSnackbar } = useSnackbar()
  const showError = error => {
    enqueueSnackbar(error, {
      variant: 'error',
    })
  }
  const onDropRejected = (files, event: DropEvent) => {
    showError('Failas per didelis, failas negali viršyti 10 MB')
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    onDropRejected: onDropRejected,
    maxSize: 15 * 1024 * 1024,
    accept: 'image/*',
    multiple: false,
  })

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img alt="Select file" className={classes.image} src="/images/undraw_add_file2_gvbb.svg" />
        </div>
        <div>
          <Typography gutterBottom variant="h3">
            Pasirinkite paveikslėlį
          </Typography>
          <Typography className={classes.info} color="textSecondary" variant="body1">
            Įmeskite failus čia arba spauskite ir <Link underline="always">pasirinkite</Link> iš kompiuterio
          </Typography>
        </div>
      </div>
      {files.length > 0 && (
        <>
          <List className={classes.list}>
            {files.map((file, i) => (
              <ListItem divider={i < files.length - 1} key={uuid()}>
                <ListItemIcon>
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{ variant: 'h5' }}
                  secondary={bytesToSize(file.size)}
                />
                <Tooltip title="More options">
                  <IconButton edge="end">
                    <MoreIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
          <div className={classes.actions}>
            <Button onClick={handleRemoveAll} size="small">
              Išimti failus
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

FilesDropzone.propTypes = {
  className: PropTypes.string,
}

export default FilesDropzone
