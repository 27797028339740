import { Grid, Typography } from '@material-ui/core'
import { tr } from '../../../translations/translate'
import {
  TEXT_ANSWERS_TO_FREQUENTLY_ASKED_QUESTIONS,
  TEXT_CONTACTS,
  TEXT_DO_YOU_HAVE_ANY_QUESTIONS,
  TEXT_EMAIL,
  TEXT_FAQ,
  TEXT_FOR_BUSINESS,
  TEXT_FOR_CUSTOMERS,
  TEXT_HELP_FOR_BUSINESSES,
  TEXT_I_WANT_GET_SOME_COUPONS,
  TEXT_I_WANT_TO_OFFER_COUPONS,
  TEXT_MORE_INFO,
  TEXT_MY_COUPONS,
  TEXT_SIGN_UP,
  TEXT_TUTORIAL_01,
  TEXT_TUTORIAL_02,
  TEXT_TUTORIAL_03,
  TEXT_TUTORIAL_04,
  TEXT_TUTORIAL_05,
  TEXT_TUTORIAL_06,
  TEXT_TUTORIAL_07,
  TEXT_TUTORIAL_08,
  TEXT_TUTORIAL_09,
  TEXT_TUTORIAL_11,
  TEXT_TUTORIAL_12,
  TEXT_TUTORIAL_13,
  TEXT_TUTORIAL_14,
  TEXT_TUTORIAL_15,
  TEXT_TUTORIAL_16,
  TEXT_TUTORIAL_17,
} from '../../../translations/keys'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import React from 'react'
import { useHomeStyles } from '../styles/style'

export const Bottom = () => {
  const classes = useHomeStyles()
  return (
    <div className={classes.box}>
      <div className={classes.wrapper}>
        <Grid container spacing={4}>
          <Grid item lg={4} xs={12}>
            <Typography variant="h2" className={classes.header}>
              {tr(TEXT_I_WANT_TO_OFFER_COUPONS)}
            </Typography>
            <div className={classes.emojiHand}>👇</div>
            <div className={classes.squareOutlined}>
              <Typography variant="body1" className={classes.tutorialText}>
                ☞ <Link to={'/sign-up'}>{tr(TEXT_SIGN_UP)}</Link> {tr(TEXT_TUTORIAL_01)}
                <br />
                <br />☞ {tr(TEXT_TUTORIAL_02)}
                <br />
                <br />☞ {tr(TEXT_TUTORIAL_03)}
                <br />
                <br />☞ {tr(TEXT_TUTORIAL_04)}
                <br />
                <br />☞ {tr(TEXT_TUTORIAL_05)}
                <br />
                <br />☞ {tr(TEXT_TUTORIAL_06)}
                <br />
                <br />☞ {tr(TEXT_TUTORIAL_07)}
                <br />
                <br />☞ {tr(TEXT_TUTORIAL_08)}
                <br />
                <br />☞ {tr(TEXT_TUTORIAL_09)}
              </Typography>
            </div>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Typography variant="h2" className={classes.header}>
              {tr(TEXT_I_WANT_GET_SOME_COUPONS)}
            </Typography>
            <div className={classes.emojiHand}>👇</div>
            <div className={classes.squareOutlined}>
              <Typography variant="body1" className={classes.tutorialText}>
                ☞ {tr(TEXT_TUTORIAL_11)}{' '}
                {/* <a href={'https://talonai.lt/company/asteroid-mining-company'} target="_top">
                    Pavyzdys čia
                  </a>{' '} */}
                <br /> <br />☞ {tr(TEXT_TUTORIAL_12)}
                <br /> <br />☞ {tr(TEXT_TUTORIAL_13)}
                <br /> <br />☞ {tr(TEXT_TUTORIAL_14)}
                <br />
                <br />☞ {tr(TEXT_TUTORIAL_15)}
                <br /> <br />☞ {tr(TEXT_TUTORIAL_16)} <Link to={'/my-coupons'}>{tr(TEXT_MY_COUPONS)}</Link>
                <br />
                <br />☞ {tr(TEXT_TUTORIAL_17)} ¯\_(ツ)_/¯ <br />
                #fintech
              </Typography>
            </div>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Typography variant="h2" className={classes.header}>
              {tr(TEXT_MORE_INFO)}
            </Typography>
            <div className={classes.emojiHand}>👇</div>
            <Grid container spacing={4} direction="column">
              <Grid item xs={12} lg={12}>
                <div className={classes.squareGreen}>
                  <Typography variant="h2" className={classes.headerBold}>
                    {tr(TEXT_CONTACTS)}
                  </Typography>
                  <Typography variant="h4" className={classes.listItem}>
                    {tr(TEXT_HELP_FOR_BUSINESSES)}:
                    <br />
                    {tr(TEXT_EMAIL)}: <strong>verslas@talonai.lt</strong>
                    <br />
                    <br />
                    {tr(TEXT_DO_YOU_HAVE_ANY_QUESTIONS)}:
                    <br />
                    {tr(TEXT_EMAIL)}: <strong>pagalba@talonai.lt</strong>
                    <br />
                    <br />
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} lg={12}>
                <div className={classes.squareGreen}>
                  <Typography variant="h2" className={classes.headerBold}>
                    {tr(TEXT_FAQ)}
                  </Typography>
                  <Typography variant="h4" className={classes.listItem}>
                    {tr(TEXT_ANSWERS_TO_FREQUENTLY_ASKED_QUESTIONS)}:
                  </Typography>
                  <Typography variant="h4" className={classes.listItem}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <Button
                          component={Link}
                          to={'/duk/lankytojams'}
                          fullWidth={true}
                          className={classes.infoButton}
                          variant="outlined"
                          size="large"
                        >
                          {tr(TEXT_FOR_CUSTOMERS)}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          component={Link}
                          to={'/duk/verslui'}
                          fullWidth={true}
                          className={classes.infoButton}
                          variant="outlined"
                          size="large"
                        >
                          {tr(TEXT_FOR_BUSINESS)}
                        </Button>
                      </Grid>
                    </Grid>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
