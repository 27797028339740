import React from 'react'
import { Field, withTypes } from 'react-final-form'
import CubitDatePickerAdapter from '../../forms/inputs/cubit-date-picker-adapter'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { CubitRadioGroupAdapter } from '../../forms/inputs/cubit-radio-group-adapter'
import { useSelector } from '../../store'
import { useDispatch } from 'react-redux'
import { actionUpdateFilters } from '../coupons-actions'
import { CouponFilter, CouponGroup } from '../../types/coupon/coupon-filter'
import { Button } from '@material-ui/core'
import {
  getEndOfLastMonth,
  getEndOfMonth,
  getEndOfYear,
  getStartOfLastMonth,
  getStartOfMonth,
  getStartOfYear,
} from '../../_core/utils/date-utils'

type CouponFiltersProps = {}
const useStyles = makeStyles(theme => ({
  filterLine: {
    display: 'flex',
  },
  singleFilter: {
    marginRight: 16,
  },
}))
const { Form } = withTypes<CouponFilter>()
const { FormSpy } = withTypes<CouponFilter>()
export const CouponFilters: React.FC<CouponFiltersProps> = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const filters = useSelector(state => state.coupons.filters)
  const onSubmit = values => {}
  const options = [
    {
      value: CouponGroup.ALL,
      label: 'Rodyti visus',
    },
    {
      value: CouponGroup.CLAIMED,
      label: 'Rodyti tik panaudotus',
    },
    {
      value: CouponGroup.NOT_CLAIMED,
      label: 'Rodyti tik nepanaudotus',
    },
    {
      value: CouponGroup.EXPIRED,
      label: 'Rodyti pasibaigusio galiojimo',
    },
  ]
  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        setThisMonth: (args, state, utils) => {
          utils.changeValue(state, 'periodFrom', () => getStartOfMonth())
          utils.changeValue(state, 'periodTo', () => getEndOfMonth())
        },
        setLastMonth: (args, state, utils) => {
          utils.changeValue(state, 'periodFrom', () => getStartOfLastMonth())
          utils.changeValue(state, 'periodTo', () => getEndOfLastMonth())
        },
        setThisYear: (args, state, utils) => {
          utils.changeValue(state, 'periodFrom', () => getStartOfYear())
          utils.changeValue(state, 'periodTo', () => getEndOfYear())
        },
      }}
      initialValues={{ ...filters }}
      render={({ values, handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.filterLine}>
            <div>
              <div className={classes.filterLine}>
                <Field
                  name={'periodFrom'}
                  className={classes.singleFilter}
                  component={CubitDatePickerAdapter}
                  label={'Rodyti nuo'}
                  variant="filled"
                />
                <Field
                  name={'periodTo'}
                  className={classes.singleFilter}
                  component={CubitDatePickerAdapter}
                  label={'Rodyti iki'}
                  variant="filled"
                />
              </div>
              <div className={classes.filterLine}>
                <Button
                  onClick={form.mutators.setLastMonth}
                  className={classes.singleFilter}
                  variant="outlined"
                  size={'small'}
                  color="default"
                >
                  Praėjęs mėnuo
                </Button>

                <Button
                  onClick={form.mutators.setThisMonth}
                  className={classes.singleFilter}
                  variant="outlined"
                  size={'small'}
                  color="default"
                >
                  Šis mėnuo
                </Button>
                <Button variant="outlined" onClick={form.mutators.setThisYear} size={'small'} color="default">
                  Šie metai
                </Button>
              </div>
            </div>
            <Field name={'group'} component={CubitRadioGroupAdapter} options={options} />

            <FormSpy
              subscription={{ values: true }}
              onChange={props => {
                dispatch(actionUpdateFilters(props.values))
              }}
            />
          </div>
        </form>
      )}
    />
  )
}
