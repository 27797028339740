import { Card, CardContent, CardHeader } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { Company } from '../types/company-type'
import FilesDropzone from '../_core/components/files-dropzone'

const useStyles = makeStyles(() => ({
  root: {},
}))
type CompanyImageProps = {
  company: Company
  fieldName: string
  title: string
  maxHeight: number
  className?: any
}
export const CompanyImages: React.FC<FieldRenderProps<string, HTMLInputElement>> = props => {
  const classes = useStyles()
  const fb = useFirebase()
  const firestore = useFirestore()

  const { fieldName, title, maxHeight, className, input, companyId } = props

  const onFilesChanged = files => {
    if (files.length > 0) {
      const file: File = files[0]
      const fileName = `${firestore.collection('someplace').doc().id}.${file.name.split('.').pop()}`
      fb.uploadFile(`files/${companyId}`, file, undefined, { name: fileName })
        .then(result => result.uploadTaskSnapshot.ref.getDownloadURL())
        .then(url => input.onChange(url))
    }
  }

  const handleDeleteImage = () => {
    input.onChange('')
  }

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title={title} />
      <CardContent>
        {input.value ? (
          <div>
            <img style={{ maxHeight: maxHeight }} alt={fieldName} src={input.value} />
            <Button onClick={handleDeleteImage}>Pakeisti</Button>
          </div>
        ) : (
          <FilesDropzone onFilesChanged={onFilesChanged} />
        )}
      </CardContent>
    </Card>
  )
}
