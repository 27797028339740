import { Card, CardContent, Container, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from '../store'
import { Company } from '../types/company-type'
import { CompanyDetails } from './account-details'
import { CompanyImages } from './account-images'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { GeosuggestAdapter } from '../forms/inputs/geosuggest-adapter'
import { validatorRequired } from '../_core/form-validators/validators'
import clsx from 'clsx'
import { Add, Delete, Remove } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import Label from '../_core/components/label'
import { shortenDescription } from '../_core/utils/coupon-utils'

const addLocations = (company: Company) => {
  return { ...company, locations: company.locations ?? [undefined] }
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  suggestField: { width: '60%', marginRight: theme.spacing(2) },
  locationRow: { display: 'flex', alignItems: 'center', marginBottom: theme.spacing() },
  addButton: { marginTop: theme.spacing(2) },
  deleteButton: { marginTop: theme.spacing(3) },
}))
export const Account: React.FC<any> = () => {
  // const { isOpen, close } = props
  const [files, setFiles] = useState([])
  const classes = useStyles()
  const firestore = useFirestore()
  const fb = useFirebase()
  const { enqueueSnackbar } = useSnackbar()
  const profile = useSelector(state => state.firebase.profile)
  const companyDocument = `companies/${profile.companyId}`
  useFirestoreConnect(companyDocument)
  const company: Company | undefined = useSelector(
    ({ firestore: { data } }) => data.companies && data.companies[profile.companyId],
  )
  const showSuccess = () => {
    enqueueSnackbar('Įmonės duomenys išsaugoti', {
      variant: 'success',
    })
  }
  const submitForm = values => {
    let companyValues = {
      ...values,
      coverPhotoUrl: values.coverPhotoUrl ?? '',
      logoUrl: values.logoUrl ?? '',
      updated: firebase.firestore.Timestamp.now(),
    }
    return firestore.collection(`companies`).doc(profile.companyId).update(companyValues).then(showSuccess)
  }

  const onSubmit = async values => {
    return submitForm(values)
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div>
          <Typography component="h2" gutterBottom variant="overline">
            {company ? company.name : ''}
          </Typography>
        </div>
        {company && (
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={onSubmit}
            initialValues={addLocations(company)}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.actions}>
                  <Button color="primary" type="submit" variant="contained">
                    Išsaugoti
                  </Button>
                </div>

                <Card className={classes.section}>
                  <CardContent>
                    <Typography component="h2" variant="overline" className={classes.header}>
                      <Label color={'secondary'}>Nauja!</Label> Jūsų įmonės lokacijos{' '}
                      <Label color={'secondary'}>Nauja!</Label>
                    </Typography>
                    <Typography variant={'body1'}>
                      Greitu metu planuojame įdiegti žemėlapio funkcionalumą, todėl skatiname verslus susižymėti savo
                      visus "taškus", jei norite būti atvaizduojami žemėlapyje.
                    </Typography>
                    <Typography variant={'caption'}>
                      Spauskite pridėti ir ieškokite pagal savo verslo pavadinimą arba adresą
                    </Typography>
                    <FieldArray name="locations">
                      {({ fields }) => (
                        <div>
                          {fields.map((name, index) => (
                            <div key={index} className={classes.locationRow}>
                              <div className={classes.suggestField}>
                                <Field
                                  component={GeosuggestAdapter}
                                  validate={validatorRequired}
                                  name={name}
                                  country={values.country}
                                  label={`Lokacija nr.${index + 1}`}
                                />
                              </div>
                              <IconButton className={classes.deleteButton} onClick={() => fields.remove(index)}>
                                <Delete />{' '}
                              </IconButton>
                            </div>
                          ))}
                          <Button
                            className={classes.addButton}
                            variant={'outlined'}
                            onClick={() => fields.push(undefined)}
                            startIcon={<Add />}
                          >
                            Pridėti
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </CardContent>
                </Card>
                <CompanyDetails className={classes.section} />
                <Field
                  component={CompanyImages}
                  companyId={profile.companyId}
                  name="coverPhotoUrl"
                  className={classes.section}
                  maxHeight={450}
                  fieldName={'coverPhotoUrl'}
                  title={'Įmonės cover photo (1200x400)'}
                />
                <Field
                  component={CompanyImages}
                  companyId={profile.companyId}
                  name="logoUrl"
                  className={classes.section}
                  maxHeight={200}
                  fieldName={'logoUrl'}
                  title={'Įmonės logotipas (512x512)'}
                />

                <div className={classes.actions}>
                  <Button color="primary" type="submit" variant="contained">
                    Išsaugoti
                  </Button>
                </div>
              </form>
            )}
          />
        )}
      </Container>
    </div>
  )
}
