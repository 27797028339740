import { ACTION_SET_FILTERS } from './coupons-actions'
import { CouponFilter, CouponGroup } from '../types/coupon/coupon-filter'
import { getEndOfMonth, getStartOfMonth } from '../_core/utils/date-utils'

export type CouponsState = {
  filters: CouponFilter
}
const initialState: { filters: CouponFilter } = {
  filters: {
    group: CouponGroup.ALL,
    periodFrom: getStartOfMonth(),
    periodTo: getEndOfMonth(),
  },
}

export const couponsReducer = (state = initialState, action): CouponsState => {
  switch (action.type) {
    case ACTION_SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }
    default:
      return state
  }
}
