import { Card, CardContent, Container, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Switch from '@material-ui/core/Switch'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { FullScreenLoader } from '../common/full-screen-loader'
import { useSelector } from '../store'
import { Company } from '../types/company-type'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(3),
  },

  actions: {
    marginTop: theme.spacing(3),
  },
}))
export const Settings: React.FC<any> = () => {
  const classes = useStyles()
  const fb = useFirebase()
  const profile = useSelector(state => state.firebase.profile)
  const firestore = useFirestore()
  const { enqueueSnackbar } = useSnackbar()
  const showSuccess = () => {
    enqueueSnackbar('Išsaugota sėkmingai', {
      variant: 'success',
    })
  }
  const company: Company | undefined = useSelector(
    ({ firestore: { data } }) => data.companies && data.companies[profile.companyId],
  )
  if (!company) return <FullScreenLoader />

  const handlePublicChange = event => {
    firestore
      .collection(`companies`)
      .doc(profile.companyId)
      .update({ isPublic: event.target.checked })
      .then(showSuccess)
  }
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div>
          <Typography component="h2" gutterBottom variant="overline">
            Nustatymai
          </Typography>
          <Card>
            <CardContent>
              <FormControl fullWidth={true} component="fieldset">
                <FormLabel component="legend">Įmonės matomumas</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={!!company.isPublic} onChange={handlePublicChange} name="isPublic" />}
                    label={company.isPublic ? 'Viešai matoma' : 'Privati'}
                  />
                </FormGroup>
                <FormHelperText>
                  {company.isPublic
                    ? 'Šiuo metu jūsų įmonė ir jos produktai yra pasiekiami viešai'
                    : 'Šiuo metu jūsų įmonė viešai neprieinama'}
                </FormHelperText>
              </FormControl>
            </CardContent>
          </Card>
        </div>
      </Container>
    </div>
  )
}
