import React, { useEffect } from 'react'
import { useSelector } from '../../store'
import { SelectCouponType } from './steps/step-0'
import { AddShareUsers } from './steps/step-1'
import { useDispatch } from 'react-redux'
import { actionResetShare } from './share-actions'
import makeStyles from '@material-ui/core/styles/makeStyles'
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))
const renderSwitch = step => {
  switch (step) {
    case 0:
      return <SelectCouponType />
    case 1:
      return <AddShareUsers />
    default:
      return <div>Step doesnt exist</div>
  }
}
export const ShareCoupons = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  useEffect(() => {
    dispatch(actionResetShare())
  }, [])

  const step: number = useSelector(state => state.share.step)

  return <div className={classes.root}>{renderSwitch(step)}</div>
}
