import React, { useEffect } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'

export const SignOut: React.FC<any> = () => {
  const firebase = useFirebase()
  useEffect(() => {
    firebase.logout()
  }, [firebase])
  return <Redirect to={{ pathname: '/' }} />
}
