import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Grid, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { registerPageView } from '../../_core/analytics/analytics'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 3),
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  content: {
    marginTop: theme.spacing(8),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 900,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  bigThanks: {
    fontSize: 80,
    fontWeight: 600,
    lineHeight: 1.5,
    textAlign: 'center',
    margin: '40px auto',
    color: theme.palette.primary.main,
  },
  list: {
    fontSize: 18,
    marginBottom: 24,
    '& > b': {
      display: 'block',
      marginBottom: '8px',
    },
  },
}))

export const ThankYou: React.FC<any> = props => {
  const classes = useStyles()
  const history = useHistory()
  useEffect(() => {
    registerPageView('terms_from_order')
  }, [])
  const handleBack = () => {
    history.goBack()
  }
  return (
    <div className={classes.root}>
      <div>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <Typography variant="h4" className={classes.bigThanks}>
        Sakome ačiū
      </Typography>
      <div className={classes.content}>
        <Typography className={classes.list}>
          <b>Karoliui Žukauskui kartu su Vilniaus miesto savivaldybe</b>
          Suteikė projektui matomumą, priėmė globoti suteikdami reikalingus resursus ir konsultacijas, ačiū jiem.
        </Typography>
        <Typography className={classes.list}>
          <b>Vyčiui Radvilai</b>
          Vytis ėmėsi mūsų globos Klaipėdoje, ačiū jam.
        </Typography>
        <Typography className={classes.list}>
          <b>Andriui Pelakauskui</b>
          Andrius leido naudotis savo fotografijomis{' '}
          <Link to={'https://talonai.lt/klaipeda'}>https://talonai.lt/klaipeda</Link> puslapyje, ačiū jam.
        </Typography>
        <Typography className={classes.list}>
          <b>Audriui Tamulevičiui</b>
          Audrius atlieka sunkiausias technologines užduotis, dirba naktimis, ačiū jam.
        </Typography>
        <Typography className={classes.list}>
          <b>Dagnei Žvinklytei</b>
          Dagnė yra programuotojų komandos dalis, atlieka ne mažiau sunkias užduotis nei Audrius, ačiū jai.
        </Typography>
        <Typography className={classes.list}>
          <b>Ligitai Baleišytei</b>
          Ligita skleidžia žinią apie projektą visoje Lietuvoje, jos dėka prisijungė daug naujų verslų, ačiū jai.
        </Typography>
        <Typography className={classes.list}>
          <b>Jonui Vėgėlei</b>
          Jonas sukūrė logo ir padeda spręsti mūsų dizaino problemas, ačiū jam.
        </Typography>
      </div>
    </div>
  )
}
