import React from 'react'
import { InfoDialog } from '../_core/components/info-dialog'
import { Typography } from '@material-ui/core'

type Tutorial = {}
export const UsersTutorial: React.FC<Tutorial> = () => {
  return (
    <InfoDialog title={'Kas tie darbuotojai? ¯\\_(ツ)_/¯'}>
      <Typography variant="body1">
        Darbuotojus galite pakviesti naudodamiesi mygtuku <b>pridėti darbuotoją</b>. Taip bus išsiųstas pakvietimas
        užsiregistruoti sistemoje kaip jūsų įmonės darbuotojui.
        <br /> Šie vartotojai negali matyti ar redaguoti užsakymų, nominalų, talonų. Paprastai tariant, jie įgauna teisę
        <br /> Šie vartotojai negali matyti ar redaguoti užsakymų, nominalų, talonų. Paprastai tariant, jie įgauna teisę
        patvirtinti nuskenuotus talonus, kuomet klientas prodo QR kodą.
        <br />
        <br />
        Pakviestus darbuotojus ar pačius pakvietimus galite betkada ištrinti taip panaikindamį viršuje apibūdintą
        prieigą
      </Typography>
    </InfoDialog>
  )
}
