import { Card, CardContent, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Field } from 'react-final-form'
import { SelectFieldAdapter } from '../forms/inputs/select-field-adapter'
import { TextFieldAdapter } from '../forms/inputs/text-field-adapter'
import {
  composeValidators,
  validatorEmail,
  validatorMaxLength,
  validatorRequired,
} from '../_core/form-validators/validators'
import { countries } from '../_core/utils/countries'
import Grid from '@material-ui/core/Grid'
import { EditorField } from '../forms/inputs/editor-field-adapter'
import { Condition } from '../products/components/product-details'
import { CheckboxFieldAdapter } from '../forms/inputs/checkbox-field-adapter'
import { useSelector } from '../store'
import { useFirestore } from 'react-redux-firebase'

const validatorUrl = (value: string) =>
  !value || value.startsWith('http://') || value.startsWith('https://')
    ? undefined
    : 'Puslapio adresas turi prasidėti "http://" arba "https://"'

const useStyles = makeStyles(() => ({
  root: {},
  formSection: {
    margin: '0px 0 20px 0',
  },
  field: {
    margin: '0px 0 12px 0',
  },
  fieldWithHelper: {
    margin: '0px 0 20px 0',
  },
}))

export const CompanyDetails: React.FC<any> = props => {
  const classes = useStyles()
  const { className } = props
  const firestore = useFirestore()
  useEffect(() => {
    firestore.get({
      collection: 'filters',
      doc: 'categories',
      storeAs: 'categories',
    })
  }, [firestore])
  const filters = useSelector(
    ({
      firestore: {
        ordered: { categories },
      },
    }) => categories && categories[0].list,
  )
  let categories = []
  if (filters) {
    categories = filters.map(category => ({ label: category.value_lt, value: category.key }))
  }
  return (
    <>
      <Card className={clsx(classes.root, className)}>
        <CardContent>
          <Typography component="h2" variant="overline" className={classes.formSection}>
            Bendra įmonės informacija
          </Typography>
          <div className={classes.fieldWithHelper}>
            <Field
              name="name"
              validate={validatorRequired}
              component={TextFieldAdapter}
              label="Pavadinimas"
              explanation={'Nurodykite verslo pavadinimą be juridinio asmens priedų tokių kaip UAB '}
            />
          </div>
          <div className={classes.field}>
            <Field
              name="category"
              validate={validatorRequired}
              component={SelectFieldAdapter}
              items={categories}
              label="Kategorija"
            />
          </div>
          <div className={classes.field}>
            <Field
              name="website"
              validate={validatorUrl}
              component={TextFieldAdapter}
              label="Įmonės ar Facebook puslapis"
              placeholder={'https://imone.lt'}
            />
          </div>
          <div className={classes.field}>
            <Field
              name="email"
              validate={composeValidators(validatorRequired, validatorEmail)}
              component={TextFieldAdapter}
              label="El. paštas"
            />
          </div>
          <div className={classes.field}>
            <Field name="phone" validate={validatorRequired} component={TextFieldAdapter} label="Tel. nr." />
          </div>
          <div className={classes.fieldWithHelper}>
            <Field
              validate={composeValidators(validatorMaxLength(120), validatorRequired)}
              name="shortDescription"
              component={TextFieldAdapter}
              label="Trumpas aprašymas"
              explanation={'Matomas kai įmonė rodoma sąrašuose'}
            />
          </div>
          <div className={classes.fieldWithHelper}>
            <Field
              name="description"
              component={EditorField}
              label="Aprašymas matomas pagrindiniame įmonės puslapyje"
            />
          </div>
        </CardContent>
      </Card>
      <Card className={clsx(classes.root, className)}>
        <CardContent>
          <Typography component="h2" variant="overline" className={classes.formSection}>
            Mokėjimo informacija
          </Typography>

          <div className={classes.fieldWithHelper}>
            <Field
              validate={validatorRequired}
              name="businessName"
              component={TextFieldAdapter}
              placeholder={'Asteroid Mining Company, UAB'}
              explanation={'Nurodykite pilną juridinio asmens pavadinimą reikalingą bankiniam pavedimui atlikti'}
              label="Juridinio asmens pavadinimas"
            />
          </div>
          <div className={classes.field}>
            <Field
              name="accountNumber"
              validate={validatorRequired}
              component={TextFieldAdapter}
              label="Sąskaitos numeris"
            />
          </div>
          <div className={classes.field}>
            <Field
              name="vatPayer"
              component={CheckboxFieldAdapter}
              label="Įmonė yra PVM mokėtojas"
              type="checkbox"
              variant="outlined"
            />
          </div>
          <Condition when="vatPayer" is={true}>
            <div className={classes.field}>
              <Grid container spacing={2}>
                <Grid item>
                  <Field name="vatNumber" fullWidth={true} component={TextFieldAdapter} label="PVM mokėtojo kodas" />
                </Grid>
                <Grid item>
                  <Field name="vat" defaultValue={21} component={TextFieldAdapter} label="PVM dydis %" />
                </Grid>
              </Grid>
            </div>
          </Condition>
          <div className={classes.field}>
            <Field name="orgNumber" validate={validatorRequired} component={TextFieldAdapter} label="Įmonės kodas" />
          </div>
        </CardContent>
      </Card>
      <Card className={clsx(classes.root, className)}>
        <CardContent>
          <Typography component="h2" variant="overline" className={classes.formSection}>
            Įmonės registracijos adresas
          </Typography>
          <div className={classes.field}>
            <Field
              name="country"
              validate={validatorRequired}
              component={SelectFieldAdapter}
              items={countries}
              label="Šalis"
            />
          </div>
          <div className={classes.field}>
            <Field
              name="city"
              defaultValue={'Vilnius'}
              validate={validatorRequired}
              component={TextFieldAdapter}
              label="Miestas"
            />
          </div>
          <Field
            className={classes.field}
            name="streetAddress"
            validate={validatorRequired}
            component={TextFieldAdapter}
            label="Adresas"
          />
          <div>
            <Field name="postalCode" validate={validatorRequired} component={TextFieldAdapter} label="Pašto kodas" />
          </div>
        </CardContent>
      </Card>
    </>
  )
}

CompanyDetails.propTypes = {
  className: PropTypes.string,
}
