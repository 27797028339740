import {
  Card,
  CardActions,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Label } from '../../_core/components/label'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useSnackbar } from 'notistack'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from '../../store'
import { Company } from '../../types/company-type'
import { Product } from '../../types/product-type'
import Switch from '@material-ui/core/Switch'
import { getImageUrl, ImageType } from '../../images/image-utils'
import FormControl from '@material-ui/core/FormControl'
import { formatDate } from '../../_core/utils/date-utils'
import IconButton from '@material-ui/core/IconButton'

import { MoreInfoDialog } from './more-info-dialog'
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  clickableRow: {
    cursor: 'pointer',
  },
  private: {
    background: '#aaaaaa',
  },
  categories: {
    width: 200,
  },
}))
const getProductCount = (company, allProducts) => {
  let products
  if (allProducts) {
    products = allProducts.filter((p: Product) => p.companyId === company.id)
  }
  if (!products) {
    return '0/0'
  }
  return `${products.filter((p: Product) => p.isPublished).length}/${products.length}`
}
const CategorySelect = ({ company, showSuccess }) => {
  const classes = useStyles()
  const firestore = useFirestore()
  useFirestoreConnect([
    {
      collection: 'filters',
      doc: 'categories',
      storeAs: 'categories',
    },
  ])
  const filters = useSelector(
    ({
      firestore: {
        ordered: { categories },
      },
    }) => categories && categories[0].list,
  )

  const handleChange = (event, company) => {
    const itemUpdates = {
      category: event.target.value,
    }
    firestore.collection(`companies`).doc(company.id).update(itemUpdates).then(showSuccess)
  }
  return filters ? (
    <div className={classes.categories}>
      <FormControl fullWidth variant={'outlined'}>
        <InputLabel htmlFor="demo-simple-select-label">Category</InputLabel>
        <Select
          fullWidth={true}
          label={'Category'}
          inputProps={{
            name: 'demo',
            id: 'demo-simple-select-label',
          }}
          onChange={event => handleChange(event, company)}
          value={company.category}
        >
          {filters.map(x => (
            <MenuItem key={x.key} value={x.key}>
              {x.value_lt}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  ) : null
}
export const CompaniesTable = props => {
  const { className, companies, products, ...rest } = props

  const classes = useStyles()
  const history = useHistory()
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const [selectedItems, setSelectedItems] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handlePageChange = (event, page) => {
    setPage(page)
  }

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value)
  }
  const { enqueueSnackbar } = useSnackbar()
  const showSuccess = () => {
    enqueueSnackbar('Išsaugota', {
      variant: 'success',
    })
  }

  const featuredChange = (event, company) => {
    const itemUpdates = {
      featured: event.target.checked,
    }
    firestore.collection(`companies`).doc(company.id).update(itemUpdates).then(showSuccess)
  }
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Logo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Filters</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Business name</TableCell>
                <TableCell>
                  <div>Product count</div>
                  <div>
                    <Typography variant={'caption'}>Published/Total</Typography>
                  </div>
                </TableCell>
                <TableCell>Featured?</TableCell>
                <TableCell>Veiksmai</TableCell>
                <TableCell>Order</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((company: Company) => (
                <TableRow
                  className={clsx(classes.clickableRow, !company.isPublic ? classes.private : '')}
                  hover
                  key={company.id}
                  selected={selectedItems.indexOf(company.id) !== -1}
                >
                  <TableCell>
                    <a href={`/company/${company.alias}`} target={'_blank'}>
                      <img
                        style={{ width: 75 }}
                        alt={company.name}
                        src={getImageUrl(company.logoUrl, ImageType.THUMB)}
                      />
                    </a>
                  </TableCell>
                  <TableCell>
                    <div>
                      <Typography variant="body1">{company.name}</Typography>
                    </div>
                    <div>
                      <Typography variant="caption">{company.shortDescription}</Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    <CategorySelect company={company} showSuccess={showSuccess} />{' '}
                  </TableCell>
                  <TableCell>{company.email}</TableCell>
                  <TableCell>
                    {company.businessName}
                    <div>
                      <Typography variant="caption">{formatDate(company.created)}</Typography>
                    </div>
                  </TableCell>

                  <TableCell>{getProductCount(company, products)}</TableCell>
                  <TableCell>
                    <Label color={company.featured ? 'success' : 'error'}>{company.featured ? 'Yes' : 'No'}</Label>
                  </TableCell>
                  <TableCell onClick={event => event.stopPropagation()}>
                    <Switch
                      checked={company.featured}
                      onChange={event => featuredChange(event, company)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <MoreInfoDialog company={company} />
                  </TableCell>
                  <TableCell>{company.order}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={companies.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  )
}
