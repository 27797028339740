import React from 'react'
import Geosuggest, { Suggest } from 'react-geosuggest'
import './geosuggest-adapter.css'
import { GeoLocation } from '../../types/location-type'
import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
  },
}))
export const GeosuggestAdapter: React.FC<any> = props => {
  const {
    input,
    field,
    label,
    style,
    variant,
    meta: { touched, error },
    country,
    ...rest
  } = props
  const classes = useStyles()
  const getAddressComponent = (suggest: Suggest, type: string): string => {
    if (!suggest.gmaps) return ''
    const country = suggest.gmaps.address_components.find(component => {
      if (component.types.indexOf(type) !== -1) {
        return component
      }
    })
    if (country) {
      return country.short_name
    }
    return ''
  }
  const handleSuggestSelect = (suggest: Suggest) => {
    if (suggest) {
      const address = suggest.gmaps?.formatted_address ?? suggest.label
      const loc: { lat: number; lng: number } = suggest.location

      const location: GeoLocation = {
        ...loc,
        name: suggest.label,
        address,
        city: getAddressComponent(suggest, 'locality'),
        types: suggest.gmaps?.types,
        // @ts-ignore
        icon: suggest.gmaps?.icon ?? '',
        // @ts-ignore
        rating: suggest.gmaps?.rating ?? 0,
        // @ts-ignore
        priceLevel: suggest.gmaps?.price_level ?? 0,
        // @ts-ignore
        phone: suggest.gmaps?.international_phone_number ?? '',
        // @ts-ignore
        userRatingTotal: suggest.gmaps?.user_ratings_total ?? '',
        country: getAddressComponent(suggest, 'country'),
      }
      input.onChange(location)
    }
  }

  const handleChange = () => {
    // all changes should reset input value, only suggest select should set value to input
    input.onChange(undefined)
  }

  return (
    <div>
      <Geosuggest
        initialValue={input.value.name}
        id="geoSuggest"
        label={label}
        country={country ?? 'LT'}
        queryDelay={250}
        placeholder=""
        onSuggestSelect={handleSuggestSelect}
        onChange={handleChange}
        autoComplete="off"
        {...rest}
      />
      {touched && error ? (
        <Typography variant={'caption'} className={classes.error}>
          {error}
        </Typography>
      ) : null}
    </div>
  )
}
