import { InputLabel, MenuItem, Select } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import React from 'react'
import { InputType } from './input-type.enum'

export type SelectFieldAdapterProps = { type: InputType.TextField } // type props are required to differenciate between different field adapters

export const SelectFieldAdapter: React.FC<any> = (props: any) => {
  const {
    input: { name, value, onChange },
    meta: { touched, error },
    fieldType,
    label,
    items,
    ...rest
  } = props

  return (
    <FormControl fullWidth variant={'outlined'}>
      <InputLabel htmlFor="demo-simple-select-label">{label}</InputLabel>
      <Select
        error={touched && error ? true : false}
        name={name}
        label={label}
        inputProps={{
          name: 'demo',
          id: 'demo-simple-select-label',
        }}
        onChange={(event: any) => onChange(event.target.value)}
        value={value}
        {...rest}
      >
        {items.map(x => (
          <MenuItem key={x.value} value={x.value}>
            {x.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
