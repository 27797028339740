import { Button } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState } from 'react'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from '../../store'
import { Order } from '../../types/order-type'
import { OrderStatus } from '../../types/order/order-status.enum'
import { Product } from '../../types/product-type'
import { filterByCategory, filterCompanies } from '../../_core/utils/filter-utils'
import { Stats } from '../stats/stats'
import { ControlCenterCompaniesFilter } from './companies-filters'
import { CompaniesTable } from './companies-table'
import { Company } from '../../types/company-type'
import Feature from 'ol/Feature'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  topTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  noMargin: {
    margin: 0,
  },
}))
type ControlCenterCompaniesType = {}
export const ControlCenterCompanies: React.FC<ControlCenterCompaniesType> = props => {
  const classes = useStyles()
  const firestore = useFirestore()
  const [done, setDone] = useState(false)
  const [hideFeatured, setHideFeatured] = useState(false)
  useFirestoreConnect([
    {
      collection: 'companies',
      storeAs: 'myCompanies',
    },
  ])

  const companies: Company[] = useSelector(
    ({
      firestore: {
        ordered: { myCompanies },
      },
    }) => myCompanies && myCompanies,
  )
  const search = useSelector(state => state.filters.search)
  const categoryFilters = useSelector(state => state.filters.categories)

  useFirestoreConnect([
    {
      collectionGroup: 'products',
      storeAs: 'products',
    },
  ])
  const products: Product[] = useSelector(state => state.firestore.ordered.products)

  const handleReorderCompanies = () => {
    firestore
      .collectionGroup('orders')
      .where('status', '==', OrderStatus.FINISHED)
      .get()
      .then(orders => {
        const companiesPriceMap = orders.docs.reduce<Record<string, number>>((result, orderSnapshot) => {
          const order = orderSnapshot.data() as Order
          result[order.companyId] = (result[order.companyId] ?? 0) + order.price
          return result
        }, {})
        console.log(companiesPriceMap)
        const companiesPriceArray = Object.entries(companiesPriceMap).sort((a, b) => a[1] - b[1])
        console.log(companiesPriceArray)
        const companiesOrderIndexArray = companiesPriceArray.map((entry, index) => [entry[0], index])
        console.log(companiesOrderIndexArray)
        const updates = companies.map(company =>
          firestore.doc(`companies/${company.id}`).update({
            order: companiesOrderIndexArray.find(x => x[0] === company.id)?.[1] ?? 0,
          }),
        )
        Promise.all(updates)
      })
  }
  let sorted = []
  if (companies) {
    sorted = companies.slice().sort((a, b) => {
      if (!a.created) return 1
      if (!b.created) return -1
      if (a.created.toMillis() > b.created.toMillis()) {
        return -1
      } else {
        return 1
      }
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Stats />
        <ControlCenterCompaniesFilter hideFeatured={hideFeatured} setHideFeatured={setHideFeatured} />
        <div>
          {sorted && (
            <CompaniesTable
              companies={sorted
                .filter(company => filterCompanies(company, search))
                .filter(company => filterByCategory(company, categoryFilters))
                .filter(c => {
                  if (hideFeatured) {
                    return c.featured === false
                  } else {
                    return true
                  }
                })}
              products={products}
            />
          )}
        </div>
        <Button onClick={handleReorderCompanies}>Reorder companies</Button>
      </div>
    </div>
  )
}
