import draftToHtml from 'draftjs-to-html'
import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Typography } from '@material-ui/core'
import { FieldRenderProps } from 'react-final-form'

const useStyles = makeStyles(() => ({
  editor: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    position: 'relative',
  },
  label: {
    padding: '2px 4px',
    fontSize: 12,
    position: 'absolute',
    background: '#ffffff',
    top: -14,
    left: 10,
  },
}))

const toolbar = {
  options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
}
export const EditorField: React.FC<FieldRenderProps<any, HTMLInputElement>> = ({ input, label }) => {
  const classes = useStyles()
  const value = input.value
    ? input.value
    : {
        blocks: [],
        entityMap: {},
      }

  return (
    <div className={classes.editor}>
      <Typography className={classes.label}>{label}</Typography>
      <Editor
        stripPastedStyles={true}
        toolbar={toolbar}
        initialContentState={value}
        wrapperClassName="demo-wrapper"
        editorClassName="editor"
        onContentStateChange={input.onChange}
      />
    </div>
  )
}
export const EditorPreview: React.FC<any> = ({ value }) => {
  const markup = draftToHtml(value)
  return <div className={'editor-preview'} dangerouslySetInnerHTML={{ __html: markup }} />
}
