import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useSelector } from '../store'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Invite, User } from '../types/user-type'
import { UsersTable } from './components/users-table'
import { UsersToolbar } from './components/users-toolbar'
import { Typography } from '@material-ui/core'
import { UsersTutorial } from './users-tutorial'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  topTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  noMargin: {
    margin: 0,
  },
}))

export const Users: React.FC = () => {
  const profile = useSelector(state => state.firebase.profile)
  const classes = useStyles()
  const companyId = profile?.companyId ?? '_'

  const collection = `users`
  useFirestoreConnect([{ collection: collection, where: ['companyId', '==', companyId], orderBy: ['email', 'asc'] }])
  const users: User[] = useSelector(state => state.firestore.ordered[collection])

  const invitesCollection = `companies/${companyId}/invites`
  useFirestoreConnect([{ collection: invitesCollection, orderBy: ['email', 'asc'] }])
  const invites: Invite[] = useSelector(state => state.firestore.ordered[invitesCollection])

  return (
    <div className={classes.root}>
      <UsersToolbar />
      <div className={classes.topTitle}>
        <Typography className={classes.noMargin} component="h2" gutterBottom variant="overline">
          Registruoti darbuotojai
        </Typography>
        <UsersTutorial />
      </div>
      <div className={classes.content}>{users && <UsersTable users={users} />}</div>
      <div>
        <Typography component="h2" gutterBottom variant="overline">
          Išsiųsti pakvietimai
        </Typography>
      </div>
      <div className={classes.content}>{invites && <UsersTable users={invites} />}</div>
    </div>
  )
}
