import { CircularProgress, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import { getErrorText } from '../../_core/utils/firebase-error-text'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(3),
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  subHeader: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  error: {
    color: theme.palette.error.main,
    textAlign: 'center',
  },
}))
export const FinishLogin: React.FC<any> = () => {
  const firebase = useFirebase()
  const firestore = useFirestore()
  const history = useHistory()
  const classes = useStyles()
  const [error, setError] = useState(null)
  useEffect(() => {
    const finish = () => {
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        var email = window.localStorage.getItem('emailForSignIn')
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt('Please provide your email for confirmation')
        }
        // The client SDK will parse the code from the link for you.
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(function (user) {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn')
            const unsubscribe = firestore.doc(`users/${user.user.uid}`).onSnapshot(snapshot => {
              if (snapshot.exists) {
                unsubscribe()
                history.push('/my-coupons')
              }
            })
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
          })
          .catch(function (error) {
            setError(getErrorText(error))
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          })
      }
    }

    finish()
  }, [firebase, history])
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>
          <Typography className={classes.subHeader} variant="h2">
            (๑˃ᴗ˂)━☆
          </Typography>
          {error ? (
            <Typography className={classes.error} variant="body1">
              {error}
            </Typography>
          ) : (
            <CircularProgress color="secondary" />
          )}
        </div>
      </div>
    </div>
  )
}
