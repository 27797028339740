import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { getRandomInt } from '_core/utils/random-int'
import { Vilnius } from './globoja-vilnius'
import { tr } from 'translations/translate'
import { TEXT_FEELING_IS_MUTUAL, TEXT_FOLLOW_US, TEXT_HOME_SPLASH_1 } from 'translations/keys'
import FacebookIcon from '@material-ui/icons/Facebook'
import IconButton from '@material-ui/core/IconButton'
import { SplashTop } from './splash/splash-top'

type AppImage = {
  src: string
}

const HomeSplashImages: AppImage[] = [
  { src: '../images/home-splash/hero-1-min.jpg' },
  { src: '../images/home-splash/hero-2-min.jpg' },
  { src: '../images/home-splash/hero-3-min.jpg' },
  { src: '../images/home-splash/hero-4-min.jpg' },
  { src: '../images/home-splash/hero-5-min.jpg' },
  { src: '../images/home-splash/hero-6-min.jpg' },
  // aclohol displayed here, against the law  { src: '../images/home-splash/hero-7-min.jpg' },
  { src: '../images/home-splash/hero-8-min.jpg' },
]
const splashImageIndex: number = getRandomInt(7)
const heroImageUrl: string = HomeSplashImages[splashImageIndex].src

const useStyles = makeStyles(theme => ({
  homeSplash: {
    backgroundImage: `url(${heroImageUrl})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    height: '100vh',
    minHeight: '445px',
    padding: theme.spacing(3),
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
  },
  fullHeight: {
    height: '100%',
  },

  homeSplashTextContainer: {
    cursor: 'default',
    userSelect: 'none',
    flexGrow: 1,
    textAlign: 'center',
    transform: 'translateZ(0)',
    textShadow: '0px 0px 32px black',
  },

  heroHeader: {
    maxWidth: '1000px',
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(1),
    margin: '0 auto',
    color: 'white',
    fontSize: '100px',
    lineHeight: 1,
    letterSpacing: '-1px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '600px',
      fontSize: '56px',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
      marginBottom: theme.spacing(3),
    },
  },
  start: {
    margin: '0 auto 0 0',
  },

  subHeader: {
    margin: '0 auto',
    color: 'white',
    fontWeight: 700,
    maxWidth: '600px',
    fontSize: '48px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '28px',
    },
  },
  emoji: {
    margin: '40px auto 0 auto',
    fontSize: '32px',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '16px auto 0 auto',
      fontSize: '16px',
    },
  },
  down: {
    display: 'block',
    margin: '40px auto 40px auto',
  },
  vilnius: {
    marginTop: theme.spacing(5),
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  emojiDown: {
    //transform: 'rotate(90deg)',
    fontSize: '48px',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
  },
  follow: {
    marginTop: theme.spacing(1),
    color: '#ffffff',
    fontWeight: 500,
    fontSize: 18,
    textShadow: '0px 0px 32px black',
  },
  followIcon: {
    fontSize: 58,
    [theme.breakpoints.down('sm')]: {
      fontSize: 48,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
}))

export const HomeSplash: React.FC = () => {
  const classes = useStyles()

  return (
    <Grid container direction="column" className={classes.homeSplash}>
      <SplashTop />

      <Grid item className={classes.homeSplashTextContainer}>
        <Grid container alignItems="center" justify="center" className={classes.fullHeight}>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.heroHeader}>
              {tr(TEXT_HOME_SPLASH_1)}
            </Typography>
            <Typography variant="h3" className={classes.subHeader}>
              {tr(TEXT_FEELING_IS_MUTUAL)}
            </Typography>
            <Typography variant="h5" className={classes.emoji}>
              (๑˘ᴗ˘)(˘ᴗ˘๑)
            </Typography>
            <div className={classes.vilnius}>
              <Vilnius />
              <div>
                <Typography className={classes.follow}>
                  <div>{tr(TEXT_FOLLOW_US)}</div>
                  <IconButton component={'a'} href={'https://www.facebook.com/talonai'} target={'_blank'}>
                    <FacebookIcon className={classes.followIcon} htmlColor={'#ffffff'} />
                  </IconButton>
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container alignItems="flex-end" justify="center">
          <Grid item>
            <a href="#info" className={`heartbeat ${classes.down}`}>
              <Typography variant="h5" className={classes.emojiDown}>
                👇
              </Typography>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
