import { OrderData } from 'app/app-reducer'

export const COUNT_ORDER_UPDATE = 'COUNT_ORDER_UPDATE'
export const COUNT_ORDER_RESET = 'COUNT_ORDER_RESET'

export const countOrderUpdateAction = (order: OrderData, companyId: string) => ({
  type: COUNT_ORDER_UPDATE,
  order: order,
  companyId,
})

export const countOrderResetAction = () => ({
  type: COUNT_ORDER_RESET,
})
