import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { toCurrency } from '../../_core/utils/orders-utils'
import { getCouponHumanId, getExpiration, getExpirationDate } from '../../_core/utils/coupon-utils'
import { formatDate } from '../../_core/utils/date-utils'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Label from '../../_core/components/label'
import { CloudDownload, Delete } from '@material-ui/icons'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from '../../store'
import { useSnackbar } from 'notistack'
import { ConfirmDialog } from '../../_core/components/confirm-dialog'
import { CouponGroup } from '../../types/coupon/coupon-filter'
import { Coupon } from '../../types/coupon-type'
import { CSVLink } from 'react-csv'
import { getExportData, getExportFileName } from '../coupons-helper'
import { AuthContext, AuthProvider } from 'auth/auth-provider'
import { getValueText } from '../../products/product-helper'
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  warning: {
    fontWeight: 500,
    color: theme.palette.error.dark,
  },
  searchField: {
    maxWidth: 400,
  },
  exportButton: {
    '& a': {
      color: '#263238',
    },
  },
}))

export const CouponsTable = props => {
  const { className, coupons, ...rest } = props
  const firestore = useFirestore()
  const [search, setSearch] = React.useState('')
  const profile = useSelector(state => state.firebase.profile)
  const filters = useSelector(state => state.coupons.filters)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (page !== 0) {
      setPage(0)
    }
  }, [filters])
  const handlePageChange = (event, page) => {
    setPage(page)
  }
  const handleCouponDelete = coupon => {
    firestore
      .collection(`companies/${profile.companyId}/coupons`)
      .doc(coupon.id)
      .delete()
      .then(_ => {
        enqueueSnackbar('Talonas ištrintas', {
          variant: 'success',
        })
      })
      .catch(reason => {
        enqueueSnackbar(reason.code, {
          variant: 'error',
        })
      })
  }
  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value)
  }
  const contains = (value, search) =>
    value ? value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 : false
  const searchFilter = (coupon: Coupon) => {
    if (search) {
      const words = search.split(' ')
      return words.every(
        word => contains(coupon.email, word) || contains(coupon.id, word) || contains(coupon.product.sku, word),
      )
    }
    return true
  }
  const couponFilter = (coupon: Coupon) => {
    switch (filters.group) {
      case CouponGroup.ALL:
        return (
          coupon.created.toDate() >= filters.periodFrom.toDate() && coupon.created.toDate() <= filters.periodTo.toDate()
        )
      case CouponGroup.CLAIMED: {
        if (coupon.claimed && coupon.claimedDate !== null) {
          return (
            coupon.claimedDate.toDate() >= filters.periodFrom.toDate() &&
            coupon.claimedDate.toDate() <= filters.periodTo.toDate()
          )
        }
        return false
      }
      case CouponGroup.NOT_CLAIMED: {
        if (!coupon.claimed) {
          return (
            coupon.created.toDate() >= filters.periodFrom.toDate() &&
            coupon.created.toDate() <= filters.periodTo.toDate()
          )
        }
        return false
      }
      case CouponGroup.EXPIRED: {
        if (coupon.product.hasExpirationDate && !coupon.claimed) {
          const date = getExpiration(coupon)
          return date >= filters.periodFrom.toDate() && date <= filters.periodTo.toDate()
        }
        return false
      }
    }

    return true
  }

  const handleSetText = t => {
    setSearch(t)
  }

  const filteredCoupons = coupons.filter(couponFilter).filter(searchFilter)
  return (
    <div>
      <div className={classes.topBar}>
        <TextField
          className={classes.searchField}
          label={'Paieška'}
          fullWidth
          variant="outlined"
          value={search}
          onChange={e => handleSetText(e.target.value)}
        />
        <Button variant="outlined" className={classes.exportButton} startIcon={<CloudDownload />}>
          <CSVLink
            data={getExportData(filteredCoupons)}
            filename={getExportFileName(filters)}
            className="btn btn-primary"
            target="_blank"
          >
            Eksportuoti
          </CSVLink>
        </Button>
      </div>

      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Užsakymo Nr.</TableCell>
                    <TableCell>Talono id</TableCell>
                    <TableCell>Nominalo kodas</TableCell>
                    <TableCell>El. paštas</TableCell>
                    <TableCell>Vertė</TableCell>
                    <TableCell>Išleistas</TableCell>
                    <TableCell>Sukurtas</TableCell>
                    <TableCell>Galioja iki</TableCell>
                    <TableCell>Veiksmai</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCoupons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(coupon => (
                    <TableRow hover key={coupon.id}>
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Typography variant="body1">
                            {coupon.order ? '#' + coupon.order.number : 'be užsakymo'}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>{getCouponHumanId(coupon)}</TableCell>
                      <TableCell>{coupon.product.sku}</TableCell>
                      <TableCell>{coupon.email}</TableCell>
                      <TableCell>{getValueText(coupon.product)}</TableCell>
                      <TableCell>
                        {coupon.claimed ? (
                          <div>
                            <Label color={'success'}>Taip</Label> ({formatDate(coupon.claimedDate)})
                          </div>
                        ) : (
                          <Label color={'error'}>Ne</Label>
                        )}
                      </TableCell>
                      <TableCell>{formatDate(coupon.created)}</TableCell>
                      <TableCell>{getExpirationDate(coupon)}</TableCell>
                      <TableCell>
                        <AuthContext.Consumer>
                          {value => (
                            <Link
                              download={true}
                              onClick={() =>
                                enqueueSnackbar('Talonas generuojamas', {
                                  variant: 'success',
                                })
                              }
                              href={`/couponPdf?companyId=${coupon.companyId}&couponId=${coupon.id}&idToken=${value.idToken}`}
                            >
                              <IconButton>
                                <CloudDownload />
                              </IconButton>
                            </Link>
                          )}
                        </AuthContext.Consumer>
                        {coupon.order ? null : (
                          <>
                            <ConfirmDialog
                              onConfirm={_ => handleCouponDelete(coupon)}
                              title={`Ištrinti Taloną #${getCouponHumanId(coupon)}`}
                              content={
                                <div>
                                  <Typography color="inherit" variant="body1">
                                    Ištrynus Taloną, atšaukti šio veiksmo nebegalima.
                                  </Typography>
                                  <Typography className={classes.warning}>
                                    Atskiras pranešimas vartotojui nebus siunčiamas, šis Talonas nebebus matomas
                                    vartotojo paskyroje, tačiau vis dar turės neveikianti Talono PDF su QR kodu
                                  </Typography>
                                </div>
                              }
                            >
                              <IconButton>
                                <Delete />
                              </IconButton>
                            </ConfirmDialog>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={filteredCoupons.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </div>
  )
}
