import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import React, { useState } from 'react'
import { Product } from '../../types/product-type'
import { useHistory } from 'react-router-dom'
import { toCurrency } from '../../_core/utils/orders-utils'
import { formatDate } from '../../_core/utils/date-utils'
import Label from '../../_core/components/label'
import PerfectScrollbar from 'react-perfect-scrollbar'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import { getValueText } from '../product-helper'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  clickableRow: {
    cursor: 'pointer',
  },
}))

type Props = {
  className?: any
  products: Product[]
}

export const ProductsTable: React.FC<Props> = props => {
  const { className, products, ...rest } = props

  const classes = useStyles()
  const history = useHistory()
  const [selectedUsers, setSelectedUsers] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleSelectAll = event => {
    const { products } = props

    let selectedUsers

    if (event.target.checked) {
      selectedUsers = products.map(user => user.id)
    } else {
      selectedUsers = []
    }

    setSelectedUsers(selectedUsers)
  }

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id)
    let newSelectedUsers = []

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id)
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1))
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1),
      )
    }

    setSelectedUsers(newSelectedUsers)
  }

  const handlePageChange = (event, page) => {
    setPage(page)
  }

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value)
  }

  const handleEditProduct = productId => {
    history.push(`/products/edit/${productId}`)
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell padding="checkbox">*/}
                  {/*  <Checkbox*/}
                  {/*    checked={selectedUsers.length === products.length}*/}
                  {/*    color="primary"*/}
                  {/*    indeterminate={selectedUsers.length > 0 && selectedUsers.length < products.length}*/}
                  {/*    onChange={handleSelectAll}*/}
                  {/*  />*/}
                  {/*</TableCell>*/}
                  <TableCell>Kodas</TableCell>
                  <TableCell>Pavadinimas</TableCell>
                  <TableCell>Kaina/Vertė</TableCell>
                  <TableCell>Sukurtas</TableCell>
                  <TableCell>Publikuotas</TableCell>
                  <TableCell>Veiksmai</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
                  <TableRow
                    hover
                    className={classes.clickableRow}
                    key={product.id}
                    onClick={() => handleEditProduct(product.id)}
                    selected={selectedUsers.indexOf(product.id) !== -1}
                  >
                    {/*<TableCell padding="checkbox">*/}
                    {/*  <Checkbox*/}
                    {/*    checked={selectedUsers.indexOf(product.id) !== -1}*/}
                    {/*    color="primary"*/}
                    {/*    onChange={event => handleSelectOne(event, product.id)}*/}
                    {/*    value="true"*/}
                    {/*  />*/}
                    {/*</TableCell>*/}
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{product.sku}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{product.title}</TableCell>
                    <TableCell>{getValueText(product)}</TableCell>
                    <TableCell>{formatDate(product.created)}</TableCell>
                    <TableCell>
                      <Label color={product.isPublished ? 'success' : 'error'}>
                        {product.isPublished ? 'Yes' : 'No'}
                      </Label>
                    </TableCell>
                    <TableCell>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={products.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  )
}
