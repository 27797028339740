import * as keys from '../keys'
export const en: { [index: string]: string } = {}
// home-splash
en[keys.TEXT_FOR_BUSINESS] = 'For Business'
en[keys.TEXT_MY_COUPONS] = 'My Coupons'
en[keys.TEXT_HOME_SPLASH_1] = 'Missing your favourite places?'
en[keys.TEXT_FEELING_IS_MUTUAL] = 'The feeling is mutual'
en[keys.TEXT_FOLLOW_US] = 'Follow us:'
// globoja-vilnius
en[keys.TEXT_GLOBOJA_VILNIUS_1] = "The project is endorsed by Vilnius city municipality"
// home
en[keys.TEXT_GET_COUPON_NOW] = "Get a coupon now."
en[keys.TEXT_ENJOY_LATER] = "Enjoy later."
en[keys.TEXT_CHOOSE_A_PLACE] = "Choose a place you want to support."
en[keys.TEXT_ORDER_COUPONS] = "Order Coupons and they will arrive to you via email."
en[keys.TEXT_ENJOY_WHEN] = "Enjoy when your favourite places open up again."
en[keys.TEXT_MISSION_WHY] = "Mission / Why?"
en[keys.TEXT_HOME_1] = "TALONAI was born out of a desire to help Lithuanian businesses, that do not have an option of working remotely in the time of quarantine. Most of us have cherished places, that we want to visit again and again. Also, we value hard work that goes into building a business and jobs that are created in the process."
en[keys.TEXT_HOME_2] = "This web app is a simple way to support those we value and enjoy fruits of your kindness a little bit later."
en[keys.TEXT_HOW] = "HOW?"
en[keys.TEXT_HOME_3] = "Businesses can offer an easy way for their loyal customers to help them during quarantine, by selling coupons, that can be payed for with bank transfer and redeemed when the businesses open up again. You do not need any special equipement for that, internet and smartphone will be enough."
en[keys.TEXT_FOR_FREE]= "Everything for free?"
en[keys.TEXT_HOME_4] = "Yes, you can use this platform for free."
en[keys.TEXT_OFFERING_COUPONS] = "Offering coupons:"
//tutorial section
en[keys.TEXT_I_WANT_TO_OFFER_COUPONS] = "I want to offer coupons"
en[keys.TEXT_SIGN_UP] = "Sign up"
en[keys.TEXT_TUTORIAL_01] = "and fill in information about your company"
en[keys.TEXT_TUTORIAL_02] = "Create your coupon denominations in the 'Nominalai' tab."
en[keys.TEXT_TUTORIAL_03] = "Follow the instructions to verify your company (won't be difficult)."
en[keys.TEXT_TUTORIAL_04] = "Share your company page in social media, so your customers could order your coupons."
en[keys.TEXT_TUTORIAL_05] = "Wait for orders: you will receive the notifications in your email and will be able to see all orders in your TALONAI account."
en[keys.TEXT_TUTORIAL_06] = "New order is created with a status of 'Awaiting confirmation', check your bank account several times a day and confirm orders that have been payed for."
en[keys.TEXT_TUTORIAL_07] = "After order is confirmed the coupons will be generated (eg., 5 coupons of EUR 20) and sent to the customers email."
en[keys.TEXT_TUTORIAL_08] = "Each coupon has a QR code, when you scan it you get redirected to a confrmation page. Here you can see if coupon is still valid, mark it as redeemed and in so doing complete the journey of this particular coupon."
en[keys.TEXT_TUTORIAL_09] = "Last but not least: wait for further updates to the app and more in deapth usage guide."
en[keys.TEXT_I_WANT_GET_SOME_COUPONS] = "I want to get some coupons"
en[keys.TEXT_TUTORIAL_11] = "Choose a place you want to support. We hope, that businesses will share their company page with available coupons on social media"
en[keys.TEXT_TUTORIAL_12] = "Choose coupons, you want to get."
en[keys.TEXT_TUTORIAL_13] = "After you fill in your details, you will get an email with all necessary information on how to make abank transfer."
en[keys.TEXT_TUTORIAL_14] = "The place that issued the coupon, after receiving your order and payment, generates your coupons, that you will be able to spend on that particular vendors goods and services."
en[keys.TEXT_TUTORIAL_15] = "You will get an email with a file attached containing all of the ordered coupons."
en[keys.TEXT_TUTORIAL_16] = "Your orders and coupons are available to see when you log in to your account (you won't loose them that way), just click"
en[keys.TEXT_TUTORIAL_17] = "In order to make the platform accessable to all sooner and free of charge, we decided against integrated payment methods. Coupons can be payed for with bank transfer straight to the account of the business you want to support. We get it, 2020"
en[keys.TEXT_MORE_INFO] = "More info"
en[keys.TEXT_CONTACTS] = "Contacts"
en[keys.TEXT_HELP_FOR_BUSINESSES] = "Help for businesses"
en[keys.TEXT_DO_YOU_HAVE_ANY_QUESTIONS] = "Do you have questions regarding the coupons you have purchased?"
en[keys.TEXT_EMAIL] = "Email"
en[keys.TEXT_FAQ] = "FAQ"
en[keys.TEXT_ANSWERS_TO_FREQUENTLY_ASKED_QUESTIONS] = "Answers to freaquently asked questions by businesses and customers"
en[keys.TEXT_FOR_CUSTOMERS] = "For visitors"
// comapny-list
en[keys.TEXT_SEARCH] = "Searched"
en[keys.TEXT_TYPE_IN_KEYWORDS] = "Type in keywords"
en[keys.TEXT_SELECT_LOCATION] = "Select locations"
//category-fiener
en[keys.TEXT_SELECT_CATEGORIES] = "Select categories"
en[keys.TEXT_FOOD_AND_DRINK] = "Food and drinks"
en[keys.TEXT_ACTIVITIES] = "Activities"
en[keys.TEXT_SERVICES] = "Services"
// company-card
en[keys.TEXT_GET_A_COUPON] = "Get coupons"