import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { useSelector } from '../../store'
import { filterByCity, sortByCreated } from '../../_core/utils/filter-utils'
import { MiniCompanyCard } from './mini-company-card'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
const useStyles = makeStyles(theme => ({
  box: {
    maxWidth: '1440px',
    margin: '0 auto',
  },
  root: {
    padding: theme.spacing(2),
  },
  content: {
    margin: '0 auto',
    marginTop: theme.spacing(2),
  },
  gridList: {
    display: 'flex',
    padding: '30px 0 8px 0',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  sectionHeader: {
    marginBottom: theme.spacing(2),
  },
}))

export const NewCompaniesList = props => {
  const classes = useStyles()
  const { companies } = props
  const city = useSelector(state => state.filters.city)
  return null
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.box}>
          <Typography variant={'h2'} style={{ marginBottom: '16px' }}>
            Naujausi
          </Typography>
          <PerfectScrollbar>
            <div className={classes.gridList}>
              {companies &&
                sortByCreated(companies)
                  .filter(company => filterByCity(company, city))
                  .slice(0, 8)
                  .map(company => <MiniCompanyCard company={company} />)}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  )
}
