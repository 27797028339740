import { Button, CircularProgress, IconButton } from '@material-ui/core'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import {
  composeValidators,
  validatorFieldsMatch,
  validatorMinLength,
  validatorRequired,
} from '_core/form-validators/validators'
import { TextFieldAdapter } from '../forms/inputs/text-field-adapter'
import { DEFAULT_COMPANY_USER_ROUTE } from '../router/router-utils'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Company } from '../types/company-type'
import { useSelector } from '../store'
import { useSnackbar } from 'notistack'
import { getErrorText } from '../_core/utils/firebase-error-text'
import { getImageUrl, ImageType } from '../images/image-utils'

type Form = {
  name: string
  password: string
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(3),
  },
  box: {
    width: '100%',
    maxWidth: '400px',
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  header: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  subHeader: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  logo: {
    maxWidth: '150px',
    borderRadius: '50%',
    background: '#fff',

    margin: '0 auto 30px auto',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  field: {
    marginBottom: theme.spacing(1),
  },
}))
export const UserAcceptInvite: React.FC = () => {
  const firebase = useFirebase()
  const firestore = useFirestore()
  const history = useHistory()
  const params = useParams<any>()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const companyDocument = `companies/${params.companyId}`
  const { enqueueSnackbar } = useSnackbar()
  const showError = error => {
    enqueueSnackbar(getErrorText(error), {
      variant: 'error',
    })
  }
  useFirestoreConnect(companyDocument)
  const company: Company | undefined = useSelector(
    ({ firestore: { data } }) => data.companies && data.companies[params.companyId],
  )
  const collection = `companies/${params.companyId}/invites`
  useFirestoreConnect([{ collection: collection, doc: params.inviteId }])

  const onSubmit = (formValue: Form) => {
    setLoading(true)
    firebase
      .auth()
      .createUserWithEmailAndPassword(params.email, formValue.password)
      .then(userCredential => {
        const unsubscribe = firestore
          .collection('users')
          .doc(userCredential.user.uid)
          .onSnapshot(user => {
            const userData = user.data()
            if (userData) {
              unsubscribe()
              firestore
                .doc(`users/${user.id}`)
                .update({
                  name: formValue.name,
                })
                .then(() => {
                  setLoading(false)
                  history.replace(DEFAULT_COMPANY_USER_ROUTE)
                })
                .catch(error => {
                  setLoading(false)
                  showError(error)
                })
            }
          })
      })
      .catch(error => {
        showError(error)
        setLoading(false)
      })
  }

  return (
    <div className={classes.root}>
      <div>
        <IconButton onClick={() => history.push('/')}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <div className={classes.content}>
        <div className={classes.box}>
          {company && (
            <img className={classes.logo} alt={company.name} src={getImageUrl(company.logoUrl, ImageType.SMALL)} />
          )}
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  className={classes.field}
                  name="name"
                  label="Vardas"
                  fieldType="text"
                  component={TextFieldAdapter}
                  validate={composeValidators(validatorRequired)}
                  required
                />
                <Field
                  className={classes.field}
                  name="password"
                  label="Slaptažodis"
                  fieldType="password"
                  component={TextFieldAdapter}
                  validate={composeValidators(validatorMinLength(6), validatorRequired)}
                  required
                />
                <Field
                  className={classes.field}
                  name="repeated-password"
                  label="Pakartoti slaptažodį"
                  fieldType="password"
                  component={TextFieldAdapter}
                  validate={composeValidators(
                    validatorFieldsMatch(['password'], 'Slaptažodžiai nesutampa'),
                    validatorMinLength(6),
                    validatorRequired,
                  )}
                  required
                />
                <div className={classes.buttonWrapper}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Button fullWidth variant="contained" color="primary" type="submit">
                      Užbaigti registraciją
                    </Button>
                  )}
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  )
}
