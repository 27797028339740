import { Container, Grid } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useSelector } from '../store'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))
export const Dashboard: React.FC<any> = () => {
  const classes = useStyles()
  const profile = useSelector(state => state.firebase.profile)
  return (
    <div className={classes.root}>
      <Container maxWidth={false}>
        {/*<Header />*/}
        {profile.role === 'user' && (
          <div>
            <Typography>
              {`Sveiki, jūs sėkmingai prisijungėte ir nuskaitę QR kodus galite peržiūrėti ir administruoti Talonus.`}
            </Typography>
          </div>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {/*<Overview />*/}
          </Grid>
          <Grid item lg={8} xl={9} xs={12}>
            {/*<FinancialStats />*/}
          </Grid>
          <Grid item lg={4} xl={3} xs={12}>
            {/*<EarningsSegmentation />*/}
          </Grid>
          <Grid item lg={8} xs={12}>
            {/*<LatestOrders />*/}
          </Grid>
          <Grid item lg={4} xs={12}></Grid>
        </Grid>
      </Container>
    </div>
  )
}
