import { Button, Grid, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from '../../store'
import { useFirestore } from 'react-redux-firebase'
import { CheckboxFieldAdapter } from '../../forms/inputs/checkbox-field-adapter'
import { TextFieldAdapter } from '../../forms/inputs/text-field-adapter'
import { Link, useHistory } from 'react-router-dom'
import { AsAGift } from '../../_core/components/as-a-gift-dialog'
import { FacebookShareButton } from 'react-share'
import { Facebook } from '@material-ui/icons'
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  numberField: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    'input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  form: {},
  headline: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, 0),
  },
  grid: {
    height: '100%',
  },
  gridItem: {
    width: '100%',
    maxWidth: '400px',
  },
  fieldContainer: {
    marginRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  formHeadline: {
    height: '42px',
    paddingTop: theme.spacing(1),
  },
  checkbox: {
    //height: '54px',
    //marginTop: theme.spacing(1),
  },
}))
const Success: React.FC<any> = props => {
  const { company } = props
  return (
    <div>
      <Typography variant="h2">Ačiū! (❁´◡`❁)</Typography>

      <br />
      <br />
      <div>
        <FacebookShareButton
          url={`https://talonai.lt/company/${company.alias}`}
          quote={`${company.name}, mes ir vėl susitiksim!`}
          hashtag={'#talonai'}
          children={<Button variant={'outlined'} startIcon={<Facebook />}>{`Pasidalink ilgesiu`}</Button>}
        />
      </div>
      <br />
      <Typography variant="body1">
        Netrukus gausite elektroninį laišką su visa informacija, kaip pabaigti savo užsakymą.
      </Typography>
      <br />
      <br />
      <Typography variant="body1">
        Jei laiško negaunate per kelias minutes, pasitikrinkite <b>spam</b> aplanką.
      </Typography>
    </div>
  )
}
export const PlaceOrder: React.FC<any> = props => {
  let companyId = props.companyId
  const company = props.company
  const [showSuccess, setShowSuccess] = React.useState(false)
  const firestore = useFirestore()
  const history = useHistory()
  const classes = useStyles()
  const productsObject = useSelector(state => state.countOrder.orderData)
  const products = Object.keys(productsObject).map(key => productsObject[key])
  const onSubmit = values => {
    const order = {
      ...values,
      email: values.email.toLowerCase(),
      companyId: companyId,
      products: products,
    }
    firestore
      .collection(`companies/${companyId}/orders`)
      .add(order)
      .then(a => setShowSuccess(true))
  }
  const required = value => (value ? undefined : 'Užpildykite šį lauką')

  if (showSuccess) {
    return <Success company={company} />
  }
  if (products.length === 0) {
    history.goBack()
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid className={classes.grid} container>
            <Grid className={classes.gridItem} item>
              <Typography className={classes.formHeadline} variant="h4">
                Informacija apie jus:
              </Typography>
              <div className={classes.fieldContainer}>
                <Field
                  name="firstName"
                  component={TextFieldAdapter}
                  label="Vardas"
                  className={classes.textField}
                  validate={required}
                  fieldType="text"
                  variant="outlined"
                />
              </div>
              <div className={classes.fieldContainer}>
                <Field
                  name="lastName"
                  component={TextFieldAdapter}
                  label="Pavardė"
                  className={classes.textField}
                  validate={required}
                  fieldType="text"
                  variant="outlined"
                />
              </div>
              <div className={classes.fieldContainer}>
                <Field
                  name="email"
                  component={TextFieldAdapter}
                  label="El. paštas"
                  className={classes.textField}
                  validate={required}
                  fieldType="email"
                  variant="outlined"
                />
              </div>
              <div className={`${classes.fieldContainer} ${classes.numberField}`}>
                <Field
                  name="phone"
                  component={TextFieldAdapter}
                  label="Telefono numeris"
                  className={classes.textField}
                  validate={required}
                />
              </div>
            </Grid>
            <Grid className={classes.gridItem} item>
              <div className={`${classes.fieldContainer} ${classes.checkbox}`}>
                <Field
                  name="isCompany"
                  component={CheckboxFieldAdapter}
                  label="Reikalinga sąskaita faktūra"
                  type="checkbox"
                  variant="outlined"
                />
              </div>
              <div className={classes.fieldContainer}>
                <Field
                  name="companyName"
                  disabled={!values.isCompany}
                  component={TextFieldAdapter}
                  label="Įmonės pavadinimas"
                  className={classes.textField}
                  key={values.isCompany ? 1 : 0}
                  validate={values.isCompany ? required : () => {}}
                  fieldType="text"
                  variant="outlined"
                />
              </div>
              <div className={`${classes.fieldContainer} ${classes.numberField}`}>
                <Field
                  disabled={!values.isCompany}
                  name="companyOrgNumber"
                  fieldType="number"
                  component={TextFieldAdapter}
                  label="Įmonės kodas"
                  className={classes.textField}
                  key={values.isCompany ? 1 : 0}
                  validate={values.isCompany ? required : () => {}}
                />
              </div>
              <div className={`${classes.fieldContainer} ${classes.numberField}`}>
                <Field
                  disabled={!values.isCompany}
                  name="vatNumber"
                  fieldType="number"
                  component={TextFieldAdapter}
                  label="PVM mokėtojo kodas"
                  className={classes.textField}
                  //validate={required}
                />
              </div>
              <div className={classes.fieldContainer}>
                <Field
                  disabled={!values.isCompany}
                  name="companyAddress"
                  component={TextFieldAdapter}
                  label="Įmonės adresas"
                  className={classes.textField}
                  key={values.isCompany ? 1 : 0}
                  validate={values.isCompany ? required : () => {}}
                  fieldType="text"
                  variant="outlined"
                />
              </div>
            </Grid>
          </Grid>
          <div>
            <AsAGift />
          </div>
          <div className={`${classes.fieldContainer} ${classes.checkbox}`}>
            <Field
              name="marketingAgreement"
              component={CheckboxFieldAdapter}
              label={
                <Typography variant="body1">Ateityje norėčiau gauti specialių pasiūlymų iš {company.name}</Typography>
              }
              type="checkbox"
              variant="outlined"
            />
          </div>
          <div className={`${classes.fieldContainer} ${classes.checkbox}`}>
            <Field
              name="terms"
              component={CheckboxFieldAdapter}
              label={
                <Typography variant="body1">
                  Atlikdamas užsakymą sutinku su <Link to={'/terms'}>sąlygomis</Link>
                  <sup>*</sup>
                </Typography>
              }
              type="checkbox"
              variant="outlined"
            />
          </div>
          <Button
            className={classes.button}
            disabled={products.length === 0 || !values.terms}
            variant="contained"
            color="primary"
            type="submit"
          >
            Pateikti užsakymą
          </Button>
        </form>
      )}
    />
  )
}
