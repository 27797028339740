import React, { useEffect } from 'react'
import { useHomeStyles } from '../styles/style'
import { registerPageView } from '../../../_core/analytics/analytics'
import { useSelector } from '../../../store'
import { HomeSplash } from '../home-splash'
import { Mission } from '../parts/mission'
import { Grid, Typography } from '@material-ui/core'
import { tr } from '../../../translations/translate'
import { TEXT_OFFERING_COUPONS } from '../../../translations/keys'
import { CompanyList } from '../company-list'
import { Bottom } from '../parts/bottom'
import { KlaipedaSplash } from './klaipeda-splash'
import { countOrderResetAction } from '../../count-order/count-order-actions'
import { useDispatch } from 'react-redux'
import { actionSetCity } from '../filters/filters-actions'
import { NewCompaniesList } from '../new-companies'

export const HomeKlaipeda: React.FC = () => {
  const classes = useHomeStyles()
  const dispatch = useDispatch()
  useEffect(() => {
    registerPageView('home_klaipeda')
  }, [])
  const companies = useSelector(
    ({
      firestore: {
        ordered: { publicCompanies },
      },
    }) => publicCompanies && publicCompanies,
  )

  useEffect(() => {
    dispatch(actionSetCity('Klaipėda'))
  }, [dispatch])
  return (
    <div className={classes.root}>
      <KlaipedaSplash />
      {companies && <NewCompaniesList companies={companies} />}
      <div style={{ marginTop: '48px' }} />
      <div id="info" className={classes.box}>
        {companies && companies.length > 0 && (
          <>
            <Grid item xs={12} className={classes.box}>
              <Typography variant={'h2'} style={{ marginBottom: '16px' }}>
                Talonus siūlo:
              </Typography>
              <CompanyList companies={companies} />
            </Grid>
          </>
        )}
        <div style={{ marginTop: '96px' }} />
        <Mission />
        <div style={{ marginTop: '48px' }} />
        <Bottom />
      </div>
    </div>
  )
}
