import { Moment } from 'moment'

export type CouponFilter = {
  group: CouponGroup
  periodFrom: Moment
  periodTo: Moment
}

export enum CouponGroup {
  ALL = 'ALL',
  CLAIMED = 'CLAIMED',
  NOT_CLAIMED = 'NOT_CLAIMED',
  EXPIRED = 'EXPIRED',
}
