import { isNumeric } from 'rxjs/internal-compatibility'

export const composeValidators = (...validators) => (value, allValues, fieldState) =>
  validators.reduce((error, validator) => error || validator(value, allValues, fieldState), undefined)

export const validatorRequired = value => (value ? undefined : 'Šis laukas yra privalomas')
export const valuePriceValidator = price => value => (value >= price ? undefined : 'Vertė negali būti mažesnė už kainą')
export const validatorMinLength = minLength => value =>
  value && '' + value.length >= minLength ? undefined : `Minimum ${minLength}`
export const validatorMaxLength = maxLength => value =>
  value && '' + value.length <= maxLength ? undefined : `Leistinas simbolių kiekis ${maxLength}`

export const validatorEmail = value => (validateEmail(value) ? undefined : 'Įveskite validų el. pašto adresą')

export const validatorFieldsMatch = (fieldNames: string[], errorMessage: string) => (value, allValues, fieldState) =>
  fieldNames.reduce((doMatch, fieldName) => (value === allValues[fieldName] ? doMatch : false), true)
    ? undefined
    : errorMessage

const validateEmail = (email: string): boolean => {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regexp.test(String(email).toLowerCase())
}
export const validatorMinValue = minValue => value =>
  value && isNumeric(value) && value >= minValue ? undefined : `Minimali reikšmė yra ${minValue}`

const validateAlias = (value: string): boolean => {
  const regexp = /^[A-Za-z0-9-]+$/
  return regexp.test(String(value).toLowerCase())
}
export const validatorAlias = value =>
  validateAlias(value) ? undefined : 'Only lower case letters and numbers with dashes can be used'
