import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import moment from 'moment'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Label } from '../../_core/components/label'
import { getOrderStatus, paymentStatusColors } from '../../_core/utils/orders-utils'
import PerfectScrollbar from 'react-perfect-scrollbar'
import IconButton from '@material-ui/core/IconButton'
import ReceiptIcon from '@material-ui/icons/Receipt'
import Button from '@material-ui/core/Button'
import { OrderStatus } from '../../types/order/order-status.enum'
import { ConfirmDialog } from '../../_core/components/confirm-dialog'
import { useSnackbar } from 'notistack'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from '../../store'
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  clickableRow: {
    cursor: 'pointer',
  },
  warning: {
    fontWeight: 500,
    color: theme.palette.error.dark,
  },
}))

export const OrdersTable = props => {
  const { className, orders, ...rest } = props

  const classes = useStyles()
  const history = useHistory()
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handlePageChange = (event, page) => {
    setPage(page)
  }

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value)
  }
  const { enqueueSnackbar } = useSnackbar()
  const showSuccess = () => {
    enqueueSnackbar('Talonai išsiųsti sėkmingai', {
      variant: 'success',
    })
  }
  const handleConfirmOrder = order => {
    const itemUpdates = {
      status: OrderStatus.FINISHED,
    }
    firestore.collection(`companies/${profile.companyId}/orders`).doc(order.id).update(itemUpdates).then(showSuccess)
  }
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Numeris</TableCell>
                <TableCell>El. paštas</TableCell>
                <TableCell>Vertė</TableCell>
                <TableCell>Sukurtas</TableCell>
                <TableCell>Būsena</TableCell>
                <TableCell>Veiksmai</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(order => (
                <TableRow
                  className={classes.clickableRow}
                  hover
                  key={order.id}
                  onClick={() => history.push(`/orders/${order.id}`)}
                >
                  <TableCell>
                    <div className={classes.nameContainer}>
                      <Typography variant="body1">#{order.number}</Typography>
                    </div>
                  </TableCell>
                  <TableCell>{order.email}</TableCell>
                  <TableCell>{`€${order.price}`}</TableCell>

                  <TableCell>{moment(order.created.toDate()).format('YYYY-MM-DD HH:mm')}</TableCell>
                  <TableCell>
                    <Label color={paymentStatusColors[order.status]}>{getOrderStatus(order.status)}</Label>
                  </TableCell>
                  <TableCell onClick={event => event.stopPropagation()}>
                    {order.status === OrderStatus.PENDING && (
                      <ConfirmDialog
                        onConfirm={() => handleConfirmOrder(order)}
                        title={`Užsakymo #${order.number} patvirtinimas`}
                        content={
                          <div>
                            <Typography color="inherit" variant="body1">
                              Patvirtinus užsakymą bus suformuoti Talonai ir klientui pristatyti el. paštu
                            </Typography>
                            <Typography className={classes.warning}>
                              Prieš patvirtindami užsakymus įsitikinkite, kad pavedimas yra atliktas sėkmingai
                            </Typography>
                          </div>
                        }
                      >
                        <Button variant={'outlined'} size={'small'}>
                          Patvirtinti
                        </Button>
                      </ConfirmDialog>
                    )}
                    <IconButton component={Link} to={`/orders/${order.id}`}>
                      <ReceiptIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={orders.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  )
}
