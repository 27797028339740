/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { Button, colors, List, ListItem } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { useSelector } from '../../store'

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: colors.blueGrey[800],
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  filler: { flex: 1 },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}))

const CustomRouterLink = props => (
  <div style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
)

export const SidebarNav = props => {
  const { pages, className, ...rest } = props
  const profile = useSelector(state => state.firebase.profile)
  const classes = useStyles()
  const roleFilter = page => page.roles.indexOf(profile.role) !== -1
  return (
    <>
      <List {...rest} className={clsx(classes.root, className)}>
        {pages
          .filter(page => !page.bottom && !page.hidden)
          .filter(roleFilter)
          .map(page => (
            <ListItem className={classes.item} disableGutters key={page.title}>
              <Button
                target={page.target}
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={page.href}
              >
                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </Button>
            </ListItem>
          ))}
      </List>
      <div className={classes.filler} />
      <List>
        <div>
          {pages
            .filter(page => page.bottom && !page.hidden)
            .filter(roleFilter)
            .map(page => (
              <ListItem className={classes.item} disableGutters key={page.title}>
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  to={page.href}
                >
                  <div className={classes.icon}>{page.icon}</div>
                  {page.title}
                </Button>
              </ListItem>
            ))}
        </div>
      </List>
    </>
  )
}
