import { Divider, Drawer } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import InputIcon from '@material-ui/icons/Input'
import SettingsIcon from '@material-ui/icons/Settings'
import OrdersIcon from '@material-ui/icons/LibraryBooks'
import clsx from 'clsx'
import React from 'react'
import { Profile } from './profile'
import { SidebarNav } from './sidebar-nav'
import { useFirebase, useFirestoreConnect } from 'react-redux-firebase'
import { Company } from '../../types/company-type'
import { useSelector } from '../../store'
import { Email } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

type Page = {
  target?: string
  roles: Array<string>
  hidden?: boolean
  title: any
  href: string
  icon: any
  bottom?: boolean
}
export const ControlCenterSidebar = props => {
  const { open, variant, onClose, className, ...rest } = props
  const profile = useSelector(state => state.firebase.profile)
  const companyDocument = `companies/${profile.companyId}`
  useFirestoreConnect(companyDocument)
  const company: Company | undefined = useSelector(
    ({ firestore: { data } }) => data.companies && data.companies[profile.companyId],
  )
  const classes = useStyles()
  const pages: Page[] = [
    {
      title: 'Companies',
      roles: ['admin', 'super-admin'],
      href: '/control-center/companies',
      icon: <OrdersIcon />,
    },
    {
      title: 'Send email',
      roles: ['admin', 'super-admin'],
      href: '/control-center/send-email',
      icon: <Email />,
    },

    {
      title: 'Nustatymai',
      roles: ['admin', 'super-admin'],
      href: '/control-center/global-settings',
      icon: <SettingsIcon />,
    },
    {
      bottom: true,
      roles: ['admin', 'super-admin'],
      title: 'Atsijungti',
      href: '/sign-out',
      icon: <InputIcon />,
    },
  ]

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile company={company} />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}
