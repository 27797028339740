import React from 'react'
import { Button, Dialog } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))
export const AsAGift: React.FC<any> = ({}) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<CardGiftcardIcon />}
        className={classes.margin}
        size="small"
        variant="outlined"
      >
        Norite Taloną įteikti kaip dovaną?
      </Button>
      <Dialog open={open} onClose={_ => setOpen(false)} maxWidth={'sm'}>
        <DialogTitle>Norite Taloną padovanoti?</DialogTitle>
        <DialogContent>
          <Typography>
            Sėkmingai įvykdžius užsakymą, jūs gausite elektroninį laišką su pdf dokumentu, kuriame yra visa reikalinga
            informacija Talonui išnaudoti.
            <br />
            Galite šį failą atsispausdinti ir įteikti žmogui fiziškai arba tiesiog nusiųsti failą elektroniniu paštu.{' '}
            <br />
            <br />
            <b>Naudingas patarimas:</b>
            <br />
            Įvykdžius užsakymą, jūs galite prisijungti prie sistemos spausdami "Mano Talonai" mygtuką viršuje (tereikia
            prieigos prie el. pašto, kuriuo vykdėte užsakymą).
            <br />
            Ten matysite visus savo Talonus bei jų statusą, t.y. žinosite ar jūsų dovana jau buvo pasinaudota. Taip pat,
            pasinaudojus jūsų dovanotu Talonu, jūs gausite el. laišką, kad Talonas buvo išnaudotas.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={_ => setOpen(false)}>
            Viskas aišku
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
