import TextField from '@material-ui/core/TextField'
import React from 'react'
import { InputType } from './input-type.enum'
import { Typography } from '@material-ui/core'

export type TextFieldAdapterProps = { type: InputType.TextField } // type props are required to differenciate between different field adapters

export const TextFieldAdapter: React.FC<any> = (props: any) => {
  const {
    input: { name, value, onChange, onBlur, onFocus, required },
    meta: { touched, error, submitError },
    fieldType,
    helperText,
    explanation,
    ...rest
  } = props

  return (
    <>
      <TextField
        fullWidth
        error={touched && (error || submitError) ? true : false}
        helperText={touched && (error || submitError) ? error : helperText}
        variant={'outlined'}
        required={required}
        name={name}
        type={fieldType ? fieldType : 'text'}
        onChange={(event: any) => onChange(event.target.value)}
        onFocus={onFocus}
        onBlur={() => onChange(typeof value === 'string' ? value.trim() : value)}
        value={value}
        {...rest}
      />
      {explanation && <Typography variant={'caption'}>{explanation}</Typography>}
    </>
  )
}
