import React from 'react'
import { Company } from '../../types/company-type'
import { ListItem } from '@material-ui/core'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import { getImageUrl, ImageType } from '../../images/image-utils'
import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch } from 'react-redux'
import { actionCompanyHoverChange, actionSetSelectedCompany } from '../map-actions'
const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    cursor: 'pointer',
  },
}))
type Props = {
  company: Company
}

export const CompanyListItem: React.FC<Props> = ({ company }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleCompanyClick = (company: Company) => {
    dispatch(actionSetSelectedCompany(company))
  }
  const handleMouseEnterLeave = (companyId: string) => {
    //dispatch(actionCompanyHoverChange(companyId))
  }
  return (
    <>
      <ListItem
        onMouseEnter={() => handleMouseEnterLeave(company.id)}
        onMouseLeave={() => handleMouseEnterLeave(null)}
        className={classes.listItem}
        button
        onClick={() => handleCompanyClick(company)}
      >
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <img src={getImageUrl(company.logoUrl, ImageType.THUMB)} alt={company.name} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={company.name} secondary={company.shortDescription} />
      </ListItem>
      <Divider />
    </>
  )
}
